import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { appVariables } from '../../helpers/appVariables';

class AboutPickThree extends Component {
    constructor(props) {
        super(props);
    }

    Moreinfo = () => {
        if (window.AboutMoreinfo) {
            window.AboutMoreinfo()
        }
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <>
                <section>
                    <div className='wrap1'>
                        <div className="priceBraDwnTitle w-100">
                            {/* <h2>{i18n.language === 'ur' ? 'کے بارے میں ' : i18n.language === 'ml' ? 'നെകുറിച്ച്' : i18n.language === 'ar' ? 'حول اللعبة' :
                            i18n.language === 'th' ? 'เกี่ยวกับ' : i18n.language === 'tl' ? 'Tungkol sa' : i18n.language === 'es' ? 'Acerca de' :
                                i18n.language === 'ta' ? 'பற்றி' : i18n.language === 'hi' ? 'के बारे में' : 'About'} </h2> */}
                            {i18n.language === 'ta' ?
                                <>
                                    <img src="./assets/img/gamesBg/pick-3-logo.png?v0.4" alt="Fortune 5 logo" className="w-30" />
                                    <h2>{t('lblabouttxt')}</h2>
                                </>
                                :
                                <>
                                    <h2>{t('lblabouttxt')}</h2>
                                    <img src="./assets/img/gamesBg/pick-3-logo.png?v0.4" alt="Fortune 5 logo" className="w-30" />
                                </>
                            }
                            <div class="moreinfoBtn" onClick={() => { this.Moreinfo() }}>
                                <span>More details</span><img src="./assets/img/icons/info.png" />
                            </div>
                        </div>
                        <div className='w-100'>
                            {i18n.language === 'ur' ?
                                <div id="about-me">
                                    {/* <h2 className="aboutGameContentHead">کے بارے میں  <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="lucky1 logo" className="h-10 w-24" /></span></h2> */}
                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBox1">
                                                <div>
                                                    <div className="bg-game15">1</div>
                                                </div>
                                                <div className="bg-game15"></div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                </div>

                                                <div className="text-center  margin-top2">
                                                    ہمارے ہفتہ وار ریفل ڈرا اور مین ڈرا میں ایک بار میں داخل ہونے کے لیے {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} اماراتی دینار میں بوتل خریدیں!
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game15">2</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game15"></div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                </div>
                                                <div className="text-center  margin-top2">
                                                    ہر لائن کے لیے 0-9 کے درمیان کوئی بھی 3 نمبر منتخب کریں یا بے ترتیب "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" پر کلک کریں اور ہم آپ کے لیے آپ کے نمبر منتخب کریں گے۔ کلک کریں۔
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game15">3</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game15"></div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                </div>
                                                <div className="text-center  margin-top2">
                                                    اگر نمبر آپ کے منتخب کردہ پیٹرن سے مماثل ہے، تو آپ انعام کی رقم جیتیں گے۔
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game15">4</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game15"></div>
                                    </div>
                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                        </div>
                                        <div className="text-center  margin-top2">
                                            ریفل ڈرا: ہر ہفتے {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} گارنٹی یافتہ فاتح! ہر فاتح AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} وصول کرتا ہے۔
                                        </div>
                                    </div>
                                </div> */}
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game15">4</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                </div>
                                                <div className="text-center  margin-top2">
                                                    يتم إجراء السحب كل يوم، مباشرة على قناتنا على اليوتيوب وصفحتنا على الفيسبوك.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game15">5</div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                </div>
                                                <div className="text-center  margin-top2">
                                                    گلف ٹکٹ پر پانی کی بوتل خرید کر آپ متحدہ عرب امارات میں کھجور کے درختوں کی باغبانی کی ترقی میں ہماری مدد کر رہے ہیں۔
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                i18n.language === 'ml' ?
                                    <div id="about-me">
                                        {/* <h2 className="aboutGameContentHead">നെകുറിച്ച് <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBox1">
                                                    <div>
                                                        <div className="bg-game15">1</div>
                                                    </div>
                                                    <div className="bg-game15"></div>
                                                </div>
                                                <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                    </div>

                                                    <div className="text-center  margin-top2">
                                                        ഞങ്ങളുപ്പട രപതിൊര റാഫിൾ ട്രഡാ ുിം പ്പമ ിൻ ട്രഡാ ുിം ഒറ്റ ടിക്ക് നൽകുന്നതിന് {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} ദിർഹത്തിന് ഒരു കുെി ൊങ്ങുക!
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game15">2</div>
                                                    </div>
                                                    <div className="gameAboutBorder bg-game15"></div>
                                                </div>
                                                <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                    </div>
                                                    <div className="text-center  margin-top2">
                                                        ഓരോ വരിയിലും 0-9 ന് ഇടയിലുള്ള ഏതെങ്കിലും 3 അക്കങ്ങൾ തിരഞ്ഞെടുക്കുക അല്ലെങ്കിൽ ക്രമരഹിതമായ "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" ക്ലിക്ക് ചെയ്യുക, ഞങ്ങൾ നിങ്ങൾക്കായി നിങ്ങളുടെ നമ്പറുകൾ തിരഞ്ഞെടുക്കും. ക്ലിക്ക് ചെയ്യുക
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game15">3</div>
                                                    </div>
                                                    <div className="gameAboutBorder bg-game15"></div>
                                                </div>
                                                <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                    </div>
                                                    <div className="text-center  margin-top2">
                                                        നിങ്ങൾ തിരഞ്ഞെടുക്കുന്ന പാറ്റേണുമായി നമ്പർ പൊരുത്തപ്പെടുന്നെങ്കിൽ, നിങ്ങൾക്ക് സമ്മാന തുക ലഭിക്കും.
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game15">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game15"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                            </div>
                                            <div className="text-center  margin-top2">
                                                റാഫിൾ ഡ്രോ: എല്ലാ ആഴ്‌ചയും {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} വിജയികൾ ഉറപ്പ്! ഓരോ വിജയിക്കും {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} ദിർഹം ലഭിക്കും.
                                            </div>
                                        </div>
                                    </div> */}
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game15">4</div>
                                                    </div>
                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                </div>
                                                <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                    </div>
                                                    <div className="text-center  margin-top2">
                                                        നറുക്കെടുപ്പുകൾ എല്ലാ ദിവസവും നടക്കുന്നു, ഞങ്ങളുടെ YouTube ചാനലിലും Facebook പേജിലും തത്സമയം.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox">
                                                <div className="aboutGameContentBoxType2">
                                                    <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                    <div className="aboutGameContentBoxType2Div">
                                                        <div className="bg-game15">5</div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                    <div className="topDetailDiv">
                                                        <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                    </div>
                                                    <div className="text-center  margin-top2">
                                                        ഗൾഫ് ടിക്കറ്റിൽ ഒരു ൊേർ കുെി ൊങ്ങുന്നതിലൂപ്പട, ുസണറ്റഡ് അറബ് എമിട്ററ്റിപ്പല ഈന്തെന ട്താേങ്ങളുപ്പട െികസനത്തിൽ നിങ്ങൾ ഞങ്ങപ്പള സഹാ ിക്കുന്നു.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    i18n.language === 'ar' ?
                                        <div id="about-me">
                                            {/* <h2 className="aboutGameContentHead">حول اللعبة <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                            <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBox1">
                                                        <div>
                                                            <div className="bg-game15">1</div>
                                                        </div>
                                                        <div className="bg-game15"></div>
                                                    </div>
                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                        </div>

                                                        <div className="text-center  margin-top2">
                                                            قم بشراء زجاجة بقيمة {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount} دراهم للدخول في السحب الأسبوعي والسحب الرئيسي دفعة واحدة!
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game15">2</div>
                                                        </div>
                                                        <div className="gameAboutBorder bg-game15"></div>
                                                    </div>
                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                        </div>
                                                        <div className="text-center  margin-top2">
                                                            اختر أي 3 أرقام بين 0-9 لكل سطر أو انقر على "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" وسنختار أرقامك لك. انقر
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game15">3</div>
                                                        </div>
                                                        <div className="gameAboutBorder bg-game15"></div>
                                                    </div>
                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                        </div>
                                                        <div className="text-center  margin-top2">
                                                            إذا كان الرقم مطابقًا للنمط الذي اخترته، فستفوز بمبلغ الجائزة.
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game15">4</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game15"></div>
                                            </div>
                                            <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                                </div>
                                                <div className="text-center  margin-top2">
                                                    : {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} فائزين مضمونين كل أسبوع! يحصل كل فائز على {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount} درهم إماراتي.
                                                </div>
                                            </div>
                                        </div> */}
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game15">4</div>
                                                        </div>
                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                    </div>
                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                        </div>
                                                        <div className="text-center  margin-top2">
                                                            قرعہ اندازی ہر روز ہوتی ہے، ہمارے یوٹیوب چینل اور فیس بک پیج پر لائیو۔
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutGameContentBox">
                                                    <div className="aboutGameContentBoxType2">
                                                        <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                        <div className="aboutGameContentBoxType2Div">
                                                            <div className="bg-game15">5</div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                        <div className="topDetailDiv">
                                                            <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                        </div>
                                                        <div className="text-center  margin-top2">
                                                            من خلال شراء زجاجة مياه من موقع تذكرة الخليج، فإنك تساعدنا في تطوير مزارع أشجار النخيل في دولة الإمارات العربية المتحدة.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        i18n.language === 'th' ?
                                            <div id="about-me">
                                                {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBox1">
                                                            <div>
                                                                <div className="bg-game15">1</div>
                                                            </div>
                                                            <div className="bg-game15"></div>
                                                        </div>
                                                        <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                            </div>

                                                            <div className="text-center  margin-top2">
                                                                ซื้อขวดในราคา 5 AED เพื่อเข้าร่วมการจับรางวัลหลักของเราในครั้งเดียว!
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game15">2</div>
                                                            </div>
                                                            <div className="gameAboutBorder bg-game15"></div>
                                                        </div>
                                                        <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                            </div>
                                                            <div className="text-center  margin-top2">
                                                                เลือกตัวเลข 3 ตัวระหว่าง 0-9 สำหรับแต่ละบรรทัด หรือคลิกที่ "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" แบบสุ่ม แล้วเราจะเลือกหมายเลขของคุณให้คุณ คลิก
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game15">3</div>
                                                            </div>
                                                            <div className="gameAboutBorder bg-game15"></div>
                                                        </div>
                                                        <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                            </div>
                                                            <div className="text-center  margin-top2">
                                                                หากหมายเลขตรงกับรูปแบบที่คุณเลือก คุณจะได้รับเงินรางวัล
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game15">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game15"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game15">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                            </div>
                                            <div className="text-center  margin-top2">
                                                Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                            </div>
                                        </div>
                                    </div> */}
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game15">4</div>
                                                            </div>
                                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                        </div>
                                                        <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                            </div>
                                                            <div className="text-center  margin-top2">
                                                                การจับรางวัลเกิดขึ้นทุกวัน ถ่ายทอดสดทางช่อง YouTube และหน้า Facebook ของเรา
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="aboutGameContentBox">
                                                        <div className="aboutGameContentBoxType2">
                                                            <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                            <div className="aboutGameContentBoxType2Div">
                                                                <div className="bg-game15">5</div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                            <div className="topDetailDiv">
                                                                <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                            </div>
                                                            <div className="text-center  margin-top2">
                                                                การซื้อขวดน้ำจาก Gulf Ticket ถือเป็นการช่วยเราในการพัฒนา
                                                                ของสวนปาล์มในสหรัฐอาหรับเอมิเรตส์
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :

                                            i18n.language === 'tl' ?
                                                <div id="about-me">
                                                    {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBox1">
                                                                <div>
                                                                    <div className="bg-game15">1</div>
                                                                </div>
                                                                <div className="bg-game15"></div>
                                                            </div>
                                                            <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                </div>

                                                                <div className="text-center  margin-top2">
                                                                    Bumili ng Bote para sa AED 5 para makapasok sa aming MAIN DRAW nang sabay-sabay!
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game15">2</div>
                                                                </div>
                                                                <div className="gameAboutBorder bg-game15"></div>
                                                            </div>
                                                            <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                </div>
                                                                <div className="text-center  margin-top2">
                                                                    Pumili ng anumang 3 numero sa pagitan ng 0-9 para sa bawat linya o mag-click sa randomization na "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" at pipiliin namin ang iyong mga numero para sa iyo. I-click
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game15">3</div>
                                                                </div>
                                                                <div className="gameAboutBorder bg-game15"></div>
                                                            </div>
                                                            <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                                </div>
                                                                <div className="text-center  margin-top2">
                                                                    Kung ang numero ay tumutugma sa iyong piniling pattern, ikaw ay mananalo sa halaga ng premyo.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game15">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game15"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game15">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                            </div>
                                            <div className="text-center  margin-top2">
                                                Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                            </div>
                                        </div>
                                    </div> */}
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game15">4</div>
                                                                </div>
                                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                            </div>
                                                            <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                                </div>
                                                                <div className="text-center  margin-top2">
                                                                    Nagaganap ang mga draw araw-araw , Live sa aming channel sa YouTube at Facebook page.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="aboutGameContentBox">
                                                            <div className="aboutGameContentBoxType2">
                                                                <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                                <div className="aboutGameContentBoxType2Div">
                                                                    <div className="bg-game15">5</div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                                <div className="topDetailDiv">
                                                                    <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                                </div>
                                                                <div className="text-center  margin-top2">
                                                                    Sa pamamagitan ng pagbili ng bote ng tubig sa Gulf Ticket, tinutulungan mo kami sa pagbuo ng mga plantasyon ng palm tree sa United Arab Emirates.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :

                                                i18n.language === 'es' ?
                                                    <div id="about-me">
                                                        {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBox1">
                                                                    <div>
                                                                        <div className="bg-game15">1</div>
                                                                    </div>
                                                                    <div className="bg-game15"></div>
                                                                </div>
                                                                <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                    </div>

                                                                    <div className="text-center  margin-top2">
                                                                        ¡Compre una botella por AED 5 para participar en nuestro SORTEO PRINCIPAL de una sola vez!
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game15">2</div>
                                                                    </div>
                                                                    <div className="gameAboutBorder bg-game15"></div>
                                                                </div>
                                                                <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                    </div>
                                                                    <div className="text-center  margin-top2">
                                                                        Elija 3 números entre 0 y 9 para cada línea o haga clic en el "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" de aleatorización y seleccionaremos sus números por usted. Hacer clic
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game15">3</div>
                                                                    </div>
                                                                    <div className="gameAboutBorder bg-game15"></div>
                                                                </div>
                                                                <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                                    </div>
                                                                    <div className="text-center  margin-top2">
                                                                        Si el número coincide con el patrón que usted eligió, ganará el monto del premio.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game15">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game15"></div>
                                        </div>
                                        <div className="aboutGameContentBox2   border-[2px] border-game15">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                            </div>
                                            <div className="text-center  margin-top2">
                                                Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                            </div>
                                        </div>
                                    </div> */}
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game15">4</div>
                                                                    </div>
                                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                </div>
                                                                <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                                    </div>
                                                                    <div className="text-center  margin-top2">
                                                                        Los sorteos se realizan todos los días, en vivo en nuestro canal de YouTube y página de Facebook.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aboutGameContentBox">
                                                                <div className="aboutGameContentBoxType2">
                                                                    <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                                    <div className="aboutGameContentBoxType2Div">
                                                                        <div className="bg-game15">5</div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                                    <div className="topDetailDiv">
                                                                        <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                                    </div>
                                                                    <div className="text-center  margin-top2">
                                                                        Al comprar una botella de agua en Gulf Ticket, nos estás ayudando en el
                                                                        desarrollo de plantaciones de palmeras en los Emiratos Árabes Unidos.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    i18n.language === 'ta' ?
                                                        <div id="about-me">
                                                            {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                            <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBox1">
                                                                        <div>
                                                                            <div className="bg-game15">1</div>
                                                                        </div>
                                                                        <div className="bg-game15"></div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                        </div>

                                                                        <div className="text-center  margin-top2">
                                                                            ஒரே நேரத்தில் எங்கள் முதன்மை டிராவில் நுழைய AED 5 க்கு ஒரு பாட்டிலை வாங்கவும்!
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game15">2</div>
                                                                        </div>
                                                                        <div className="gameAboutBorder bg-game15"></div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                        </div>
                                                                        <div className="text-center  margin-top2">
                                                                            ஒவ்வொரு வரிக்கும் 0-9 க்கு இடையில் ஏதேனும் 3 எண்களைத் தேர்வு செய்யவும் அல்லது ரேண்டமைசேஷன்
                                                                            "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" கிளிக் செய்யவும் & உங்களுக்கான எண்களைத் தேர்ந்தெடுப்போம். கிளிக் செய்யவும்
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game15">3</div>
                                                                        </div>
                                                                        <div className="gameAboutBorder bg-game15"></div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                                        </div>
                                                                        <div className="text-center  margin-top2">
                                                                            நீங்கள் தேர்ந்தெடுக்கும் முறையுடன் எண் பொருந்தினால், நீங்கள் பரிசுத் தொகையை வெல்வீர்கள்.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game15">4</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game15"></div>
                                    </div>
                                    <div className="aboutGameContentBox2   border-[2px] border-game15">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                        </div>
                                        <div className="text-center  margin-top2">
                                            Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                        </div>
                                    </div>
                                </div> */}
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game15">4</div>
                                                                        </div>
                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                                        </div>
                                                                        <div className="text-center  margin-top2">
                                                                            டிராக்கள் தினமும் நடைபெறும், எங்கள் யூடியூப் சேனல் மற்றும் பேஸ்புக் பக்கத்தில் நேரலை.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aboutGameContentBox">
                                                                    <div className="aboutGameContentBoxType2">
                                                                        <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                                        <div className="aboutGameContentBoxType2Div">
                                                                            <div className="bg-game15">5</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                                        <div className="topDetailDiv">
                                                                            <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                                        </div>
                                                                        <div className="text-center  margin-top2">
                                                                        Gulf Ticket பயணச்சீட்டில் தண்ணீர் பாட்டிலை வாங்குவதன் மூலம், ஐக்கிய அரபு எமிரேட்ஸில் பனை மரத் தோட்டங்களை மேம்படுத்த எங்களுக்கு உதவுகிறீர்கள்.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        i18n.language === 'hi' ?
                                                            <div id="about-me">
                                                                {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBox1">
                                                                            <div>
                                                                                <div className="bg-game15">1</div>
                                                                            </div>
                                                                            <div className="bg-game15"></div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                            </div>

                                                                            <div className="text-center  margin-top2">
                                                                                एक बार में हमारे मुख्य ड्रॉ में प्रवेश करने के लिए AED 5 में एक बोतल खरीदें!
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game15">2</div>
                                                                            </div>
                                                                            <div className="gameAboutBorder bg-game15"></div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                            </div>
                                                                            <div className="text-center  margin-top2">
                                                                                प्रत्येक पंक्ति के लिए 0-9 के बीच कोई भी 3 संख्याएँ चुनें या रैंडमाइज़ेशन
                                                                                "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" पर क्लिक करें और हम आपके लिए आपकी संख्याएँ चुनेंगे। क्लिक

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game15">3</div>
                                                                            </div>
                                                                            <div className="gameAboutBorder bg-game15"></div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                                            </div>
                                                                            <div className="text-center  margin-top2">
                                                                                यदि संख्या आपके चुने गए पैटर्न से मेल खाती है, तो आप पुरस्कार राशि जीतेंगे।
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game15">4</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game15"></div>
                                    </div>
                                    <div className="aboutGameContentBox2   border-[2px] border-game15">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/lucky-abt-4.png?v0.1?V0.1" alt='Lucky1 Icon'/>
                                        </div>
                                        <div className="text-center  margin-top2">
                                            Raffle Draw: {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinAmount}.
                                        </div>
                                    </div>
                                </div> */}
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game15">4</div>
                                                                            </div>
                                                                            <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                                            </div>
                                                                            <div className="text-center  margin-top2">
                                                                                ड्रॉ प्रतिदिन होते हैं, हमारे यूट्यूब चैनल और फेसबुक पेज पर लाइव होते हैं।
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="aboutGameContentBox">
                                                                        <div className="aboutGameContentBoxType2">
                                                                            <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                                            <div className="aboutGameContentBoxType2Div">
                                                                                <div className="bg-game15">5</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                                            <div className="topDetailDiv">
                                                                                <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                                            </div>
                                                                            <div className="text-center  margin-top2">
                                                                                गल्फ टिकट पर पानी की बोतल खरीदकर, आप संयुक्त अरब अमीरात में ताड़ के पेड़ के बागानों के विकास में हमारी सहायता कर रहे हैं।
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            i18n.language === 'vi' ?
                                                                <div id="about-me">
                                                                    {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBox1">
                                                                                <div>
                                                                                    <div className="bg-game15">1</div>
                                                                                </div>
                                                                                <div className="bg-game15"></div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                                </div>

                                                                                <div className="text-center  margin-top2">
                                                                                    Mua một chai nước với giá 5 AED để tham gia XỔ SỐ và XỔ SỐ CHÍNH của chúng tôi chỉ trong một lần!
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game15">2</div>
                                                                                </div>
                                                                                <div className="gameAboutBorder bg-game15"></div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                                </div>
                                                                                <div className="text-center  margin-top2">
                                                                                    Chọn bất kỳ 3 số từ 0-9 cho mỗi dòng hoặc nhấp vào biểu tượng "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" và chúng tôi sẽ chọn các số cho bạn.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game15">3</div>
                                                                                </div>
                                                                                <div className="gameAboutBorder bg-game15"></div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                                                </div>
                                                                                <div className="text-center  margin-top2">
                                                                                    Nếu số trùng khớp với mẫu bạn chọn, bạn sẽ thắng số tiền thưởng.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game15">4</div>
                                                                                </div>
                                                                                <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                                                </div>
                                                                                <div className="text-center  margin-top2">
                                                                                    Rút thăm diễn ra hàng ngày, Trực tiếp trên kênh YouTube và trang Facebook của chúng tôi.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="aboutGameContentBox">
                                                                            <div className="aboutGameContentBoxType2">
                                                                                <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                                                <div className="aboutGameContentBoxType2Div">
                                                                                    <div className="bg-game15">5</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                                                <div className="topDetailDiv">
                                                                                    <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                                                </div>
                                                                                <div className="text-center  margin-top2">
                                                                                    {/* Xổ Số Chính: Khớp các số theo bất kỳ thứ tự nào để giành Giải Thưởng Lớn trị giá {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} AED* */}
                                                                                    Bằng cách mua một chai nước trên Gulf Ticket, bạn đang hỗ trợ chúng tôi trong việc phát triển các đồn điền cây cọ ở Các Tiểu vương quốc Ả Rập Thống nhất.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                i18n.language === 'id' ?
                                                                    <div id="about-me">
                                                                        {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                        <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBox1">
                                                                                    <div>
                                                                                        <div className="bg-game15">1</div>
                                                                                    </div>
                                                                                    <div className="bg-game15"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                                    </div>

                                                                                    <div className="text-center  margin-top2">
                                                                                        Beli Botol seharga AED 5 untuk mengikuti UNDIAN UTAMA kami sekaligus!
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game15">2</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game15"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        Pilih 3 angka antara 0-9 untuk setiap baris atau klik ikon "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" dan kami akan memilihkan angka untuk Anda.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game15">3</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game15"></div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        Jika angka cocok dengan pola pilihan Anda, Anda akan memenangkan hadiah yang telah ditentukan.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game15">4</div>
                                                                                    </div>
                                                                                    <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        Pengundian dilakukan setiap hari, Langsung di saluran YouTube dan halaman Facebook kami.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="aboutGameContentBox">
                                                                                <div className="aboutGameContentBoxType2">
                                                                                    <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                                                    <div className="aboutGameContentBoxType2Div">
                                                                                        <div className="bg-game15">5</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                                                    <div className="topDetailDiv">
                                                                                        <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                                                    </div>
                                                                                    <div className="text-center  margin-top2">
                                                                                        Dengan membeli satu botol air di Gulf Ticket, Anda membantu kami dalam pengembangan perkebunan pohon palem di Uni Emirat Arab.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    i18n.language === 'km' ?
                                                                        <div id="about-me">
                                                                            {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                            <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                                <div className="aboutGameContentBox">
                                                                                    <div className="aboutGameContentBox1">
                                                                                        <div>
                                                                                            <div className="bg-game15">1</div>
                                                                                        </div>
                                                                                        <div className="bg-game15"></div>
                                                                                    </div>
                                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                                                        <div className="topDetailDiv">
                                                                                            <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                                        </div>

                                                                                        <div className="text-center  margin-top2">
                                                                                            ទិញដបទឹកមួយក្នុងតម្លៃ 5 AED ដើម្បីចូលរួមក្នុងការចាប់ឆ្នោត RAFFLE និងឆ្នោតចម្បងប្រចាំសប្តាហ៍របស់យើងក្នុងពេលតែមួយ!
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox">
                                                                                    <div className="aboutGameContentBoxType2">
                                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                        <div className="aboutGameContentBoxType2Div">
                                                                                            <div className="bg-game15">2</div>
                                                                                        </div>
                                                                                        <div className="gameAboutBorder bg-game15"></div>
                                                                                    </div>
                                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                        <div className="topDetailDiv">
                                                                                            <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                                        </div>
                                                                                        <div className="text-center  margin-top2">
                                                                                            ជ្រើសរើសលេខ 3 ចន្លោះពី 0-9 សម្រាប់ជួរនីមួយៗ ឬចុចលើរូបតំណាង "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" ហើយយើងនឹងជ្រើសរើសលេខសម្រាប់អ្នក។
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox">
                                                                                    <div className="aboutGameContentBoxType2">
                                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                        <div className="aboutGameContentBoxType2Div">
                                                                                            <div className="bg-game15">3</div>
                                                                                        </div>
                                                                                        <div className="gameAboutBorder bg-game15"></div>
                                                                                    </div>
                                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                        <div className="topDetailDiv">
                                                                                            <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                                                        </div>
                                                                                        <div className="text-center  margin-top2">
                                                                                            ប្រសិនបើលេខត្រូវគ្នាជាមួយលំនាំដែលអ្នកជ្រើស អ្នកនឹងឈ្នះចំនួនទឹកប្រាក់រង្វាន់។
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox">
                                                                                    <div className="aboutGameContentBoxType2">
                                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                        <div className="aboutGameContentBoxType2Div">
                                                                                            <div className="bg-game15">4</div>
                                                                                        </div>
                                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                    </div>
                                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                        <div className="topDetailDiv">
                                                                                            <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                                                        </div>
                                                                                        <div className="text-center  margin-top2">
                                                                                            ការចាប់ឆ្នោតធ្វើឡើងជារៀងរាល់ថ្ងៃ ផ្សាយផ្ទាល់នៅលើបណ្តាញ YouTube និងទំព័រ Facebook របស់យើង។
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox">
                                                                                    <div className="aboutGameContentBoxType2">
                                                                                        <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                                                        <div className="aboutGameContentBoxType2Div">
                                                                                            <div className="bg-game15">5</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                                                        <div className="topDetailDiv">
                                                                                            <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                                                        </div>
                                                                                        <div className="text-center  margin-top2">
                                                                                            ដោយការទិញដបទឹកមួយលើ Gulf Ticket អ្នកកំពុងជួយគាំទ្រយើងក្នុងការអភិវឌ្ឍដំណាំដើមត្នោតនៅសហរដ្ឋអាមេរិកអារ៉ាប់។
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div id="about-me">
                                                                            {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-10 w-24" /></span></h2> */}
                                                                            <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                                                                <div className="aboutGameContentBox">
                                                                                    <div className="aboutGameContentBox1">
                                                                                        <div>
                                                                                            <div className="bg-game15">1</div>
                                                                                        </div>
                                                                                        <div className="bg-game15"></div>
                                                                                    </div>
                                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 marginLft0 rem11">
                                                                                        <div className="topDetailDiv">
                                                                                            <img src="./assets/img/icons/pick-3-abt-1.png?v0.1" alt='Lucky1 Icon' />
                                                                                        </div>

                                                                                        <div className="text-center  margin-top2">
                                                                                            Purchase a Bottle for AED 5 to enter our MAIN DRAW in one
                                                                                            go!
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox">
                                                                                    <div className="aboutGameContentBoxType2">
                                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                        <div className="aboutGameContentBoxType2Div">
                                                                                            <div className="bg-game15">2</div>
                                                                                        </div>
                                                                                        <div className="gameAboutBorder bg-game15"></div>
                                                                                    </div>
                                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                        <div className="topDetailDiv">
                                                                                            <img src="./assets/img/icons/pick-3-abt-2.png?v0.1" alt='Lucky1 Icon' />
                                                                                        </div>
                                                                                        <div className="text-center  margin-top2">
                                                                                            Choose any 3 numbers between 0-9 for each line or click on the randomisation "
                                                                                            <img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />"  icon and we select your numbers for you.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox">
                                                                                    <div className="aboutGameContentBoxType2">
                                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                        <div className="aboutGameContentBoxType2Div">
                                                                                            <div className="bg-game15">3</div>
                                                                                        </div>
                                                                                        <div className="gameAboutBorder bg-game15"></div>
                                                                                    </div>
                                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                        <div className="topDetailDiv">
                                                                                            <img src="./assets/img/icons/pick-3-abt-3.png?v0.2" alt='Lucky1 Icon' />
                                                                                        </div>
                                                                                        <div className="text-center  margin-top2">
                                                                                            {/* Main Draw: If Zodiac/number is matched, to win the multiple of your purchase amount. */}
                                                                                            If the number is matching with your choosing pattern,you will win the prize amount.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox">
                                                                                    <div className="aboutGameContentBoxType2">
                                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                        <div className="aboutGameContentBoxType2Div">
                                                                                            <div className="bg-game15">4</div>
                                                                                        </div>
                                                                                        <div className="gameAboutBorder bg-game15">&nbsp;</div>
                                                                                    </div>
                                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 rem11">
                                                                                        <div className="topDetailDiv">
                                                                                            <img src="./assets/img/icons/pick-3-abt-4.png?v0.1" alt='Lucky1 Icon' />
                                                                                        </div>
                                                                                        <div className="text-center  margin-top2">
                                                                                            Draws take place daily , Live on our YouTube channel and Facebook page.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="aboutGameContentBox">
                                                                                    <div className="aboutGameContentBoxType2">
                                                                                        <div className="gameAboutBorderLast bg-game15">&nbsp;</div>
                                                                                        <div className="aboutGameContentBoxType2Div">
                                                                                            <div className="bg-game15">5</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="aboutGameContentBox2   border-[2px] border-game15 marginRht0 rem11">
                                                                                        <div className="topDetailDiv">
                                                                                            <img src="./assets/img/icons/pick-3-abt-5.png?v0.1" alt='Lucky1 Icon' />
                                                                                        </div>
                                                                                        <div className="text-center  margin-top2">
                                                                                            By purchasing a water bottle on Gulf Ticket, you are assisting us in the development
                                                                                            of palm tree plantations in the United Arab Emirates.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                            }
                        </div>
                    </div >

                </section>
                <div class="lightboxinfo scrollbar">
                    <div class="lightBox-wrap">
                        <a class="lightBoxClose"><img src="./assets/img/icons/cloes-icon.webp" /></a>
                        <span>
                            <img src="./assets/img/rush-3-info.png?v0.2" />
                        </span>
                    </div>
                </div>
                <div class="overlay"></div>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AboutPickThree))

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../../Header/Header';
import { history } from '../../helpers/history';
import { connect } from 'react-redux';


class Promotionwithcdn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promotionobjList: [],
            Flag: 1,
            storedObj: "",
            baseurl: "",
            Promotionrule: [
                // { "offertype": "LAUNCH EXTRAVAGANZA", "offer_quote": "Offer Lasts Only 3 Weeks After Launch.", "img_no": 5, "date": "offer", "url": "/PromotionRules" },
                // { "offertype": "", "offer_quote": "We are thrilled to announce a significant change to our Super6 Raffle and Draw!", "img_no": 5, "date": "offer", "url": "/OfferSuper6" },
                // { "offertype": "", "offer_quote": "We are thrilled to announce an exciting change to our raffle event! The Fortune4 Raffle is evolving into Fortune6, bringing even more opportunities for you to win incredible prizes!", "img_no": 5, "date": "offer", "url": "/Offerfortune6" },
            ]
        };
    }

    componentDidMount() {
        this.setOfferimgurl();
    }


    OfferDetails = (Obj) => {
        this.setState({ storedObj: Obj })
        this.setState({ Flag: 2 })
        // if (Obj && Obj.url) {
        //     history.push(Obj.url)
        // }
    }

    setOfferimgurl = () => {
        try {
            let hbanner = 0;
            let hbanner_old = localStorage.getItem('promotionver');
            if (this.props.appvariables.bVersion) {
                let hbannerVer = this.props.appvariables.bVersion.split("~")
                if (hbannerVer.length > 0) {
                    hbanner = hbannerVer[2];
                    localStorage.setItem('promotionver', hbanner);
                }
            }
            if (hbanner_old != hbanner) {
                var s = process.env.REACT_APP_CDN_URL + 'web/Webpromotion.json' + "?V" + hbanner;
                fetch(s).then(response => response.json()).then(data => {
                    localStorage.setItem('promotiondata', JSON.stringify(data));
                    this.setState({ baseurl: data.baseurl })
                    this.setState({ Promotionrule: data })
                })
            }
            else {
                let promotiondata = JSON.parse(localStorage.getItem('promotiondata'));
                this.setState({ baseurl: promotiondata.baseurl })
                this.setState({ Promotionrule: promotiondata });
            }
        }
        catch (ex) {

        }
    }


    Comeback = () => {
        this.setState({ sethtml: "" })
        this.setState({ Flag: 1 })
        // history.goBack();
    }

    render() {
        const { t } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div className="wrap2 contentalign">
                    {this.state.Flag == 2 && <button type="button" className="backBtn" onClick={() => {this.Comeback()}}>
                        <img src="./assets/img/icons/Back-Icon.svg" alt='Back-Icon' />
                        <span>{t('lblBack')}</span>
                    </button>}
                    <h2 className="headding3 floatNone">{t('lblPromotionRule')}</h2>
                    {this.state.Flag == 1 && this.state.Promotionrule && this.state.Promotionrule.promotionList && this.state.Promotionrule.promotionList.length > 0 &&
                        <div className="row">
                            <div className="pastDrawResultsSection marginBottomResult">
                                {this.state.Promotionrule.promotionList.map((x, inx) =>
                                    <div className="pastDrawResultSubSection bg-white" key={`bg_${inx}`}>
                                        {inx == 0 && <div class={`dateAbsoluteSection bg-game9`}>{x.protype}</div>}
                                        <div className="WinningContainer">
                                            <div className="WinningCome solid-border paddingNone">
                                                {/* <img  src={`${process.env.REACT_APP_BANNER_CDN}/Web/Promotion/promotions-${x.img_no}.png`} alt="" srcset="" /> */}
                                                <img src={x.imagPath} alt="promotions icon" srcset="" />
                                            </div>
                                            <div className="solid-border solid-border-1 paddingNone margin0 text-left promotionRulesCont">
                                                <p>{x.header}</p>
                                                <span>{x.subHeader}</span>
                                            </div>
                                            <div className="detailsSection">
                                                <a onClick={() => { this.OfferDetails(x) }}><img src="./assets/img/icons/Notes-3.svg" alt="details" /></a>
                                            </div>
                                            {/* <div class="dateAbsoluteSection logoBg !right-[-2.5rem] xl:!right-[-4.5rem] !text-[0.8rem]">
                                        {x.date} */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>}
                    {this.state.Flag == 2 &&
                        <>
                            {this.state.baseurl.length > 0 && <iframe src={this.state.baseurl + this.state.storedObj.htmlFile} width={'100%'} height={document.documentElement.clientWidth-460} allowfullscreen></iframe>}
                        </>

                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    const { serverTime } = state.commonReducer;
    return { user, appvariables, serverTime };
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Promotionwithcdn))


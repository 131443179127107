import { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../helpers/history';
class Offerimage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            img_url: "",
            dataLoadCompleted: false
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            // latestResult: props.latestResult

        }
    }

    componentDidMount() {
        let mythis = this;
        setTimeout(() => { mythis.setOfferimgurl(); }, 2000)//700
    }


    Formbannerimage = (data) => {
        try {
            const mythis = this;
            let HomebannerJsonObj = [];
            data.data.forEach(obj => {
                if (obj) {
                    let startdate = obj.startDate;
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    let strmilliseconds = (new Date(startdate.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
                    if (mythis.props.serverTime >= strmilliseconds) {
                        if (obj.expiryDate != "" && obj.expiryDate != "null") {
                            let enddate = obj.expiryDate;
                            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                            let endmilliseconds = (new Date(enddate.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
                            if (mythis.props.serverTime < endmilliseconds) {
                                obj.imagPath = data.baseurl ? data.baseurl + obj.deskImagPath : obj.deskImagPath;
                                HomebannerJsonObj.push(obj);
                                // if(type == "set"){
                                // localStorage.setItem('offerimgshowtype', obj.);  // day-EveryDayShow,onetime-only one time,5-Every 5 hours.
                                // }
                            }
                        }
                        else {
                            obj.imagPath = data.baseurl ? data.baseurl + obj.deskImagPath : obj.deskImagPath;
                            HomebannerJsonObj.push(obj);
                            // if(type == "set"){
                            // localStorage.setItem('offerimgshowtype', obj.);  // day-EveryDayShow,onetime-only one time,5-Every 5 hours.
                            // }
                        };
                    }
                }
            });
            return HomebannerJsonObj;
        }
        catch (ex) {
            console.log("Formbannerimage => ", ex)
        }
    }

    DaybyDayOption = () => {
        try {
            let flag = localStorage.getItem('offerimgshowtype');
            if (!flag) {
                return false;
            }
            if (flag == "onetime") {
                return false;
            }
            else if (flag == "day") {
                let loginflag = false;
                let lastlogindate = new Date(localStorage.getItem('daybyday')).toISOString().replace('-', '/').split('T')[0].replace('-', '/');
                let lastlogin = new Date(this.props.serverTime);
                if (lastlogindate == null) {
                    localStorage.setItem('daybyday', lastlogin);
                    loginflag = true;
                }
                else {
                    let nowdate = new Date(this.props.serverTime).toISOString().replace('-', '/').split('T')[0].replace('-', '/');
                    let datenow = new Date(this.props.serverTime)
                    if (nowdate != lastlogindate) {
                        localStorage.setItem('daybyday', datenow);
                        return true;
                    }
                    else {
                        return false
                    }
                }
                return loginflag;
            }
            else if (flag != "day" && flag != "onetime") {
                let loginflag = false;
                let lastlogindate = new Date(localStorage.getItem('daybyday'));
                let lastlogin = new Date(this.props.serverTime);
                if (lastlogindate == null) {
                    localStorage.setItem('daybyday', lastlogin);
                    loginflag = true;
                }
                else {
                    let nowtime = new Date(this.props.serverTime);
                    const millisecondsDiff = nowtime.getHours() - lastlogindate.getHours()
                    let date = new Date(lastlogindate).toISOString().replace('-', '/').split('T')[0].replace('-', '/');
                    let nowdate = new Date(this.props.serverTime).toISOString().replace('-', '/').split('T')[0].replace('-', '/');;
                    flag = JSON.parse(flag);
                    let hours = flag * 60;
                    let hoursinmillis = 60000;
                    if (millisecondsDiff > (hours * hoursinmillis) || date != nowdate) {
                        loginflag = true;
                        localStorage.setItem('daybyday', lastlogin);
                    }
                    else {
                        loginflag = false;
                    }
                }
                return loginflag;
            }
        } catch (error) {
            return false;
        }
    }


    setOfferimgurl = () => {
        try { 
            let hbanner = 0;
            let hbanner_old = localStorage.getItem('offerimgver');
            if (this.props.appvariables.bVersion) {
                let hbannerVer = this.props.appvariables.bVersion.split("~")
                if (hbannerVer.length > 0) {
                    hbanner = hbannerVer[6];
                    // localStorage.setItem('offerimgver', hbanner);
                }
            }
            // localStorage.setItem('offerimgshowtype', "5");  // day-EveryDayShow,onetime-only one time,5-Every 5 hours.
            // let DayOptinFlag = this.DaybyDayOption();
            if (hbanner_old != hbanner) {
                var s = process.env.REACT_APP_CDN_URL + 'web/Popup.json' + "?V" + hbanner;
                fetch(s).then(response => response.json()).then(data => {
                    localStorage.setItem('offerimg', JSON.stringify(data));
                    let HomebannerJson = this.Formbannerimage(data);
                    this.setState({ img_url: HomebannerJson });
                    if (HomebannerJson.length > 0) {
                        setTimeout(() => {
                            if (window.HomePageOfferimg)
                                window.HomePageOfferimg()
                        }, 300);
                    }
                })
            }
            // else {
            // localStorage.setItem('offerimgshowtype', "day");  // day-EveryDayShow,onetime-only one time,5-Every 5 hours.
            // let DayOptinFlag = this.DaybyDayOption(AvailOption);
            //     if (DayOptinFlag == true) {
            //         let data = JSON.parse(localStorage.getItem('offerimg'));
            //         let HomebannerJson = this.Formbannerimage(data.'get');
            //         this.setState({ img_url: HomebannerJson });
            //         if (HomebannerJson.length > 0) {
            //             setTimeout(() => {
            //                 if (window.HomePageOfferimg)
            //                     window.HomePageOfferimg()
            //             }, 300);
            //         }
            //     }
            // }
        }
        catch (ex) {

        }
    }

    bnrClck = (url) => {
        try { 
            let home_ban_link = url;
            if (home_ban_link.indexOf("http") >= 0) {
                window.open(home_ban_link, '_blank');
            } else {
                history.push("/" + home_ban_link)
            }
            this.CloseImage();
        } catch (ex) {
            console.log("Offerimage bnrClck ==>", ex)
        }
    }

    CloseImage = () => {
        if(this.props.appvariables.bVersion){
            let hbannerVer = this.props.appvariables.bVersion.split("~");
            if (hbannerVer.length > 0) {
                localStorage.setItem('offerimgver', hbannerVer[6]);
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {this.state.img_url.length > 0 && <div class="lightboxinfo3">
                    <a class="lightBoxClose" onClick={() => {this.CloseImage()}}><img src="./assets/img/icons/cloes-icon.png?v0.1" /></a>
                    <div class="lightBox-wrap">
                        <span>
                            <img onClick={() => { this.bnrClck(this.state.img_url[0].linkUrl) }} src={this.state.img_url[0].imagPath} />
                        </span>
                    </div>
                </div>
                }
            </>
        )
    }

}

function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    const { serverTime } = state.commonReducer;
    return { user, appvariables, serverTime };
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Offerimage))


import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        // window.setAccordian();
    }
    render() {
        const { t } = this.props;
        const { i18n } = this.props;
        return (
            <>
                <h2 className='headding3 float-none'>{t('lblPrivacyPolicy')}</h2>
                {/* {i18n.language === 'ur' ?
                    <div className="addlpageCont">
                        <p>
                            [گلف ٹکٹ جنرل ٹریڈنگ ایل ایل سی] (اس کے بعد اسے "گلف ٹکٹ" کہا جائے گا) اپنے صارفین کی رازداری کی حفاظت کرنے کے لیے پرعزم ہے۔ یہ رازداری کی پالیسی اس بات کی وضاحت کرتی ہے کہ آپ کے ذریعہ ہماری ویب سائٹ اور خدمات استعمال کیے جانے کے دوران ہم آپ کی ذاتی معلومات کو کس طرح اکٹھا، استعمال اور شیئر کرتے ہیں۔

                        </p>
                        <ul className="faqAccordian">
                            <li>
                                <a >وہ معلومات جسے ہم اکٹھی کرتے ہیں</a>
                                <p>ہم آپ سے درج ذیل معلومات اکٹھی کرتے ہیں:</p>
                                <ul>
                                    <li>
                                        <p>ذاتی معلومات جو آپ ہمیں فراہم کرتے ہیں: اس میں آپ کا نام، ای میل پتہ، فون نمبر، تاریخ پیدائش، قومیت اور رہائشی پتہ شامل ہیں۔ جب آپ اکاؤنٹ بنائیں، ڈرا ٹکٹ خریدیں یا مدد کے لیے ہم سے رابطہ کریں تو آپ ہمیں یہ معلومات فراہم کر سکتے ہیں۔</p>
                                    </li>
                                    <li>
                                        <p>	مالیاتی معلومات جو آپ ہمیں فراہم کرتے ہیں: اس میں آپ کا کریڈٹ یا ڈیبٹ کارڈ نمبر، اختتام کی تاریخ اور بلنگ کا پتہ شامل ہیں۔ جب آپ ڈرا ٹکٹ خریدیں تو آپ ہمیں یہ معلومات فراہم کر سکتے </p>
                                    </li>
                                    <li>
                                        <p>	وہ معلومات جو ہم خود بخود اکٹھی کرتے ہیں: اس میں آپ کا آئی پی پتہ، براؤزر کی قسم، آپریٹنگ سسٹم، ڈیوائس کی قسم اور جغرافیائی محل وقوع شامل ہیں۔ ہم اپنی ویب سائٹ پر آپ کی سرگرمی کے بارے میں بھی معلومات اکٹھا کرتے ہیں جیسے کہ آپ کے ذریعہ دیکھے گیے صفحات اور آپ کے ذریعہ خریدے گیے ڈرا </p>
                                    </li>
                                    <li>
                                        <p>	وہ معلومات جو ہم فریق ثالث سے اکٹھی کرتے ہیں: ہم آپ کے بارے میں سوشل میڈیا پلیٹ فارم اور عوامی ڈیٹا بیس جیسے فریق ثالث سے معلومات اکٹھی کر سکتے ہیں۔ اس معلومات میں آپ کا نام، ای میل پتہ اور پروفائل تصویر شامل ہو سکتے ہیں۔</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a >ہم آپ کی معلومات کو کس طرح استعمال کرتے ہیں</a>
                                <p>ہم آپ کی معلومات استعمال کرتے ہیں:</p>
                                <ul>
                                    <li>
                                        <p>	آپ کو اپنی خدمات فراہم کرنے کے لیے: اس میں آپ کے ڈرا ٹکٹ کی خریداری کا موقع فراہم کرنا، آپ کو آپ کی جیت کے بارے میں اطلاعات بھیجنا اور آپ کو صارف سے متعلق اعانت فراہم کرنا شامل ہیں۔</p>
                                    </li >
                                    <li>
                                        <p>	اپنی خدمات کو بہتر بنانے کے لیے: ہم اپنی ویب سائٹ، خدمات اور ڈرا پروڈکٹس کو بہتر بنانے کے لیے آپ کے تاثرات اور سرگرمی کا ڈیٹا استعمال کرتے ہیں۔</p>
                                    </li>
                                    <li>
                                        <p>	آپ کے ساتھ بات چیت کرنے کے لیے: ہم آپ کو اپنی خدمات، پروموشن اور دیگر اہم اپ ڈیٹس کے بارے میں ای میل یا ٹیکسٹ پیغامات بھیج سکتے </p>
                                    </li>
                                    <li>
                                        <p>	اپنے صارفین کو تحفظ فراہم کرنے کے لیے: ہم دھوکہ دہی اور دیگر غیر قانونی سرگرمیوں کو روکنے کے لیے آپ کی معلومات استعمال کرتے ہیں۔</p>
                                    </li>
                                    <li>
                                        <p>	قانونی تقاضوں کی تعمیل کرنے کے لیے: ہم متحدہ عرب امارات میں قوانین اور ضوابط کی تعمیل کے لیے آپ کی معلومات استعمال کر سکتے ہیں۔</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a >ہم آپ کی معلومات کا اشتراک کیسے کرتے ہیں</a>
                                <p>ہم درج ذیل فریق ثالث کے ساتھ آپ کی معلومات کا اشتراک کرتے ہیں:</p>
                                <ul>
                                    <li>
                                        <p>	سروس فراہم کنندگان: ہم ان سروس فراہم کنندگان کے ساتھ آپ کی معلومات کا اشتراک کرتے ہیں جو ہماری ویب سائٹ اور خدمات کو چلانے میں ہماری مدد کرتے ہیں۔ مثال کے طور پر، ہم آپ کی ڈرا ٹکٹ کی خریداری پر کارروائی کے لیے ادائیگی کے پروسیسرز کے ساتھ آپ کی معلومات کا اشتراک کرتے ہیں۔</p>
                                    </li>
                                    <li>
                                        <p>سرکاری ایجنسیاں: ہم سے عدالت کے حکم یا دیگر قانونی عمل کے جواب میں سرکاری ایجنسیوں کے ساتھ آپ کی معلومات کا اشتراک کرنے کا مطالبہ ہو سکتا ہے۔</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a >ڈیٹا سیکیورٹی</a>
                                <ul>
                                    <li>
                                        <p>
                                            ہم آپ کی معلومات کو بلا اجازت دیکھنے، استعمال کرنے یا ظاہر کرنے سے محفوظ رکھنے کے لیے معقول اقدامات کرتے ہیں۔ تاہم، ڈیٹا کو منتقل یا ذخیرہ کرنے کا کوئی طریقہ 100% محفوظ نہیں ہے۔
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a >آپ کے انتخابات</a>
                                <p>آپ کے پاس اپنی ذاتی معلومات کے حوالے سے درج ذیل انتخابات ہیں:</p>
                                <ul>
                                    <li>
                                        <p>	آپ اپنے اکاؤنٹ میں لاگ ان کر کے اپنی ذاتی معلومات کر دیکھ اور اپ ڈیٹ کر سکتے ہیں۔</p>
                                    </li>
                                    <li>
                                        <p>	آپ ان پیغامات کے نیچے دیئے گئے ان سبسکرائب لنک پر کلک کر کے مارکیٹنگ سے متعلق ای میل اور ٹیکسٹ میسجز وصول کرنے سے آپٹ آؤٹ کر سکتے ہیں۔</p>
                                    </li>
                                    <li>
                                        <p>	آپ ہم سے [support@gulfticket.com] پر رابطہ کر کے اپنی ذاتی معلومات کو حذف کرنے کی درخواست کر سکتے ہیں۔</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a >اس رازداری کی پالیسی میں تبدیلیاں</a>
                                <ul>
                                    <li>
                                        <p>ہم وقتا فوقتا اس رازداری کی پالیسی کو اپ ڈیٹ کر سکتے ہیں۔ اگر ہم کوئی مادی تبدیلی کرتے ہیں، تو ہم اپنی ویب سائٹ پر اپ ڈیٹ کردہ رازداری کی پالیسی پوسٹ کریں گے اور آپ کو ای میل کے ذریعے اس کی اطلاع دیں گے۔</p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a >ہم سے رابطہ کریں</a>
                                <p>اگر آپ کے پاس اس رازداری کی پالیسی کے بارے میں کوئی سوال ہے، تو براہ کرم ہم سے [support@gulfticket.com] پر رابطہ کریں۔</p>
                                <p>متحدہ عرب امارات کے رہائشیوں کے لیے اضافی معلومات</p>
                                <p>متحدہ عرب امارات کے قانون کے مطابق، آپ کو اپنی ذاتی معلومات سے متعلق مندرجہ ذیل حقوق حاصل ہیں:</p>
                                <ul>
                                    <li>
                                        <p>	اپنی ذاتی معلومات تک رسائی کا حق۔</p>
                                    </li>
                                    <li>
                                        <p>اپنی ذاتی معلومات میں کسی بھی غلطی کو درست کرنے کا حق۔</p>
                                    </li>
                                    <li>
                                        <p>	اپنی ذاتی معلومات کو مٹانے کا حق۔</p>
                                    </li>
                                    <li>
                                        <p>	اپنی ذاتی معلومات کی کارروائی کو محدود کرنے کا حق۔</p>
                                    </li>
                                    <li>
                                        <p>	اپنی ذاتی معلومات کی کارروائی پر اعتراض کرنے کا حق۔.</p>
                                    </li>
                                    <li>
                                        <p>	ڈیٹا کی انتقال پذیری کا حق۔[support@gulfticket.com]</p>
                                    </li>
                                </ul>
                                <p>ان حقوق میں سے کسی کو استعمال کرنے کے لیے براہ کرم ہم سے [support@gulfticket.com] پر رابطہ کریں</p>
                                <p>ہم مناسب وقت کے اندر آپ کی درخواست کا جواب دیں گے۔</p>
                            </li>
                        </ul>
                    </div>
                    :
                    i18n.language === 'ml' ?
                        <div className="addlpageCont">
                            <p>
                                [ഗൾഫ് ടിക്കറ്റ് ജനറൽ ട്രേഡിംഗ് എൽ എൽ സി] (ഇനി മുതൽ "ഗൾഫ് ടിക്കറ്റ്" എന്ന് പരാമർശിക്കപ്പെടുന്നു) അതിന്റെ ഉപയോക്താക്കളുടെ സ്വകാര്യത പരിരക്ഷിക്കാൻ പ്രതിജ്ഞാബദ്ധമാണ്. ഞങ്ങളുടെ വെബ്സൈറ്റും സേവനങ്ങളും ഉപയോഗിക്കുമ്പോൾ നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങൾ ഞങ്ങൾ എങ്ങനെ ശേഖരിക്കുകയും ഉപയോഗിക്കുകയും പങ്കിടുകയും ചെയ്യുന്നുവെന്ന് ഈ സ്വകാര്യതാ നയം വിശദീകരിക്കുന്നു
                            </p>
                            <ul className="faqAccordian">
                                <li>
                                    <a >ഞങ്ങൾ ശേഖരിക്കുന്ന വിവരങ്ങൾ</a>
                                    <p>നിങ്ങളിൽ നിന്ന് ഇനിപ്പറയുന്ന വിവരങ്ങൾ ഞങ്ങൾ ശേഖരിക്കുന്നു:</p>
                                    <ul>
                                        <li>
                                            <p>നിങ്ങൾ ഞങ്ങൾക്ക് നൽകുന്ന വ്യക്തിഗത വിവരങ്ങൾ: ഇതിൽ നിങ്ങളുടെ പേര്, ഇമെയിൽ വിലാസം, ഫോൺ നമ്പർ, ജനനത്തീയതി, ദേശീയത, റെസിഡൻഷ്യൽ വിലാസം എന്നിവ ഉൾപ്പെടുന്നു. നിങ്ങൾ ഒരു അക്കൗണ്ട് സൃഷ്ടിക്കുമ്പോഴോ നറുക്കെടുപ്പ് ടിക്കറ്റ് വാങ്ങുമ്പോഴോ പിന്തുണയ്ക്കായി ഞങ്ങളെ ബന്ധപ്പെടുമ്പോഴോ നിങ്ങൾക്ക് ഈ വിവരങ്ങൾ ഞങ്ങൾക്ക് നൽകിയേക്കാം</p>
                                        </li>
                                        <li>
                                            <p>നിങ്ങൾ ഞങ്ങൾക്ക് നൽകുന്ന സാമ്പത്തിക വിവരങ്ങൾ: ഇതിൽ നിങ്ങളുടെ ക്രെഡിറ്റ് അല്ലെങ്കിൽ ഡെബിറ്റ് കാർഡ് നമ്പർ, കാലഹരണ തീയതി, ബില്ലിംഗ് വിലാസം എന്നിവ ഉൾപ്പെടുന്നു. നിങ്ങൾ ഒരു നറുക്കെടുപ്പ് ടിക്കറ്റ് വാങ്ങുമ്പോൾ നിങ്ങൾക്ക് ഈ വിവരങ്ങൾ ഞങ്ങൾക്ക് നൽകിയേക്കാം</p>
                                        </li>
                                        <li>
                                            <p>ഞങ്ങൾ യാന്ത്രികമായി ശേഖരിക്കുന്ന വിവരങ്ങൾ: ഇതിൽ നിങ്ങളുടെ ഐപി വിലാസം, ബ്രൗസർ തരം, ഓപ്പറേറ്റിംഗ് സിസ്റ്റം, ഉപകരണ തരം, ഭൂമിശാസ്ത്രപരമായ സ്ഥാനം എന്നിവ ഉൾപ്പെടുന്നു. നിങ്ങൾ സന്ദർശിക്കുന്ന പേജുകളും നിങ്ങൾ വാങ്ങുന്ന നറുക്കെടുപ്പ് ടിക്കറ്റുകളും പോലുള്ള നിങ്ങളുടെ പ്രവർത്തനത്തെക്കുറിച്ചുള്ള വിവരങ്ങളും ഞങ്ങളുടെ വെബ് സൈറ്റിൽ ഞങ്ങൾ ശേഖരിക്കുന്നു.</p>
                                        </li>
                                        <li>
                                            <p>മൂന്നാം കക്ഷികളിൽ നിന്ന് ഞങ്ങൾ ശേഖരിക്കുന്ന വിവരങ്ങൾ: സോഷ്യൽ മീഡിയ പ്ലാറ്റ്ഫോമുകളും പൊതു ഡാറ്റാബേസുകളും പോലുള്ള മൂന്നാം കക്ഷികളിൽ നിന്ന് നിങ്ങളെക്കുറിച്ചുള്ള വിവരങ്ങൾ ഞങ്ങൾ ശേഖരിച്ചേക്കാം. ഈ വിവരങ്ങളിൽ നിങ്ങളുടെ പേര്, ഇമെയിൽ വിലാസം, പ്രൊഫൈൽ ചിത്രം എന്നിവ ഉൾപ്പെട്ടേക്കാം..</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a >നിങ്ങളുടെ വിവരങ്ങൾ ഞങ്ങൾ എങ്ങനെ ഉപയോഗിക്കുന്നു</a>
                                    <p>ഇനിപ്പറയുന്നവയ്ക്കായി ഞങ്ങൾ നിങ്ങളുടെ വിവരങ്ങൾ ഉപയോഗിക്കുന്നു:</p>
                                    <ul>
                                        <li>
                                            <p>ഞങ്ങളുടെ സേവനങ്ങൾ നിങ്ങൾക്ക് നൽകുക: നിങ്ങളുടെ ഡ്രോ ടിക്കറ്റ് വാങ്ങലുകൾ പ്രോസസ്സ് ചെയ്യുക, നിങ്ങളുടെ വിജയങ്ങളെക്കുറിച്ച് അറിയിപ്പുകൾ അയയ്ക്കുക, ഉപഭോക്തൃ പിന്തുണ നൽകുക എന്നിവ ഇതിൽ ഉൾപ്പെടുന്നു.</p>
                                        </li>
                                        <li>
                                            <p>ഞങ്ങളുടെ സേവനങ്ങൾ മെച്ചപ്പെടുത്തുക: ഞങ്ങളുടെ വെബ്സൈറ്റ്, സേവനങ്ങൾ, വരയ്ക്കൽ ഉൽപ്പന്നങ്ങൾ എന്നിവ മെച്ചപ്പെടുത്തുന്നതിന് നിങ്ങളുടെ ഫീഡ്ബാക്കും പ്രവർത്തന ഡാറ്റയും ഞങ്ങൾ ഉപയോഗിക്കുന്നു.</p>
                                        </li>
                                        <li>
                                            <p>നിങ്ങളുമായി ആശയവിനിമയം നടത്തുക: ഞങ്ങളുടെ സേവനങ്ങൾ, പ്രമോഷനുകൾ, മറ്റ് പ്രധാനപ്പെട്ട അപ്ഡേറ്റുകൾ എന്നിവയെക്കുറിച്ചുള്ള ഇമെയിലുകളോ ടെക്സ്റ്റ് സന്ദേശങ്ങളോ ഞങ്ങൾ നിങ്ങൾക്ക് അയച്ചേക്കാം.</p>
                                        </li>
                                        <li>
                                            <p>ഞങ്ങളുടെ ഉപയോക്താക്കളെ പരിരക്ഷിക്കുക: തട്ടിപ്പും മറ്റ് നിയമവിരുദ്ധ പ്രവർത്തനങ്ങളും തടയുന്നതിന് ഞങ്ങൾ നിങ്ങളുടെ വിവരങ്ങൾ ഉപയോഗിക്കുന്നു.</p>
                                        </li>
                                        <li>
                                            <p>നിയമപരമായ ആവശ്യകതകൾ പാലിക്കുക: യുഎഇയിലെ നിയമങ്ങളും ചട്ടങ്ങളും പാലിക്കുന്നതിന് ഞങ്ങൾ നിങ്ങളുടെ വിവരങ്ങൾ ഉപയോഗിച്ചേക്കാം.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a >നിങ്ങളുടെ വിവരങ്ങൾ ഞങ്ങൾ എങ്ങനെ പങ്കിടുന്നു</a>
                                    <p>ഇനിപ്പറയുന്ന മൂന്നാം കക്ഷികളുമായി നിങ്ങളുടെ വിവരങ്ങൾ ഞങ്ങൾ പങ്കിടുന്നു:</p>
                                    <ul>
                                        <li>
                                            <p>സേവന ദാതാക്കൾ: ഞങ്ങളുടെ വെബ്സൈറ്റും സേവനങ്ങളും പ്രവർത്തിപ്പിക്കാൻ ഞങ്ങളെ സഹായിക്കുന്ന സേവന ദാതാക്കളുമായി ഞങ്ങൾ നിങ്ങളുടെ വിവരങ്ങൾ പങ്കിടുന്നു. ഉദാഹരണത്തിന്, നിങ്ങളുടെ ഡ്രോ ടിക്കറ്റ് വാങ്ങലുകൾ പ്രോസസ്സ് ചെയ്യുന്നതിന് പേയ്മെന്റ് പ്രോസസ്സറുകളുമായി ഞങ്ങൾ നിങ്ങളുടെ വിവരങ്ങൾ പങ്കിടുന്നു.</p>
                                        </li>
                                        <li>
                                            <p>സർക്കാർ ഏജൻസികൾ: ഒരു സമൻസ് അല്ലെങ്കിൽ മറ്റ് നിയമപരമായ പ്രക്രിയയ്ക്ക് മറുപടിയായി ഞങ്ങൾ നിങ്ങളുടെ വിവരങ്ങൾ സർക്കാർ ഏജൻസികളുമായി പങ്കിടേണ്ടതുണ്ട്.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a >ഡാറ്റാ സുരക്ഷ</a>
                                    <ul>
                                        <li>
                                            <p>നിങ്ങളുടെ വിവരങ്ങൾ അനധികൃതമായി കൈകാര്യം ചെയ്യൽ, ഉപയോഗം അല്ലെങ്കിൽ വെളിപ്പെടുത്തൽ എന്നിവയിൽ നിന്ന് പരിരക്ഷിക്കുന്നതിന് ഞങ്ങൾ ന്യായമായ നടപടികൾ കൈക്കൊള്ളുന്നു. എന്നിരുന്നാലും, വിവരങ്ങൾ കൈമാറ്റത്തിന്റെയോ സംഭരണത്തിന്റെയോ ഒരു രീതിയും 100% സുരക്ഷിതമല്ല.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a >നിങ്ങളുടെ തിരഞ്ഞെടുപ്പുകൾ</a>
                                    <p>നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങളുമായി ബന്ധപ്പെട്ട് നിങ്ങൾക്ക് ഇനിപ്പറയുന്ന തിരഞ്ഞെടുക്കലുകൾ ഉണ്ട്:</p>
                                    <ul>
                                        <li>
                                            <p>നിങ്ങളുടെ അക്കൗണ്ടിലേക്ക് ലോഗിൻ ചെയ്തുകൊണ്ട് നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങൾ കൈകാര്യം ചെയ്യാനും അപ്ഡേറ്റ് ചെയ്യാനും കഴിയും.</p>
                                        </li>
                                        <li>
                                            <p> ഈ സട്ന്ദശങ്ങളുപ്പട െുെപ്പട ുള്ള അൺസബ്സ്സരകബ് ലിങ്കിൽ ക്ലിക്കുപ്പെയ്തുപ്പകാണ്ട് നിങ്ങൾക്ക് മാർക്കറ്റിിംഗ് ഇപ്പമ ിലുകളുിം പ്പടക്സ്റ്റ് സട്ന്ദശങ്ങളുിം സവീകരിക്കുന്നത് ഒ ിൊക്കാിം. </p>
                                        </li>
                                        <li>
                                            <p>[support@gulfticket.com] എന്ന വിലാസത്തിൽ ഞങ്ങളെ ബന്ധപ്പെട്ടുകൊണ്ട് നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങൾ ഇല്ലാതാക്കാൻ നിങ്ങൾക്ക് അഭ്യർത്ഥിക്കാം</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a >ഈ സ്വകാര്യതാ നയത്തിലെ മാറ്റങ്ങൾ</a>
                                    <ul>
                                        <li>
                                            <p>ഞങ്ങൾ ഈ സ്വകാര്യതാ നയം കാലാകാലങ്ങളിൽ അപ്ഡേറ്റ് ചെയ്തേക്കാം. ഞങ്ങൾ എന്തെങ്കിലും മെറ്റീരിയൽ മാറ്റങ്ങൾ വരുത്തുകയാണെങ്കിൽ, ഞങ്ങളുടെ വെബ്സൈറ്റിൽ അപ്ഡേറ്റുചെയ്ത സ്വകാര്യതാ നയം ഞങ്ങൾ പോസ്റ്റുചെയ്യുകയും ഇമെയിൽ വഴി നിങ്ങളെ അറിയിക്കുകയും ചെയ്യും.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a >ഞങ്ങളെ ബന്ധപ്പെടുക</a>
                                    <p>ഈ സ്വകാര്യതാ നയത്തെ കുറിച്ച് നിങ്ങൾക്ക് എന്തെങ്കിലും ചോദ്യങ്ങൾ ഉണ്ടെങ്കിൽ, ദയവായി ഞങ്ങളെ ബന്ധപ്പെടുക [support@gulfticket.com]</p>
                                    <p>യുഎഇ നിവാസികൾക്കുള്ള അധിക വിവരങ്ങൾ</p>
                                    <p>യുഎഇ നിയമം അനുസരിച്ച്, നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങൾ സംബന്ധിച്ച് നിങ്ങൾക്ക് ഇനിപ്പറയുന്ന അവകാശങ്ങളുണ്ട്:</p>
                                    <ul>
                                        <li>
                                            <p>നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങൾ കൈകാര്യം ചെയ്യാനുള്ള അവകാശം.</p>
                                        </li>
                                        <li>
                                            <p>നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങളിൽ എന്തെങ്കിലും കൃത്യതയില്ലായ്മ ഉണ്ടെങ്കിൽ തിരുത്താനുള്ള അവകാശം.</p>
                                        </li>
                                        <li>
                                            <p>നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങൾ മായ്ച്ചുകളയാനുള്ള അവകാശം. </p>
                                        </li>
                                        <li>
                                            <p>നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങളുടെ പ്രോസസ്സിംഗ് നിയന്ത്രിക്കാനുള്ള അവകാശം.</p>
                                        </li>
                                        <li>
                                            <p>നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങളുടെ പ്രോസസ്സിംഗിനെ എതിർക്കാനുള്ള അവകാശം.</p>
                                        </li>
                                        <li>
                                            <p>ഡാറ്റയ്ക്കുള്ള അവകാശം portability.[support@gulfticket.com]</p>
                                        </li>
                                    </ul>
                                </li>
                                <p>ഈ അവകാശങ്ങളിൽ ഏതെങ്കിലും വിനിയോഗിക്കുന്നതിന്, ദയവായി ഞങ്ങളെ ബന്ധപ്പെടുക [support@gulfticket.com]</p>
                                <p>ന്യായമായ സമയത്തിനുള്ളിൽ നിങ്ങളുടെ അഭ്യർത്ഥനയോട് ഞങ്ങൾ പ്രതികരിക്കും.</p>
                            </ul>
                        </div> :
                        i18n.language === 'ar' ?
                            <div className="addlpageCont">
                                <p>
                                    تلتزم شركة [تذكرة الخليج للتجارة العامة ذ.م.م] (المشار إليها باسم "تذكرة الخليج") بحماية خصوصية مستخدميها. يشرح هذا البيان سياسة الخصوصية وكيف نقوم بجمع معلوماتك الشخصية واستخدامها ومشاركتها عند استخدام موقعنا وخدماتنا.

                                </p>
                                <ul className="faqAccordian">
                                    <li>
                                        <a >    معلوماتك الشخصية</a>
                                        <p>  نحن نجمع المعلومات التالية منك"</p>
                                        <ul>
                                            <li>
                                                <p>
                                                    المعلومات الشخصية:  تتضمن اسمك وعنوان بريدك الإلكتروني ورقم هاتفك وتاريخ ميلادك وجنسيتك وعنوان إقامتك. يمكنك تزويدنا بهذه المعلومات عند إنشاء حساب، أو شراء تذكرة سحب، أو الاتصال بنا للحصول على الدعم.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    المعلومات المالية: تتضمن رقم بطاقة الصراف الآلي أو بطاقة الائتمان الخاصة بك، وتاريخ انتهاء الصلاحية، وعنوان إرسال الفواتير. كذلك يمكنك تزويدنا بهذه المعلومات عند شراء تذكرة السحب.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    المعلومات التي نجمعها تلقائيًا: يتضمن ذلك عنوان IP الخاص بك ونوع المتصفح ونظام التشغيل ونوع الجهاز والموقع الجغرافي.
                                                    نقوم أيضًا بجمع معلومات حول نشاطك على موقعنا الإلكتروني، مثل الصفحات التي تزورها وتذاكر السحب التي تشتريها.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    المعلومات التي نجمعها من أطراف ثالثة: قد نجمع معلومات عنك من أطراف ثالثة، مثل منصات التواصل الاجتماعي وقواعد البيانات العامة.
                                                    قد تتضمن هذه المعلومات اسمك وعنوان بريدك الإلكتروني وصورة ملفك الشخصي.
                                                </p>
                                            </li>

                                        </ul>
                                    </li>
                                    <li>
                                        <a >   كيف نستخدم معلوماتك</a>
                                        <p>  نحن نستخدم معلوماتك من أجل:</p>
                                        <ul>
                                            <li><p>تزويدك بالخدمات: يتضمن ذلك معالجة مشتريات تذاكر السحب الخاصة بك، وإرسال إشعارات إليك حول أرباحك، وتزويدك بخدمة دعم العملاء.</p></li>
                                            <li><p>تحسين خدماتنا: نستخدم تعليقاتك وبيانات نشاطك لتحسين موقعنا الإلكتروني وخدماتنا ومنتجاتنا.</p></li>
                                            <li><p>التواصل معك: قد نرسل إليك رسائل بريد إلكتروني أو رسائل نصية حول خدماتنا وعروضنا الترويجية وغيرها من التحديثات المهمة.</p></li>
                                            <li><p>حماية مستخدمينا: نستخدم معلوماتك لمنع الاحتيال والأنشطة غير القانونية الأخرى.</p></li>
                                            <li><p>الامتثال للمتطلبات القانونية: قد نستخدم معلوماتك للامتثال للقوانين واللوائح في دولة الإمارات العربية المتحدة.</p></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a>  كيف نشارك معلوماتك </a>
                                        <p> نحن نشارك معلوماتك مع الأطراف الثالثة التالية:</p>
                                        <ul>
                                            <li><p>
                                                مقدمي الخدمات: نشارك معلوماتك مع مقدمي الخدمات الذين يساعدوننا في تشغيل موقعنا وخدماتنا. على سبيل المثال، نشارك معلوماتك مع معالجي عمليات الدفع لإدارة عمليات شراء تذاكر السحب الخاصة بك.
                                            </p></li>
                                            <li><p>
                                                الوكالات الحكومية: قد يُطلب منا مشاركة معلوماتك مع الوكالات الحكومية ردًا على أمر استدعاء أو أي إجراء قانوني آخر.
                                            </p></li>

                                        </ul>
                                    </li>
                                    <li>
                                        <a > حماية البيانات</a>
                                        <ul>
                                            <li><p>
                                                نتخذ إجراءات أمنية مشددة لحماية بياناتك الخاصة من الوصول الغير موثوق أو كشفها من غير تصريح.  ومع ذلك لا توجد أي طريقة لنقل المعلومات آمنة بنسبة 100%.
                                            </p></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a >خياراتك</a>
                                        <p>لديك الخيارات التالية بخصوص معلوماتك الشخصية:</p>
                                        <ul>
                                            <li><p >يمكنك الوصول لمعلوماتك الشخصية وتحديثها عن طريق تسجيل الدخول إلى حسابك</p></li>
                                            <li><p >يمكنك إلغاء الاشتراك في تلقي رسائل البريد الإلكتروني والرسائل النصية التسويقية من خلال النقر على رابط إلغاء الاشتراك الموجود أسفل هذه الرسائل.</p></li>
                                            <li><p>يمكنك طلب حذف معلوماتك الشخصية عن طريق التواصل عبر البريد الإلكتروني التالي [support@gulfticket.com]</p></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a >التغييرات في سياسة الخصوصية </a>
                                        <p> قد نقوم بتحديث بيان الخصوصية بين الحين والآخر، إذا أجرينا أي تغييرات جوهرية، فسننشر سياسة الخصوصية المحدثة على موقعنا ونشعرك عبر البريد الإلكتروني.</p>

                                    </li>
                                    <li>
                                        <a >اتصل بنا</a>
                                        <p>إذا كانت لديك أي أسئلة حول بيان سياسة الخصوصية، يرجى الاتصال بنا على [support@gulfticket.com]
                                        </p>
                                        <p>معلومات إضافية للمقيمين في دولة الإمارات العربية المتحدة</p>
                                        <p> وفقًا لقانون دولة الإمارات العربية المتحدة، لديك الحقوق التالية فيما يتعلق بمعلوماتك الشخصية:</p>
                                        <ul>
                                            <li><p>حق الوصول لمعلوماتك الشخصية</p></li>
                                            <li><p>حق تصحيح أي أخطاء في معلوماتك الشخصية.</p></li>
                                            <li><p>حق حذف المعلومات الشخصية</p></li>
                                            <li><p>حق تقييد معالجة معلوماتك الشخصية.</p></li>
                                            <li><p>حق الاعتراض على معالجة معلوماتك الشخصية.</p></li>
                                            <li><p>حق إمكانية نقل البيانات [support@gulfticket.com]</p></li>
                                        </ul>
                                        <p>لممارسة أي حق من هذه الحقوق، يرجى التواصل بنا على [support@gulfticket.com]</p>
                                        <p>وسوف نقوم بالرد على طلبك خلال فترة زمنية مناسبة.</p>
                                    </li>

                                </ul>
                            </div> : */}
                            <div className="mt-5">
                                <h3 className="text-base lg:text-xl font-semibold">Privacy Policy</h3>
                                <div className="text-sm">Last updated: January 24, 2024</div>

                                <p className="mt-5">
                                    This Privacy Policy describes Our policies and procedures for the collection, use, and
                                    disclosure of Your information when You use the Platform (Gulf Ticket Website or App)
                                    and tells You about Your privacy rights and how the law protects You.
                                </p>
                                <p className="mt-3">
                                    We use Your Personal data to provide and improve the Platform. By using the Platform,
                                    You agree to the collection and use of information in accordance with this Privacy
                                    Policy.
                                </p>
                                <p className="mt-3">
                                    This privacy statement has been prepared based on provisions of multiple legislations,
                                    including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
                                </p>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Owner and Data Controller</h3>
                                    For the purposes of this Terms & Conditions:
                                    <div className="mt-2 lg:mt-3">
                                        {/* <h4 className="text-base font-semibold">Miraki Future Investments and Holdings LLC-FZ</h4>
                                        <div>Dubai, UAE.</div> */}
                                        <div>Owner contact email: support@gulfticket.com</div>
                                    </div>

                                    <div className="mt-2 lg:mt-3">
                                        <h4 className="text-base font-semibold">DPO Contact</h4>
                                        <div>Dubai, UAE.</div>
                                        <div>DPO contact email: dpo@gulfticket.com</div>
                                    </div>
                                </div>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Interpretation and Definitions</h3>

                                    <h4 className="text-base lg:text-lg font-semibold">Interpretation</h4>
                                    <p>
                                        The words for which the initial letter is capitalized have meanings defined under the
                                        following conditions: The following definitions shall have the same meaning
                                        regardless of whether they appear in singular or plural.
                                    </p>
                                    <h4 className="text-base lg:text-lg font-semibold mt-2 lg:mt-3">Definitions</h4>
                                    For the purposes of this Privacy Policy:
                                    <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                        <li>
                                            Account means a unique account created for You to access our Platform or parts of
                                            our Platform.
                                        </li>
                                        <li>
                                            Application refers to Gulf Ticket App, the software program provided by the Company.
                                        </li>
                                        {/* <li>
                                            Company (referred to as either "the Company", "We", "Us" or "Our" in this Policy)
                                            refers to Miraki Future Investments and Holdings LLC-FZ, Dubai, UAE.
                                        </li> */}
                                        <li>
                                            Cookies are small files that are placed on Your computer, mobile device, or any
                                            other device by a website, containing the details of Your browsing history on that
                                            website, among its many uses.
                                        </li>
                                        <li>Country refers to: United Arab Emirates.</li>
                                        <li>
                                            Device means any device that can access the Platform such as a computer, a cell
                                            phone, or a digital tablet.
                                        </li>
                                        <li>
                                            Personal Data is any information that relates to an identified or identifiable
                                            individual.
                                        </li>
                                        <li>Platform refers to the Application or the Website or both.</li>
                                        <li>
                                            Service Provider means any natural or legal person who processes the data on behalf
                                            of the Company. It refers to third-party companies or individuals employed by the
                                            Company to facilitate the Service, to provide the Service on behalf of the Company,
                                            to perform services related to the Service or to assist the Company in analyzing how
                                            the Service is used.
                                        </li>
                                        <li>
                                            Usage Data refers to data collected automatically, either generated by the use of
                                            the Platform or from the Platform infrastructure itself (for example, the duration
                                            of a page visit).
                                        </li>
                                        <li>Website refers to Gulf Ticket, accessible from www.gulfticket.com.</li>
                                        <li>
                                            You,means the individual accessing or using the Platform, or the company, or other
                                            legal entity on behalf of which such individual is accessing or using the Platform,
                                            as applicable.
                                        </li>
                                    </ul>
                                </div>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">
                                        Collecting and Using Your Personal Data
                                    </h3>

                                    <h4 className="text-base lg:text-lg font-semibold">Types of Data Collected</h4>
                                    <div className="italic font-semibold mt-3">Personal Data</div>
                                    <p>
                                        While using Our Platform, We may ask You to provide Us with certain personally
                                        identifiable information that can be used to contact or identify You. Personally
                                        identifiable information may include, but is not limited to:
                                    </p>

                                    <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                        <li>Email Address</li>
                                        <li>First Name and Last Name</li>
                                        <li>Mobile Number</li>
                                        <li>Date of Birth</li>
                                        <li>Nationality</li>
                                    </ul>

                                    <h4 className="text-base lg:text-lg font-semibold mt-3">Usage Data</h4>
                                    Usage Data is collected automatically when using the Platform.
                                    <p className="mt-3">
                                        Usage Data may include information such as Your Device's Internet Protocol address
                                        (e.g., IP address), browser type, browser version, the pages of our Platform that You
                                        visit, the time and date of Your visit, the time spent on those pages, unique device
                                        identifiers, and other diagnostic data.
                                    </p>
                                    <p className="mt-3">
                                        When You access the Platform by or through a mobile device, We may collect certain
                                        information automatically, including, but not limited to, the type of mobile device
                                        You use, Your mobile device unique ID, the IP address of Your mobile device, Your
                                        mobile operating system, the type of mobile Internet browser You use, unique device
                                        identifiers, and other diagnostic data.
                                    </p>
                                    <p className="mt-3">
                                        We may also collect information that Your browser sends whenever You visit our
                                        Platform or when You access the Platform by or through a mobile device.
                                    </p>

                                    <h4 className="text-base lg:text-lg font-semibold mt-3">
                                        Tracking Technologies and Cookies
                                    </h4>
                                    <p>
                                        We use Cookies and similar tracking technologies to track activity on Our Platform and
                                        store certain information. Tracking technologies used are beacons, tags, and scripts
                                        to collect and track information and to improve and analyze Our Platform. The
                                        technologies We use may include:
                                    </p>

                                    <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                        <li>
                                            Cookies or Browser Cookies. A Cookie is a small file placed on Your Device. You can
                                            instruct Your browser to refuse all Cookies or to indicate when a Cookie is being
                                            sent. However, if You do not accept Cookies, You may not be able to use some parts
                                            of our Platform. Unless you have adjusted Your browser setting so that it will
                                            refuse Cookies, our Platform may use Cookies.
                                        </li>
                                        <li>
                                            Web Beacons. Certain sections of our Platform and our emails may contain small
                                            electronic files known as web beacons (also referred to as clear gifs, pixel tags,
                                            and single-pixel gifs) that permit the Company, for example, to count users who have
                                            visited those pages or opened an email and for other related website statistics (for
                                            example, recording the popularity of a certain section and verifying system and
                                            server integrity).
                                        </li>
                                        <li>
                                            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your
                                            personal computer or mobile device when You go offline, while Session Cookies are
                                            deleted as soon as You close Your web browser.
                                        </li>
                                    </ul>
                                    <p className="my-4">
                                        We use both Session and Persistent Cookies for the purposes set out below:
                                    </p>
                                    <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                        <li>Necessary / Essential Cookies</li>
                                        <li>Type: Session Cookies</li>
                                        <li>Administered by: Us</li>
                                    </ul>

                                    <p className="mt-3">
                                        <span className="font-semibold">Purpose</span>: These Cookies are essential to provide You
                                        with services available through the Website and to enable You to use some of its
                                        features. They help to authenticate users and prevent fraudulent use of user accounts.
                                        Without these Cookies, the services that You have asked for cannot be provided, and We
                                        only use these Cookies to provide You with those services.
                                    </p>

                                    <div className="mt-3 italic font-semibold">Cookies Policy / Notice Acceptance Cookies</div>
                                    <div><span className="font-semibold">Type:</span> Persistent Cookies</div>
                                    <div><span className="font-semibold">Administered by:</span> Us</div>
                                    <div>
                                        <span className="font-semibold">Purpose:</span>
                                        These Cookies identify if users have accepted the use of cookies on the Website.
                                    </div>

                                    <div className="mt-3 italic font-semibold">Functionality Cookies</div>
                                    <div><span className="font-semibold">Type:</span> Persistent Cookies</div>
                                    <div><span className="font-semibold">Administered by:</span> Us</div>
                                    <div>
                                        <span className="font-semibold">Purpose:</span>
                                        These Cookies allow us to remember choices You make when You use the Website, such as
                                        remembering your login details or language preference. The purpose of these Cookies is
                                        to provide You with a more personal experience and to avoid You having to re-enter
                                        your preferences every time You use the Website.
                                    </div>
                                    <p className="mt-3">
                                        For more information about the cookies we use and your choices regarding cookies,
                                        please visit our Cookies Policy or the Cookies section of our Privacy Policy.
                                    </p>
                                </div>
                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Analytics</h3>
                                    <p>
                                        The services contained in this section enable the Owner to monitor and analyze web
                                        traffic and can be used to keep track of User behavior.
                                    </p>
                                </div>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Google Analytics (Google LLC)</h3>
                                    <p>
                                        Google Analytics is a web analysis service provided by Google LLC (“Google”). Google
                                        analyzes the Data collected to track and examine the use of this Platform, to prepare
                                        reports on its activities, and share them with the Owner and other Google services.
                                        Google may use the Data collected to contextualize and personalize the ads on its own
                                        advertising network.
                                    </p>
                                    <p className="mt-3">Personal Data processed: Cookies; Usage Data.</p>
                                    <p>
                                        Place of Processing: United States – Privacy Policy – Opt-Out. Privacy Shield
                                        participant.
                                    </p>
                                    <p>Category of personal data collected: Internet information.</p>
                                </div>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">
                                        Google Analytics Advertising Reporting Features
                                    </h3>
                                    <p>
                                        Google Analytics has Advertising Reporting Features activated on this Platform, which
                                        collects additional information from the DoubleClick cookie (web activity) and from
                                        device advertising IDs (app activity). It allows the Owner to analyze specific
                                        behavior and interest Data (traffic Data and Users' ad interaction Data) and, if
                                        enabled, demographic Data (information about the age and gender of Users).
                                    </p>
                                    <p className="mt-3">
                                        Users can opt out of Google's use of Cookies by visiting Google's Ads Settings.
                                        Personal Data processed: Cookies; unique device Identifiers for advertising (Google
                                        Advertiser ID or IDFA, for example); various types of Data as specified in the privacy
                                        policy of the service. Place of Processing: United States – Privacy Policy – Opt-Out.
                                        Privacy Shield participant.
                                    </p>
                                    <p className="mt-3">
                                        Category of personal data collected: Internet information and Identifiers.
                                    </p>
                                </div>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Phone Contact</h3>
                                    <p>
                                        Users who provided their telephone number might be contacted for commercial or
                                        promotional purposes related to this Platform, as well as for fulfilling support
                                        requests.
                                    </p>
                                    <p className="mt-3">Personal Data processed: Telephone number.</p>
                                    <p>Category of personal data collected: Identifiers.</p>
                                </div>
                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">
                                        Displaying content from external platforms
                                    </h3>
                                    <p>
                                        This service allows Users to view content hosted on external platforms directly from
                                        the pages of this Platform and to interact with them. This type of service might still
                                        collect web traffic data for the pages where the service is installed, even when Users
                                        do not use it.
                                    </p>
                                </div>
                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Google Fonts</h3>
                                    <p>
                                        Google Fonts is a typeface visualization service provided by Google LLC that allows
                                        this Platform to incorporate content of this kind on its pages.
                                    </p>

                                    <p className="mt-3">
                                        Personal Data processed: Usage Data; various types of Data as specified in the privacy
                                        policy of the service.
                                    </p>
                                    <p>Place of Processing: United States – Privacy Policy. Privacy Shield participant.</p>
                                    <p>Category of personal data collected: Internet information.</p>
                                </div>
                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Netcore</h3>
                                    <p>Personal Data processed: Email Address, Gender and Purchase information</p>
                                    <p>Category of personal data collected: Personal marketing information</p>
                                </div>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">YouTube</h3>
                                    <p>
                                        YouTube is a video content visualization service provided by Google LLC, whose widget
                                        allows this Platform to incorporate video content on its pages.
                                    </p>

                                    <p className="mt-3">Personal Data processed: Cookies and Usage Data.</p>
                                    <p>Place of Processing: United States – Privacy Policy. Privacy Shield participant.</p>
                                    <p>Category of personal data collected: Internet information.</p>
                                </div>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Use of Your Personal Data</h3>
                                    <p>The Company may use Personal Data for the following purposes:</p>
                                    <p>
                                        To provide and maintain our Platform, including to monitor the usage of our Platform.
                                    </p>

                                    <p className="mt-3">
                                        To manage Your Account: to manage Your registration as a user of the Platform. The
                                        Personal Data You provide can give You access to different functionalities of the
                                        Platform that are available to You as a registered user.
                                    </p>
                                    <p className="mt-3">
                                        For the performance of a contract, the development, compliance, and undertaking of the
                                        purchase contract for the products, items, or services You have purchased or of any
                                        other contract with Us through the Platform.
                                    </p>

                                    <p className="mt-3">
                                        To contact You: To contact You by email, telephone calls, SMS, or other equivalent
                                        forms of electronic communication, such as a mobile application's push notifications
                                        regarding updates or informative communications related to the functionalities,
                                        products, or contracted services, including the security updates, when necessary or
                                        reasonable for their implementation.
                                    </p>

                                    <p className="mt-3">
                                        To provide You with news, special offers, and general information about other goods,
                                        services, and events that we offer that are similar to those that you have already
                                        purchased or inquired about, unless You have opted not to receive such information.
                                    </p>

                                    <p className="mt-3">To manage Your requests: To attend and manage Your requests to Us.</p>

                                    <p className="mt-3">
                                        For business transfers, We may use Your information to evaluate or conduct a merger,
                                        divestiture, restructuring, reorganization, dissolution, or other sale or transfer of
                                        some or all of Our assets, whether as a going concern or as part of bankruptcy,
                                        liquidation, or similar proceeding, in which Personal Data held by Us about our
                                        Platforms users is among the assets transferred.
                                    </p>

                                    <p className="mt-3">
                                        For other purposes: We may use Your information for other purposes, such as data
                                        analysis, identifying usage trends, determining the effectiveness of our promotional
                                        campaigns, and to evaluate and improve our Platform, products, services, marketing,
                                        and your experience.
                                    </p>
                                    <p className="mt-3">We may share Your personal information in the following situations:</p>

                                    <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                        <li>
                                            <span className="font-semibold">With Service Providers:</span> We may share Your
                                            personal information with Service Providers to monitor and analyze the use of our
                                            Platform, to contact You.
                                        </li>
                                        <li>
                                            <span className="font-semibold">For business transfers:</span> We may share or transfer
                                            Your personal information in connection with, or during negotiations of, any merger,
                                            sale of Company assets, financing, or acquisition of all or a portion of Our
                                            business to another company.
                                        </li>
                                        <li>
                                            <span className="font-semibold">With Affiliates:</span> We may share Your information
                                            with Our affiliates, in which case we will require those affiliates to honor this
                                            Privacy Policy. Affiliates include Our parent company and any other subsidiaries,
                                            joint venture partners, or other companies that We control or that are under common
                                            control with Us.
                                        </li>
                                        <li>
                                            <span className="font-semibold">With business partners:</span> We may share Your
                                            information with Our business partners to offer You certain products, services, or
                                            promotions.
                                        </li>
                                        <li>
                                            <span className="font-semibold">With other users:</span> when You share personal
                                            information or otherwise interact in public areas with other users, such information
                                            may be viewed by all users and may be publicly distributed outside.
                                        </li>
                                        <li>
                                            <span className="font-semibold">With Your Consent:</span> We may disclose Your personal
                                            information for any other purpose with Your Consent.
                                        </li>
                                        <li>
                                            <span className="font-semibold">Retention of Your Personal Data:</span> The Company will
                                            retain Your Personal Data only for as long as is necessary for the purposes set out
                                            in this Privacy Policy. We will retain and use Your Personal Data to the extent
                                            necessary to comply with our legal obligations (for example, if we are required to
                                            retain your data to comply with applicable laws), resolve disputes, and enforce our
                                            legal agreements and policies.
                                        </li>
                                    </ul>
                                    <p className="mt-3">
                                        The Company will also retain Usage Data for internal analysis purposes. Usage Data is
                                        generally retained for a shorter period of time, except when this data is used to
                                        strengthen the security or to improve the functionality of Our Platform, or We are
                                        legally obligated to retain this data for longer time periods.
                                    </p>
                                </div>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">
                                        Your Privacy Rights & How to Exercise Them
                                    </h3>
                                    <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                        <li>
                                            <span className="font-semibold">Know & Access:</span> Learn what information we collect,
                                            why we use it, and who receives it. Request a portable copy of your data.
                                        </li>
                                        <li>
                                            <span className="font-semibold">Delete:</span> Ask us to erase your personal
                                            information, with some legal exceptions.
                                        </li>
                                        <li>
                                            <span className="font-semibold">Exercise Your Rights:</span> Submit a verifiable request
                                            with your identity and clear details.
                                        </li>
                                        <li>
                                            <span className="font-semibold">Response & Timeline:</span> We'll confirm receipt,
                                            respond within 45 days (up to 90 days if needed), and explain any denials. No fees
                                            unless requests are excessive.
                                        </li>
                                    </ul>
                                </div>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Delete Your Personal Data</h3>
                                    <p>
                                        You have the right to delete or request that We assist in deleting the Personal Data
                                        that We have collected about You.
                                    </p>
                                    <p className="mt-3">
                                        Our Platforms may give You the ability to delete certain information about You from
                                        within the Platform.
                                    </p>
                                    <p className="mt-3">
                                        You may update, amend, or delete Your information at any time by signing in to Your
                                        Account, if you have one, and visiting the account settings section that allows you to
                                        manage Your personal information. You may also contact Us to request access to,
                                        correct, or delete any personal information that You have provided to Us.
                                    </p>
                                    <p className="mt-3">
                                        Please note, however, that We may need to retain certain information when we have a
                                        legal obligation or lawful basis to do so.
                                    </p>
                                </div>

                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Disclosure of Your Personal Data</h3>
                                    <h4 className="text-base font-semibold">Business Transactions</h4>
                                    <p>
                                        If the Company is involved in a merger, acquisition or asset sale, Your Personal Data
                                        may be transferred. We will provide notice before Your Personal Data is transferred
                                        and becomes subject to a different Privacy Policy
                                    </p>
                                    <h4 className="text-base font-semibold mt-3">Law enforcement</h4>
                                    <p>
                                        Under certain circumstances, the Company may be required to disclose Your Personal
                                        Data if required to do so by law or in response to valid requests by public
                                        authorities (e.g. a court or a government agency).
                                    </p>
                                    <h4 className="text-base font-semibold mt-3">Other legal requirements</h4>
                                    <p>
                                        The Company may disclose Your Personal Data in the good faith belief that such action
                                        is necessary to:
                                    </p>
                                    <h4 className="text-base font-semibold mt-3">Comply with a legal obligation</h4>
                                    <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
                                        <li>Protect and defend the rights or property of the Company</li>
                                        <li>Prevent or investigate possible wrongdoing in connection with the Platform</li>
                                        <li>Protect the personal safety of Users of the Platform or the public</li>
                                        <li>Protect against legal liability</li>
                                    </ul>
                                </div>
                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Security of Your Personal Data</h3>
                                    <p>
                                        The security of Your Personal Data is important to Us, but remember that no method of
                                        transmission over the Internet, or method of electronic storage is 100% secure. While
                                        We strive to use commercially acceptable means to protect Your Personal Data, We
                                        cannot guarantee its absolute security.
                                    </p>
                                </div>
                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Links to Other Websites</h3>
                                    <p>
                                        Our Platform may contain links to other websites that are not operated by Us. If You
                                        click on a third party link, You will be directed to that third party's site. We
                                        strongly advise You to review the Privacy Policy of every site You visit.
                                    </p>
                                    <p className="mt-3">
                                        We have no control over and assume no responsibility for the content, privacy policies
                                        or practices of any third party sites or services.
                                    </p>
                                </div>
                                <div className="mt-3 lg:mt-7">
                                    <h3 className="text-base lg:text-xl font-semibold">Changes to this Privacy Policy</h3>
                                    <p>
                                        We may update Our Privacy Policy from time to time. We will notify You of any changes
                                        by posting the new Privacy Policy on this page.
                                    </p>
                                    <p className="mt-3">
                                        We will let You know via email and/or a prominent notice on Our Platform, prior to the
                                        change becoming effective and update the "Last updated" date at the top of this
                                        Privacy Policy.
                                    </p>
                                    <p className="mt-3">
                                        You are advised to review this Privacy Policy periodically for any changes. Changes to
                                        this Privacy Policy are effective when they are posted on this page.
                                    </p>
                                </div>
                            </div>
                {/* } */}
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
    logout: loginActions.logout
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(PrivacyPolicy))
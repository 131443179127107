import React, { Component } from 'react';
import { commonClass } from './helpers/commonClass';
import { withTranslation } from 'react-i18next';
import { history } from './helpers/history';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        let _dd = errorInfo ? ", errorInfo : " + JSON.stringify(errorInfo) : "";
        commonClass.writeLog(error + _dd, "componentDidCatch");

        this.setState({
            error: error
        })
        this.state.error = error;
        this.state.errorInfo = _dd;
        let errodflg = localStorage.getItem('erromsg');        
        if (!errodflg || errodflg != error) {
            localStorage.setItem('erromsg', error);                        
            setTimeout(() => {
                window.location.reload(true);
            }, 3000);
        }
    }

    GotoHome = () => {
        history.push('/home')
    }

    render() {
        const { t } = this.props;
        if (this.state.error) {

            return (
                <div style={{ textAlign: "center" }}>
                    {/* <h2 style={{ color: "red" }}>{t('lblPleaseTryAgain')}.<a href={`${process.env.REACT_APP_BASENAME}/Home`}> {t('lblClick')}</a></h2> */}
                    <h2 style={{ color: "red" }}>{t('lblPleaseTryAgain')}.<a onClick={() => {this.GotoHome()}}> {t('lblClick')}</a></h2>
                    <p style={{ color: "white" }}>{this.state.error} {this.state.errorInfo}</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary)
import { appConstants } from "../helpers/actionType";
import { commonClass } from "../helpers/commonClass";

let user = null;
let appVar = null;
let pagemenu = null;
let regotp = null;
try {
    if (commonClass.decrypt) {
        try {
            user = commonClass.decrypt(localStorage.getItem('user'));
        }
        catch (ex) {
            user = localStorage.getItem('user')
        }
    }
    user = JSON.parse(user);
    user && !user.userid && localStorage.setItem('user', commonClass.encrypt(JSON.stringify({ userid: "0", username: "", mobileNo: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, Imageinfo: " ", Created_date: "", Player_name: "", Country_code: "" })));
    user = user && user.userid ? user : { userid: "0", username: "", mobileNo: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, Imageinfo: " ", Created_date: "", Player_name: "", Country_code: "" };
    appVar = JSON.parse(localStorage.getItem('appvariables'));
    // let Cvalue =commonClass.SetCountryCode();
    // appVar = appVar ? appVar : { countryCode: "", currency: Cvalue, version: process.env.REACT_APP_Version, bVersion: "0~0~0~0~0~0~0" };
    appVar = appVar ? appVar : { countryCode: "", currency: process.env.REACT_APP_CURRENCY, version: process.env.REACT_APP_Version, bVersion: "0~0~0~0~0~0~0" };
    pagemenu = localStorage.getItem('pagemenuid');
    pagemenu = pagemenu ? pagemenu : "0";
    regotp = JSON.parse(localStorage.getItem('regotp'));
    regotp = regotp ? regotp : {};
}
catch (ex) {
    user = user ? user : { userid: "0", username: "", mobileNo: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, Imageinfo: " ", Created_date: "", login_Time: 0 };
    appVar = appVar ? appVar : { countryCode: process.env.REACT_APP_Countrycode, currency: process.env.REACT_APP_CURRENCY, version: process.env.REACT_APP_Version, bVersion: "0~0~0~0~0~0~0" };
    pagemenu = pagemenu ? pagemenu : "0";
    localStorage.setItem('user', commonClass.encrypt(JSON.stringify(user)));
}
const initialState = {
    loggedIn: true, user, appvariables: appVar,
    pageFlog: 0, pageMenuID: pagemenu, LightBoxID: 0, otp: regotp
};
export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case appConstants.LOGIN_SUCCESS:
            let Appvalue = state.appvariables;
            // 62 == indonesian,971 == Dubai,66 == Thailand, (Load currency based on login country)
            // if (action.userdata.Country_code == '62' || action.userdata.Country_code == '971' || action.userdata.Country_code == '66') {
            let CountryCode = commonClass.LoadCountry();
            if (CountryCode && CountryCode.length > 0) {
                // After login Country based on load Currency. 
                let get_code_obj = CountryCode.filter(a => a.country_code == action.userdata.Country_code);
                if (get_code_obj && get_code_obj.length > 0) {
                    localStorage.setItem('setuserCuncy', JSON.stringify(get_code_obj[0]))
                    Appvalue = { ...Appvalue, currency: get_code_obj[0].symbol }
                    localStorage.setItem('appvariables', JSON.stringify(Appvalue));
                }
                else {
                    // default Country load to After Login.
                    let get_code_obj = CountryCode.filter(a => a.country_code == '971');
                    if (get_code_obj && get_code_obj.length > 0) {
                        localStorage.setItem('setuserCuncy', JSON.stringify(get_code_obj[0]))
                        Appvalue = { ...Appvalue, currency: get_code_obj[0].symbol }
                        localStorage.setItem('appvariables', JSON.stringify(Appvalue));
                    }
                }
            }
            // }
            return {
                ...state,
                user: action.userdata,
                appvariables: Appvalue
            };
        case appConstants.LOGIN_ERROR:
            return {
                pageFlog: state.pageFlog,
                appvariables: state.appvariables
            };
        case appConstants.SET_PAGEID:
            return {
                ...state,
                LightBoxID: action.pageFlog
            };
        case appConstants.SET_LOGIN_OTP:
            localStorage.setItem('regotp', JSON.stringify(action.otp))
            return {
                ...state,
                otp: action.otp
            };
        case appConstants.LOGOUT:
            //for reload cart value
            let _lastloginmycart = JSON.parse(localStorage.getItem('lastloginmycart'))
            if (!_lastloginmycart) {
                localStorage.setItem('lastloginmycart', localStorage.getItem('mycart'));
            }
            localStorage.removeItem('mycart');
            let SetLogoutUser = { userid: "0", username: "", mobileNo: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, Imageinfo: " ", Created_date: "", Player_name: "", Country_code: process.env.REACT_APP_Countrycode };
            localStorage.setItem('user', commonClass.encrypt(JSON.stringify(SetLogoutUser)));
            return {
                user: SetLogoutUser,
                pageFlog: state.pageFlog,
                appvariables: state.appvariables
            };
        case appConstants.GET_BALANCE:
            let userdata = state.user;
            if (userdata != undefined) {
                userdata.balance = (parseFloat(action.balance) / 100); //31//
                userdata.winbalance = (parseFloat(action.winbalance) / 100);
                localStorage.setItem('user', commonClass.encrypt(JSON.stringify(userdata)));
            }
            return {
                ...state,
                user: { ...state.user, balance: userdata.balance, winbalance: userdata.winbalance }
            };
        case appConstants.UPDATE_PROFILE_IMG:
            let ouser = state.user;
            if (ouser != undefined) {
                ouser.Imageinfo = action.img;
                ouser.Player_name = action.name;
                ouser.emailID = action.email;
                localStorage.setItem('user', commonClass.encrypt(JSON.stringify(ouser)));
            }
            return {
                ...state,
                user: { ...state.user, Imageinfo: ouser.Imageinfo, Player_name: ouser.Player_name, emailID: action.email }
            };
        case appConstants.GET_BANNER_VERSION:
            let appVarl = state.appvariables;
            if (appVarl != undefined) {
                appVarl.bVersion = action.gbanner;//home banner ~ winner ~ promotion ~ past draw show
                localStorage.setItem('appvariables', JSON.stringify(appVarl));
            }
            return {
                ...state,
                appvariables: { ...state.appvariables, bVersion: action.gbanner }
            };
        case appConstants.GET_USER_CURRENCY:
            let appVarlc = state.appvariables;
            if (appVarlc != undefined) {
                // Set User Select Currency set Only Symbol For Currency
                appVarlc.currency = action.currency.symbol;
                //appVarlc.currency = action.currency.code; 
                localStorage.setItem('appvariables', JSON.stringify(appVarlc));
            }
            return {
                ...state,
                appvariables: { ...state.appvariables, currency: action.currency.symbol }
            };
        default:
            return { ...state }
    }
}
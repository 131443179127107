import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from './component/Pages/Home/Home';
import Footer from './component/Footer/Footer';
import { history } from './component/helpers/history';
import { PrivateRoute } from './component/helpers/PrivateRoute';
import Common from './component/Pages/Common';
import BetSlip from './component/Pages/BetSlip';
import Result from './component/Pages/Result';
import GeneralInfo from './component/Pages/GeneralInfo/GeneralInfo';
import ErrorBoundary from './component/Error';
import Login from './component/Login';
import Otpgeneration from './component/Otpgeneration';
import Register from './component/Register';
import Lotto from './component/Pages/Games/Lotto';
import MyCart from './component/Pages/MyCart';
import Payment from './component/Pages/Payment';
import PaymentVerify from './component/Pages/PaymentVerify';
import PaymentStatus from './component/Pages/PaymentStatus';
import Faqs from './component/Pages/GeneralInfo/Faqs';
import Account from './component/Pages/MyAccount/Account';
import Mydraws from './component/Pages/Draw/Mydraws';
import HowToPlayPage from './component/Pages/GeneralInfo/HowToPlayPage';
import UnderConstruction from './component/Pages/GeneralInfo/UnderConstruction';
import { commonClass } from './component/helpers/commonClass';
import { withTranslation } from 'react-i18next';
import OtpForgetPassword from './component/OtpForgetPassword';
import ForgetPassword from './component/ForgetPassword';
import Promotion from './component/Pages/GeneralInfo/Promotion';
import PromotionRules from './component/Pages/GeneralInfo/PromotionRules';
import RegisterActivation from './component/RegisterActivation';
import TermsCondition from './component/Pages/GeneralInfo/TermsCondition';
import Viewdetailedresults from './component/Pages/Draw/Viewdetailedresults';
import PageNotFound from './component/Pages/404';
import Promotionterms from './component/Pages/GeneralInfo/Promotionterms';
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import SetCookie from './component/Cookie/SetCookie';
import ContactUs from './component/Pages/GeneralInfo/ContactUs';
import WinnersStory from './component/Pages/Draw/WinnersStory';
import Feedback from './component/Pages/GeneralInfo/Feedback';
import PrivacyPolicyPage from './component/Pages/GeneralInfo/PrivacyPolicyPage';
import OfferSuper6 from './component/Pages/GeneralInfo/OfferSuper6';
import OfferFortune6 from './component/Pages/GeneralInfo/OfferFortune6';
import Promotionwithcdn from './component/Pages/GeneralInfo/Promotionwithcdn';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locadet: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1].toLowerCase()
    }
    history.listen((location, action) => {
      console.log("New version is : " + process.env.REACT_APP_Version);
      console.log("New version is : " + location.path);
    });
    let _myapp = JSON.parse(localStorage.getItem('appvariables'));
    if (_myapp && process.env.REACT_APP_Version != _myapp.version) {
      let Cvalue = commonClass.SetCountryCode();
      localStorage.setItem('appvariables', JSON.stringify({ countryCode: "", currency: Cvalue, version: process.env.REACT_APP_Version, bVersion: _myapp.bVersion }));//bVersion: _myapp.bVersion
      // window.location.href = window.location.href;
      // window.location.replace(window.location.href);
      window.location.reload(true);
    }
    if (_myapp == null || _myapp == undefined) {
      let Cvalue = commonClass.SetCountryCode();
      // 0 => get banner ,1 => our winners image.,2 => Promotion ,3 => PastDraw Shows(Utube Video Upload),4 => Winner Story Youtube Url,5 => Get NewsLetter Json,6 => OfferImage Show Page 
      localStorage.setItem('appvariables', JSON.stringify({ countryCode: process.env.REACT_APP_Countrycode, currency: Cvalue, version: process.env.REACT_APP_Version, bVersion: "0~0~0~0~0~0~0" })); //bVersion: "0~0~0~0"
    }
    let _user = localStorage.getItem('user');
    if (_user == null || _user == undefined) {
      //this.props.loginSkip(1);
      let userdata = { userid: "0", username: "", mobileNo: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, Imageinfo: " ", Created_date: "", Player_name: "", Country_code: process.env.REACT_APP_Countrycode };
      localStorage.setItem('user', commonClass.encrypt(JSON.stringify(userdata)));
    }
    //window.loadscriptLacy(); //implement in index.html file.
  }

  componentDidMount() {
    let myloc = history.location.pathname;
    commonClass.SendPageEvent(myloc);
    commonClass.SendFacebookpixel();
    commonClass.SendEventTracking(20, {});
    if (myloc.toLowerCase() == '/fortune4' || myloc.toLowerCase() == '/fortune5') {
      history.push('/fortune6');
    }
    else if (myloc.toLowerCase() == '/fortune4draws' || myloc.toLowerCase() == '/fortune5draws') {
      history.push('/fortune6draws');
    }


    // var _c = new Date().getTime();
    // const script = document.createElement("script");
    // script.src = "https://trk.convserv.com/tracko/v1/cont/cont.js?of=330&ac=7&af=20&cs=0&ts={" + _c + "}";
    // script.async = true;
    // document.body.appendChild(script);
  }
  render() {
    return (
      <>
        {process.env.REACT_APP_MODE == "production" && <Helmet
          script={[
            helmetJsonLdProp({
              "@context": "http://www.schema.org",
              "@type": "WebSite",
              name: "Gulf Ticket ",
              alternateName: "Gulf Ticket India",
              url: "https://gulfticket.com/"
            }),
          ]}>
          <link rel="canonical" href="https://gulfticket.com/" />
          <meta name="google-site-verification" content="6rb-DK-9U641DxLR8qJzqkkXq5fJcpAOl68exvxO7hs" />
        </Helmet>}
        {/* <div dir={this.props.i18n.language == 'ar' || this.props.i18n.language == 'ur' ? "rtl" : "ltr"} lang={this.props.i18n.language == 'ar' ? "ar" : "en"} className="mainContainer"> */}
        <div className="mainContainer">
          <ErrorBoundary>
            <Router basename={process.env.REACT_APP_BASENAME} history={history}>
              <Common />
              <BetSlip />
              <SetCookie launguage={this.props} />
              <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <Route exact path='/home' component={Home} />
                {/* <Route exact path='/fortune4' render={() => <Lotto lottotype="9" />} />
                <Route exact path='/super6' render={() => <Lotto lottotype="10" />} />
                <Route exact path='/magic5' render={() => <Lotto lottotype="11" />} />
                <Route exact path='/lucky1' render={() => <Lotto lottotype="13" />} /> */}
                <Route exact path='/fortune6' render={() => <Home lottotype="9" />} />
                <Route exact path='/super6' render={() => <Home lottotype="10" />} />
                <Route exact path='/magic5' render={() => <Home lottotype="11" />} />
                <Route exact path='/lucky1' render={() => <Home lottotype="13" />} />
                <Route exact path='/dream5' render={() => <Home lottotype="14" />} />
                <Route exact path='/rush3' render={() => <Home lottotype="15" />} />
                {/* <Route exact path='/Result' component={Result} /> */}
                <Route exact path='/generalinfo' component={GeneralInfo} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/Otpgeneration' component={Otpgeneration} />
                <Route exact path='/register' component={Register} />
                <Route exact path='/mycart' component={Payment} />  {/* MyCart */}
                <Route exact path='/payment' component={Payment} />
                <Route exact path='/paymentverify' component={PaymentVerify} />
                <Route exact path='/paymentstatus' component={PaymentStatus} />
                {/* <Route exact path='/promotion' component={GeneralInfo} /> */}
                <Route exact path='/faqs' component={Faqs} />
                <Route exact path='/HowtoPlay' component={HowToPlayPage} />
                <Route exact path='/Account' component={Account} />
                <Route exact path='/UnderConstruction' component={UnderConstruction} />
                <Route exact path='/fortune6draws' render={() => <Mydraws drawtype="9" />} />
                <Route exact path='/super6draws' render={() => <Mydraws drawtype="10" />} />
                <Route exact path='/magic5draws' render={() => <Mydraws drawtype="11" />} />
                <Route exact path='/othersdraws' render={() => <Mydraws drawtype="12" />} />
                <Route exact path='/lucky1draws' render={() => <Mydraws drawtype="13" />} />
                <Route exact path='/dream5draws' render={() => <Mydraws drawtype="14" />} />
                <Route exact path='/rush3draws' render={() => <Mydraws drawtype="15" />} />
                <Route exact path='/OtpForgetPassword' component={OtpForgetPassword} />
                <Route exact path='/ForgetPassword' component={ForgetPassword} />
                <Route exact path='/promotion' component={Promotion} />
                {/* <Route exact path='/promotion' component={Promotionwithcdn} /> */}
                <Route exact path='/PromotionRules' component={PromotionRules} />
                <Route exact path='/registerActivation' component={RegisterActivation} />
                <Route exact path='/termscondition' component={TermsCondition} />
                <Route exact path="/Viewdetailedresults" component={Viewdetailedresults} />
                <Route exact path="/promotionterms" component={Promotionterms} />
                <Route exact path="/Contactus" component={ContactUs} />
                <Route exact path="/winnersstory" component={WinnersStory} />
                <Route exact path="/feedback" component={Feedback} />
                <Route exact path="/Reload" component={PageNotFound} />
                <Route exact path="/404" component={PageNotFound} />
                {/* <Route exact path="/*" component={PageNotFound} /> */}
                <Route exact path="/Privacy" component={PrivacyPolicyPage} />
                <Route exact path="/OfferSuper6" component={OfferSuper6} />
                <Route exact path="/Offerfortune6" component={OfferFortune6} />
                <Route exact path="/*" render={() => <Redirect to='/404' />} />
              </Switch>
            </Router>
          </ErrorBoundary>
        </div>
        {(this.state.locadet == 'termscondition') ?
          <></> : <Footer Pageloc={this.state.locadet} />
        }
      </>
    );
  }
}
//export default App;
export default withTranslation()(App)
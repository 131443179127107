import React, { Component } from 'react';
import { connect } from 'react-redux';
import { gameActions } from '../../action/GameAction';
import Header from '../../Header/Header';
import { alertActions } from '../../action/AlertAction';
import { withTranslation } from 'react-i18next';
import Countdown, { zeroPad } from 'react-countdown';
import PriceBreakdown from './PriceBreakdown';
import BrandTrust from './BrandTrust';
import HowToPlay from './HowToPlay';
import LatestResults from './LatestResults';
import DownloadApp from './DownloadApp';
import { history } from '../../helpers/history';
import { commonClass } from "../../helpers/commonClass";
import { Helmet } from "react-helmet";
import { loginActions } from '../../action/loginAction';
import SaleClose from '../SaleClosed/SaleClose';
import SetCookie from '../../Cookie/SetCookie';
import { appVariables } from '../../helpers/appVariables';
import AboutFortureFive from '../Games/AboutFortureFive';
import AboutMgicFive from '../Games/AboutMgicFive';
import AboutSuperSix from '../Games/AboutSuperSix';
import AboutLuckyOne from '../Games/AboutLuckyOne';
import AboutDreamFive from '../Games/AboutDreamFive';
import AboutPickThree from '../Games/AboutPickThree';
import Offerimage from '../../ShowOffer/Offerimage';
let refecthgamecnt = 0;
let TotalGames = 6;
let lastCountDownTime = Date.now();
const locky1Arr = [1, 2, 5, 10];
const pick3BetTypeLst = [{ betname: 'Straight', id: 1 }, { betname: 'Box', id: 2 }, { betname: 'Front Pair', id: 3 }, { betname: 'Back Pair', id: 4 }, { betname: 'Range', id: 5 }, { betname: 'Any Two', id: 6 }];
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawCompleted: false,
            cartItems: [],
            random: [],
            autoApplyCountmax: 10,//max pad count
            myfavNoID: 0,
            otherGameCount: 0,
            dataFatchCompleted: false,
            ActiveSlider: 1,
            showSaleCloseInfo: false,
            showSaleCloseSuper: false,
            bannerList: [],
            SaleCloseInfoMagic: false,
            SaleCloseInfoDream: false,
            MagicGameId: 0,
            SuperGameId: 0,
            FortuneGameId: 0,
            PrevCartFlag: false,
            SaleCloseInfoLucky: false,
            LuckyGameId: 0,
            myUCDraw: [],
            lottoType: props.lottotype ? props.lottotype : null,
            currentDraw: {},
            padCount: 2,//default pad count
            padCountmax: 31,//max pad count
            CartmaxLength: 50,
            OldcartItems: [],
            showSaleClosePick3: false,
            Pick3GameId: 0
        };
        // props.ClearCart();
        this.props.getBanner(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey)
        props.closeBetSlip();
        let tmr = setInterval(
            () => {
                if (this.props.serverTime && this.props.serverTime > 0) {
                    props.GameAvailableGames(props.user.mobileNo, 0, props.i18n.language, this.props.serverTime, this.props.user.loginKey);
                    clearInterval(tmr);
                }
            }, 1000)

        let tmr12 = setInterval(
            () => {
                if (this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0 && this.props.serverTime && this.props.serverTime > 0) {
                    clearInterval(tmr12);
                    let _fdraw = null;
                    if (this.state.lottoType) {
                        _fdraw = this.state.currentAvailableDraws.filter(a => a.gamegroupID == this.state.lottoType).sort((a, b) => parseInt(b.SaleStatus) <= parseInt(a.SaleStatus) ? 1 : -1);
                        if (_fdraw && _fdraw.length > 0) {
                            _fdraw = _fdraw[0];
                        }
                        else {
                            _fdraw = { gamegroupID: this.state.lottoType };
                        }
                    }
                    else {
                        // as per kalai commnet ,defaul load s6
                        // let _fdraws6 = this.state.currentAvailableDraws.filter(a => a.gamegroupID == 10).sort((a, b) => parseInt(b.SaleStatus) <= parseInt(a.SaleStatus) ? 1 : -1);
                        // if (_fdraws6 && _fdraws6.length > 0) {
                        //     _fdraw = _fdraws6[0];
                        // }
                        // else {
                        let _stdateStr = "";
                        let _stdate = null;
                        if (this.props.serverTime && this.props.serverTime > 0) {
                            _stdate = new Date(this.props.serverTime);
                        }
                        else {
                            _stdate = new Date();
                        }
                        _stdateStr = ("0" + _stdate.getDate()).slice(-2) + "/" + ("0" + (_stdate.getMonth() + 1)).slice(-2) + "/" + _stdate.getFullYear();
                        let _flst = this.state.currentAvailableDraws.filter(a => a.DrawDate == _stdateStr && a.SaleStatus == 0).sort((a, b) => parseInt(b.SaleStatus) <= parseInt(a.SaleStatus) ? 1 : -1);
                        if (_flst && _flst.length > 0) {//check today draw is available
                            let _flst1 = _flst.filter(a => a.gamegroupID != 13 && a.gamegroupID != 15);//check main draw available,today draw
                            if (_flst1 && _flst1.length > 0) {
                                _fdraw = _flst1[0];//set main draw,today draw
                            }
                            else {
                                _fdraw = _flst[0];//set lucky1 draw,today draw
                            }
                        }
                        else {
                            //set tmr draw
                            _stdate.setDate(_stdate.getDate() + 1);
                            _stdateStr = ("0" + _stdate.getDate()).slice(-2) + "/" + ("0" + (_stdate.getMonth() + 1)).slice(-2) + "/" + _stdate.getFullYear();
                            let _flst = this.state.currentAvailableDraws.filter(a => a.DrawDate == _stdateStr && a.SaleStatus == 0).sort((a, b) => parseInt(b.SaleStatus) <= parseInt(a.SaleStatus) ? 1 : -1);
                            if (_flst && _flst.length > 0) {
                                _fdraw = _flst[0];
                            }
                            else {
                                _fdraw = this.state.currentAvailableDraws.sort((a, b) => parseInt(b.SaleStatus) <= parseInt(a.SaleStatus) ? 1 : -1)[0];
                            }
                        }

                        // }

                    }
                    this.setCurrentDraw(_fdraw);
                    // due to collapse implementedF
                    // let _mdraw = document.getElementById("game_" + _fdraw.gamegroupID)?.getBoundingClientRect();
                    // if (_mdraw) {
                    //     document.getElementById("game_all").scrollLeft = _mdraw.left - _mdraw.width;
                    // }
                    if (this.state.lottoType) {
                        setTimeout(() => {
                            document.getElementById("game_" + _fdraw.gamegroupID)?.scrollIntoView({ block: 'start', behavior: 'smooth' });
                        }, 2000);
                    }
                    let inxx = 2;
                    let _tcion = "+";
                    [13, 15, 14, 11, 9, 10].forEach(element => {
                        if (element == _fdraw.gamegroupID) {
                            inxx = 5;
                            _tcion = "-";
                        }
                        else {
                            if (document.getElementById("game_" + element)) {
                                document.getElementById("game_" + element).style.zIndex = inxx;
                                inxx = _tcion == "+" ? (inxx + 1) : (inxx - 1);
                            }
                        }
                    });
                }
            }, 200)
    }

    componentDidMount() {
        window.backtotop();
        if (window.gamescrol) {
            window.gamescrol();
        }
        else if (localStorage.getItem('hfrsh') == null) {
            localStorage.setItem('hfrsh', 1);
            window.location.reload(true);
        }
        let mythis = this;
        mythis.setjsondata();
        setTimeout(() => { mythis.setjsondata(1); }, 2000)//700
        setTimeout(() => {
            if ((!this.state.currentAvailableDraws || this.state.currentAvailableDraws.length == 0) && localStorage.getItem("fis") != "1") {
                localStorage.setItem("fis", "1");
                window.location.href = window.location.href;
            }
            // else if (this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0 && this.state.dataFatchCompleted == false) {
            //     this.setState({ dataFatchCompleted: true });
            // }
        }, 2000);
        window.addEventListener("visibilitychange", function () { mythis.handleFocus(); })
        let Obj = {
            'language': this.props.i18n.language
        }
        commonClass.SendEventTracking(16, Obj)
        if (localStorage.getItem("lid") === null)
            localStorage.removeItem('lid');


        let tmr1 = setInterval(
            () => {
                let getsrvtime = JSON.parse(localStorage.getItem('stimeis'));
                if ((!this.props.serverTime || this.props.serverTime < 0) && (getsrvtime == null || getsrvtime < 3)) {
                    getsrvtime = getsrvtime + 1;
                    localStorage.setItem('stimeis', getsrvtime)
                    window.location.reload();
                }
                else {
                    localStorage.removeItem('stimeis')
                    clearInterval(tmr1);
                }
            }, 3000)
    };

    handleFocus = () => {
        try {
            if (document.visibilityState == "visible") {
                let CtotalGamelen = [...new Set(this.state.currentAvailableDraws.filter(Obj => Obj.SaleStatus == 0).map(item => item.gamegroupID))].length;
                if (!this.state.currentAvailableDraws || CtotalGamelen != TotalGames) {
                    this.props.GameFamily(0, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);//typeID,mobileno,langID
                    setTimeout(() => {
                        refecthgamecnt = 0;
                        this.state.dataFatchCompleted = false;
                    }, 500);
                }
            }
        }
        catch (ex) {
            console.log("document visibilityState", ex)
        }
    }
    componentDidUpdate() {
        var lstrdata = localStorage.getItem('alertmsg');
        if (lstrdata != null) {
            this.props.alertsucc(lstrdata);
        }
        this.loadPrevCartItem();
        this.reFetchTheGameData(); // game loading performance changes.
    }

    reFetchTheGameData = () => {
        try {
            if (this.state.dataFatchCompleted == false) {
                let CtotalGamelen = [...new Set(this.state.currentAvailableDraws.filter(Obj => Obj.SaleStatus == 0).map(item => item.gamegroupID))].length;
                // this.state.currentAvailableDraws.filter(Obj => Obj.SaleStatus == 0).length
                if ((!this.state.currentAvailableDraws || CtotalGamelen != TotalGames) && refecthgamecnt < 2) {
                    console.log("refecthgamecnt " + refecthgamecnt);
                    let mythis = this;
                    refecthgamecnt++;
                    let regame = setInterval(() => {
                        // alert("alert component")
                        mythis.props.GameAvailableGames(mythis.props.user.mobileNo, 0, mythis.props.i18n.language, mythis.props.serverTime, mythis.props.user.loginKey);
                        clearInterval(regame)
                    }, (2000 * refecthgamecnt));
                }
                else {
                    this.state.dataFatchCompleted = true;
                    this.setState({ dataFatchCompleted: true });
                    // After game Close Next Game Automatic Fetch. 
                    setTimeout(() => {
                        if (this.state.currentDraw && this.state.currentDraw.SaleStatus != 0) {
                            let setcdraw = this.state.currentAvailableDraws.filter(a => a.gamegroupID == this.state.currentDraw.gamegroupID);
                            if (setcdraw && setcdraw.length > 0) {
                                this.setCurrentDraw(setcdraw[0])
                            }
                        }
                    }, 3000);
                }
                //for multi draw
                if (this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0) {
                    if (this.state.myUCDraw && this.state.myUCDraw.length > 0) {
                        //-remove closed draw selection gameID
                        let lst = [];
                        this.state.myUCDraw.forEach(element => {
                            let fobj = this.state.currentAvailableDraws.filter(Obj => Obj.gamegroupID == this.state.currentDraw.gamegroupID && Obj.GameId == element && Obj.SaleStatus != 1);
                            if (fobj && fobj.length > 0) {
                                lst.push(fobj[0])
                            }
                        });
                        this.state.myUCDraw = [lst[0].GameId];
                        //-remove closed draw selection gameID
                    }
                    let fobj = this.state.currentAvailableDraws.filter(Obj => Obj.gamegroupID == this.state.currentDraw.gamegroupID && Obj.SaleStatus == 0);
                    if ((!this.state.myUCDraw || this.state.myUCDraw.length == 0) && fobj.length > 0) {
                        this.state.myUCDraw = [fobj[0].GameId];
                    }
                }
            }
            else if (this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0) {
                //default draw selection issue solved
                let fobj = this.state.currentAvailableDraws.filter(Obj => Obj.gamegroupID == this.state.currentDraw.gamegroupID && Obj.SaleStatus == 0);
                if ((!this.state.myUCDraw || this.state.myUCDraw.length == 0) && fobj.length > 0) {
                    this.state.myUCDraw = [fobj[0].GameId];
                }
            }
        }
        catch (ex) {
            console.log("reFetchTheGameData => " + ex)
        }
    };

    static getDerivedStateFromProps(props, state) {
        return {
            currentAvailableDraws: props.currentAvailableDraws,
            MyFavNos: props.MyFavNos,
        }
    }
    loadPrevCartItem = () => {
        if (this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0 && this.state.PrevCartFlag == false) {
            this.state.PrevCartFlag = true;
            let prevCartItem = localStorage.getItem('mycart') && localStorage.getItem('mycart') == '' ? null : JSON.parse(localStorage.getItem('mycart'));
            // window.SetWidthCountDown();
            if (prevCartItem) {
                this.state.otherGameCount = prevCartItem.length;
                return
                this.state.currentAvailableDraws.forEach(_drawEle => {
                    //for get the other game betslip value..                
                    let prevCartItem1 = prevCartItem.filter(a => a.gamegroupID == _drawEle.gamegroupID);
                    if (prevCartItem1 && prevCartItem1.length > 0) {
                        _drawEle.BetSelection = prevCartItem1[0].BetSelection;
                        _drawEle.stakeValue = prevCartItem1[0].stakeValue;
                        this.state.cartItems.push(prevCartItem1[0]);
                    }
                });
            }
        }
    }
    showMsg = (msg, flag) => {
        this.props.alertError(this.props.t(msg), flag);
    }

    createBall = (x) => {
        // alreadt check condition in calling area.
        // if (x.SaleStatus == 1) {
        //     return <li><h6 className={`paddingNone uppercase !text-xl text-game${x.gamegroupID} text-center`}>{this.props.t("lblclosed")}</h6></li>;
        // } 
        let darr = x.no_of_balls.split('~');
        let GameId = x.GameId;//x.GamePadId;
        let sballno = darr[0];
        let eballno = darr[1];
        let ballLst = [];
        for (let i = sballno; eballno >= i; i++) {
            //i = ("0" + i).slice(-2);
            i = "" + i;
            let b = i;
            let b_i = GameId + "_" + b;
            ballLst.push(<li id={`ball_${b_i}`} onClick={() => { this.selectBall(i, x) }}><a className={`${x.BetSelection && x.BetSelection.filter(y => y == i).length > 0 ? `game-${x.gamegroupID}-active` : ''}`}>{i}</a></li>)
        }
        return ballLst;
    }

    selMulti_lucy1 = (x, mval, fromoval) => {
        x.OddsVal = mval;
        if (fromoval == 1) {
            document.getElementById('txtmtpl').value = '';
        }
    }
    selMulti_lucy1_sub = (x) => {
        const mval = locky1Arr[0];
        document.getElementById('txtmtpl').value = '';
        this.selMulti_lucy1(x, mval);
    }
    multiKup = (_txtthis, x) => {
        if (/\D/g.test(_txtthis.currentTarget.value)) {
            _txtthis.currentTarget.value = _txtthis.currentTarget.value.replace(/\D/g, '');
        }
        if (x.maxStake < (_txtthis.currentTarget.value * x.minStake)) {
            _txtthis.currentTarget.value = '';
            this.selMulti_lucy1(x, locky1Arr[0]);
        }
        else {
            this.selMulti_lucy1(x, _txtthis.currentTarget.value);
        }
    }
    multiKyblur = (_txtthis, x) => {
        if (/\D/g.test(_txtthis.currentTarget.value)) {
            _txtthis.currentTarget.value = _txtthis.currentTarget.value.replace(/\D/g, '');
        }
        if (x.maxStake < (_txtthis.currentTarget.value * x.minStake)) {
            _txtthis.currentTarget.value = '';
            this.selMulti_lucy1(x, locky1Arr[0]);
        }
        else {
            this.selMulti_lucy1(x, _txtthis.currentTarget.value);
        }
    }
    createBall_lucy1 = (x) => {
        let darr = x.no_of_balls.split('~');
        let GameId = x.GameId;
        let sballno = parseInt(darr[0]);
        let eballno = parseInt(darr[1]);
        let ballLst = [];
        for (let i = sballno; eballno >= i; i++) {
            let b = "" + i;
            let b_i = GameId + "_" + b;
            ballLst.push(<li id={`ball_${b_i}`} onClick={() => { this.selectBall_lucy1(i, x) }}><a className={`game-${x.gamegroupID}-balls-${i} ${x.BetSelection.filter(y => y == i).length > 0 ? `game-${x.gamegroupID}-active` : ''}`}><span>{i}</span></a></li>)
        }
        return ballLst;
    }
    createBall_Pick3 = (x) => {
        let darr = x.no_of_balls.split('~');
        let GameId = x.GameId;
        let sballno = parseInt(darr[0]);
        let eballno = parseInt(darr[1]);
        let max_ball = parseInt(darr[2]);
        let ballLst = [];
        for (let i = sballno; eballno >= i; i++) {
            for (let j = 0; j < max_ball; j++) {
                let b = "" + i;
                let a = "" + j;
                let b_i = GameId + "_" + b + "_" + a;
                ballLst.push(<li id={`ball_${b_i}`} onClick={() => { this.selectBall_Pick3(i, x, j) }}> <a className={`${x.BetSelection[j] == i ? `game-${x.gamegroupID}-active` : ''}`}><span>{i}</span></a></li>)
            }
        }
        return ballLst;
    }

    selectBall_Pick3 = (b_id, x, ball_inx) => {
        let flst = x.BetSelection[ball_inx];
        let darr = x.no_of_balls.split('~')[2];
        //  && flst.length > 0 && x.BetSelection.length < 3
        if (flst == b_id) {
            // for (let inx = 0; inx < x.BetSelection.length; inx++) {
            //     if (x.BetSelection[ball_inx] == b_id) {
            x.BetSelection[ball_inx] = '?';
            // break;
            // }
            // };
            //x.BetSelection = x.BetSelection.filter(a => (a != b_id));
        }
        else {
            // if (x.BetSelection.filter(a => a != '?').length >= darr) {
            //     this.props.alertError(this.props.t("lblMaxSelect") + darr + '.');
            //     return;
            // }
            // else {
            // for (let inx = 0; inx < x.BetSelection.length; inx++) {
            //     if (x.BetSelection[inx] == "?") {
            //         x.BetSelection[inx] = b_id;
            //         break;
            //     }
            //     else {
            x.BetSelection[ball_inx] = b_id;
            // break;
            // }
            // };
        }
        // }
        if (x.BetSelection.filter(a => a != '?').length == darr) {
            var arr2 = JSON.stringify(x.BetSelection);
            arr2 = JSON.parse(arr2);
            let sbet = [...arr2.sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1)];
            let jnew = sbet.map((e, c) => e).join(';')
            x.BetSelectionSort = jnew;
            x.stakeValue = x.minStake;
        }
        else {
            x.stakeValue = 0;
        }
        // for slow ball selection issues fixed
        let s = this.state.currentAvailableDraws;
        this.setState({ currentAvailableDraws: s });
        //or gtm data layer fires, 1st time selection 
        let fsel = localStorage.getItem("ftimesel")
        if (x.stakeValue > 0 && (!fsel || fsel.indexOf("~" + x.gamegroupID) < 0)) {
            localStorage.setItem("ftimesel", fsel + "~" + x.gamegroupID)
            if (x.gamegroupID == 9) {
                commonClass.SendPageDataLayer(5);
            }
            else if (x.gamegroupID == 11) {
                commonClass.SendPageDataLayer(17);
            }
            else if (x.gamegroupID == 10) {
                commonClass.SendPageDataLayer(6);
            }
            else if (x.gamegroupID == 13) {
                commonClass.SendPageDataLayer(19);
            }
            else if (x.gamegroupID == 15) {
                commonClass.SendPageDataLayer(24);
            }
        }
        if (x.stakeValue > 0) {
            // this.showMsg('lblnumberselsuc'); //Show After Number Select Msg
            let tmr = setInterval(
                () => {
                    this.props.clearAlerts()
                    clearTimeout(tmr);
                }, 2000)
        }
    }


    selectBall_lucy1 = (b_id, x) => {
        let flst = x.BetSelection.filter(a => a == b_id);
        let darr = x.no_of_balls.split('~')[2];
        if (flst.length > 0) {
            for (let inx = 0; inx < x.BetSelection.length; inx++) {
                if (x.BetSelection[inx] == b_id) {
                    if (x.BetSelection.length == 1) {
                        x.BetSelection[inx] = '?';
                    }
                    else {
                        x.BetSelection.splice(inx, 1);
                    }
                    break;
                }
            };
        }
        else {
            if (x.BetSelection.length > 1) {
                x.BetSelection.push(b_id);
            }
            else if (x.BetSelection[0] == "?") {
                x.BetSelection[0] = b_id;
            }
            else {
                x.BetSelection.push(b_id);
            }
        }
        if (x.BetSelection.filter(a => a == '?').length <= 0) {
            var arr2 = JSON.stringify(x.BetSelection);
            arr2 = JSON.parse(arr2);
            let sbet = [...arr2.sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1)];
            let jnew = sbet.map((e, c) => e).join(';')
            x.BetSelectionSort = jnew;
            x.stakeValue = x.minStake;
        }
        else {
            x.stakeValue = 0;
        }
        // for slow ball selection issues fixed
        let s = this.state.cartItems;
        this.setState({ cartItems: s });
        //or gtm data layer fires, 1st time selection 
        let fsel = localStorage.getItem("ftimesel")
        if (x.stakeValue > 0 && (!fsel || fsel.indexOf("~" + x.gamegroupID) < 0)) {
            localStorage.setItem("ftimesel", fsel + "~" + x.gamegroupID)
            if (x.gamegroupID == 9) {
                commonClass.SendPageDataLayer(5);
            }
            else if (x.gamegroupID == 11) {
                commonClass.SendPageDataLayer(17);
            }
            else if (x.gamegroupID == 10) {
                commonClass.SendPageDataLayer(6);
            }
            else if (x.gamegroupID == 13) {
                commonClass.SendPageDataLayer(19);
            }
        }
        if (x.stakeValue > 0) {
            let tmr = setInterval(
                () => {
                    this.props.clearAlerts()
                    clearTimeout(tmr);
                }, 2000)
        }
    }

    selectBall = (b_id, x) => {
        let flst = x.BetSelection.filter(a => a == b_id);
        let darr = x.no_of_balls.split('~')[2];
        if (flst.length > 0) {
            for (let inx = 0; inx < x.BetSelection.length; inx++) {
                if (x.BetSelection[inx] == b_id) {
                    x.BetSelection[inx] = '?';
                    break;
                }
            };
            //x.BetSelection = x.BetSelection.filter(a => (a != b_id));
        }
        else {
            if (x.BetSelection.filter(a => a != '?').length >= darr) {
                this.props.alertError(this.props.t("lblMaxSelect") + darr + '.');
                return;
            }
            else {
                for (let inx = 0; inx < x.BetSelection.length; inx++) {
                    if (x.BetSelection[inx] == "?") {
                        x.BetSelection[inx] = b_id;
                        break;
                    }
                };
            }
        }
        if (x.BetSelection.filter(a => a != '?').length == darr) {
            var arr2 = JSON.stringify(x.BetSelection);
            arr2 = JSON.parse(arr2);
            let sbet = [...arr2.sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1)];
            let jnew = sbet.map((e, c) => e).join(';')
            let prevCartItem = localStorage.getItem('mycart') != '' ? JSON.parse(localStorage.getItem('mycart')) : null;
            if (prevCartItem) {
                let noexits1 = prevCartItem.filter(a => a.BetSelectionSort == jnew && a.gamegroupID == x.gamegroupID);
                if (noexits1.length <= 0) {
                    x.BetSelectionSort = jnew;
                    x.stakeValue = x.minStake;
                }
                else {
                    for (let inx = 0; inx < x.BetSelection.length; inx++) {
                        if (x.BetSelection[inx] == b_id) {
                            x.BetSelection[inx] = '?';
                            break;
                        }
                    };
                    this.showMsg(this.props.t('lblDuplicateNoSel'))
                    return;
                }
            }
            else {
                x.BetSelectionSort = jnew;
                x.stakeValue = x.minStake;
            }
        }
        else {
            x.stakeValue = 0;
        }
        // for slow ball selection issues fixed
        let s = this.state.currentAvailableDraws;
        this.setState({ currentAvailableDraws: s });
        //or gtm data layer fires, 1st time selection 
        let fsel = localStorage.getItem("ftimesel")
        if (x.stakeValue > 0 && (!fsel || fsel.indexOf("~" + x.gamegroupID) < 0)) {
            localStorage.setItem("ftimesel", fsel + "~" + x.gamegroupID)
            if (x.gamegroupID == 9) {
                commonClass.SendPageDataLayer(5);
            }
            else if (x.gamegroupID == 11) {
                commonClass.SendPageDataLayer(17);
            }
            else if (x.gamegroupID == 10) {
                commonClass.SendPageDataLayer(6);
            }
            else if (x.gamegroupID == 13) {
                commonClass.SendPageDataLayer(19);
            }
            else if (x.gamegroupID == 14) {
                commonClass.SendPageDataLayer(22);
            }
        }
        if (x.stakeValue > 0) {
            // this.showMsg('lblnumberselsuc'); //Show After Number Select Msg
            let tmr = setInterval(
                () => {
                    this.props.clearAlerts()
                    clearTimeout(tmr);
                }, 2000)
        }
    }

    reCount = (rundraw) => {
        if (rundraw == null || Object.keys(rundraw).length == 0) {
            return <></>;
        }
        else {
            if (this.props.serverTime > 0) {
                this.state.coundownStart = 1;
                return <><Countdown now={() => { return (this.props.serverTime); }} date={this.getGameTime(rundraw)} onComplete={this.countdownCompleted} renderer={this.renderer} /> </>;
            }
            else {
                return <>
                    <><div className="timeCont"><div id="hours1">00</div><p>Hours</p></div><span>:</span></>
                    <><div className="timeCont"><div id="minutes1">00</div><p>Minutes</p></div><span>:</span></>
                    <><div className="timeCont"><div id="seconds1">00</div><p>Seconds</p></div></>
                </>
            }
        }
    };

    getGameTime = (rundraw) => {
        lastCountDownTime = Date.now();
        let _date = rundraw ? rundraw.DrawDateTime : "";
        if (_date != undefined && _date != "") {
            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
            //console.log("getGameTime : " + sTime);
            //console.log("DateNow : " + Date.now());

            // if (rundraw.SaleStatus == 0) { //0-open,1-closed  ,Sale closeTime implemented
            let SaleCloseTime = sTime - rundraw.SaleCloseTimeMSec;
            let SaleStatusRemaining = SaleCloseTime - this.props.serverTime;
            let SalesClo = (SaleStatusRemaining > 0 ? 0 : 1);
            if (SalesClo != rundraw.SaleStatus) {
                rundraw.SaleStatus = SalesClo;
                this.state.currentDraw.SaleStatus = SalesClo;
                //Lucky1 Game Current Game Close After Second Game Load 
                if (rundraw.gamegroupID == 13 || rundraw.gamegroupID == 15) {
                    setTimeout(() => {
                        this.reloadNextDraw()
                    }, 1500);
                }
            }
            // }

            // if (this.state.showSaleCloseInfo == false) {
            //     let SaleCloseTime1 = sTime - this.props.serverTime;
            //     this.state.showSaleCloseInfo = SaleCloseTime1 < (24 * (60 * (60 * 1000))) ? true : false;
            // }
            let daysmillis = 86400000; //One day (24 hours)
            if (rundraw.gamegroupID == 9 || (rundraw.gamegroupID == 9 && this.state.FortuneGameId != rundraw.GameId)) {
                if (this.state.showSaleCloseInfo == false) {
                    let SaleCloseTime1 = sTime - this.props.serverTime;
                    this.state.showSaleCloseInfo = SaleCloseTime1 < daysmillis ? true : false;
                }
                this.state.FortuneGameId = rundraw.GameId;
            }
            else if (rundraw.gamegroupID == 11 || (rundraw.gamegroupID == 11 && this.state.MagicGameId != rundraw.GameId)) {
                if (this.state.SaleCloseInfoMagic == false) {
                    let SaleCloseTime1 = sTime - this.props.serverTime;
                    this.state.SaleCloseInfoMagic = SaleCloseTime1 < daysmillis ? true : false;
                }
                this.state.MagicGameId = rundraw.GameId;
            }
            else if (rundraw.gamegroupID == 13 || (rundraw.gamegroupID == 13 && this.state.LockyGameId != rundraw.GameId)) {
                if (this.state.SaleCloseInfoLucky == false) {
                    let SaleCloseTime1 = sTime - this.props.serverTime;
                    this.state.SaleCloseInfoLucky = SaleCloseTime1 < daysmillis ? true : false;
                }
                this.state.LockyGameId = rundraw.GameId;
            }
            else if (rundraw.gamegroupID == 14 || (rundraw.gamegroupID == 14 && this.state.DreamGameId != rundraw.GameId)) {
                if (this.state.SaleCloseInfoDream == false) {
                    let SaleCloseTime1 = sTime - this.props.serverTime;
                    this.state.SaleCloseInfoDream = SaleCloseTime1 < daysmillis ? true : false;
                }
                this.state.DreamGameId = rundraw.GameId;
            }
            else if (rundraw.gamegroupID == 10 || (rundraw.gamegroupID == 10 && this.state.SuperGameId != rundraw.GameId)) {
                if (this.state.showSaleCloseSuper == false) {
                    let SaleCloseTime1 = sTime - this.props.serverTime;
                    this.state.showSaleCloseSuper = SaleCloseTime1 < daysmillis ? true : false;
                }
                this.state.SuperGameId = rundraw.GameId;
            }
            else if (rundraw.gamegroupID == 15 || (rundraw.gamegroupID == 15 && this.state.Pick3GameId != rundraw.GameId)) {
                if (this.state.showSaleClosePick3 == false) {
                    let SaleCloseTime1 = sTime - this.props.serverTime;
                    this.state.showSaleClosePick3 = SaleCloseTime1 < daysmillis ? true : false;
                }
                this.state.Pick3GameId = rundraw.GameId;
            }

            return sTime;
        }
        else {
            return 0;
        }
    };
    reloadNextDraw = () => {
        this.state.myUCDraw = [];
        setTimeout(() => {
            let mythis = this;
            mythis.props.GameAvailableGames(mythis.props.user.mobileNo, 0, mythis.props.i18n.language, mythis.props.serverTime, mythis.props.user.loginKey);
            // Lucky1 GamePad Close Next Draw GamePad Open. 
            setTimeout(() => {
                let latestgame = this.state.currentAvailableDraws.filter(a => a.gamegroupID == this.state.currentDraw.gamegroupID && a.SaleStatus == 0);
                if (latestgame && latestgame.length > 0) {
                    this.setCurrentDraw(latestgame[0])
                }
            }, 1000);
        }, (500));
    }
    renderer = ({ total, days, hours, minutes, seconds, completed }) => {
        let retval
        if (completed) {
            retval = <div className='aftercomplete' >{this.props.t('lblDrawClosed')}</div>;
        } else {
            if (days > 0) {
                retval = <>
                    <><div className="timeCont"><div id="days1">{zeroPad(days)}</div><p>Days</p></div><span>:</span></>
                    <><div className="timeCont"><div id="hours1">{zeroPad(hours)}</div><p>Hours</p></div><span>:</span></>
                    <><div className="timeCont"><div id="minutes1">{zeroPad(minutes)}</div><p>Min</p></div><span>:</span></>
                    <><div className="timeCont"><div id="seconds1">{zeroPad(seconds)}</div><p>Sec</p></div></>
                </>;
            }
            else {
                retval = <>
                    <><div className="timeCont"><div id="hours1">{zeroPad(hours)}</div><p>Hours</p></div><span>:</span></>
                    <><div className="timeCont"><div id="minutes1">{zeroPad(minutes)}</div><p>Min</p></div><span>:</span></>
                    <><div className="timeCont"><div id="seconds1">{zeroPad(seconds)}</div><p>Sec</p></div></>
                </>;
            }
        }
        return retval;
    };
    countdownCompleted = () => {
        //this.RefreshAfterGameClose();        
        this.state.isDrawCompleted = true;
    };
    clearCart = (x) => {
        x.BetSelection.forEach((_bx, inx) => {
            x.BetSelection[inx] = '?';
        });
        this.state.random = [];
    };

    BuyLotteryBet = (x) => {
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            this.showMsg('lblPlsLoginOrSignUp');
            return;
        }
        if (x.BetSelection.length != x.no_of_balls.split('~')[2]) {
            this.showMsg('lblplcurbet');
            return;
        }
        let totStake = x.stakeValue;
        var sTime = new Date(this.props.serverTime);
        let cartList = [(x.BetSelection.map(i => ("0" + i).slice(-2))).join(';')];
        let gamestring = "";
        let inx = 1;
        cartList.forEach(item => {
            gamestring += inx;
            gamestring += "~" + item;
            gamestring += "~" + 0;//oddsValue;
            gamestring += "~" + x.minStake;//stakeValue;
            gamestring += "~" + x.minStake + "|";//total
        })
        let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 4);
        let ObjBuy = {
            "reflot": x.GameId,
            "group_id": parseInt(x.gamegroupID),
            "draw_time": x.DrawTime,
            "bet_info": gamestring,
            "client_transid": TransID,
            "amount": totStake,
            "type_id": 0
        }
        this.props.Buybet(ObjBuy, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        this.clearCart(x);
    };

    AddToCart = (_draw) => {
        let bervalidate = true;
        // if (this.props.user == undefined || this.props.user.mobileNo == "0") {
        //     this.showMsg('lblPlsLoginOrSignUp', 1);
        //     bervalidate = false;
        //     return;
        // }
        // else 
        if (_draw.BetSelection.filter(a => a == '?').length > 0 || (_draw.OddsVal <= 0 && _draw.gamegroupID == 13)) {
            bervalidate = false;
            this.showMsg('lblplcurbet');
            return;
        }
        if (bervalidate == false) { return }
        //if (this.state.cartItems && this.state.cartItems.length > 0) {
        let lst = this.state.cartItems.filter(a => a.gamegroupID == _draw.gamegroupID);
        if (lst && lst.length > 0 && _draw.gamegroupID != 13) { // Addtocart  from Zodiac Game Click Again Issue. _draw.gamegroupID != 13
            lst[0].BetSelection = _draw.BetSelection;
            this.props.UpdateCartAll(lst[0]);
        }
        //}
        else {
            if (this.state.otherGameCount >= 50) {
                this.showMsg('lblMaxLen')
                return;
            }
            let _mybetslip = null;
            if (_draw.gamegroupID != 13) {
                _mybetslip = _draw.autoApply
            }
            else {
                _mybetslip = _draw.BetSelection.length
            }
            for (let i = 0; i < _mybetslip; i++) {
                // let _maxid = this.state.cartItems.length > 0 ? Math.max(...this.state.cartItems.map(o => o.GamePadId)) : 0;
                // _maxid++;
                const min = 0;
                const max = 99999;
                let _maxid = min + (Math.random() * (max - min));
                let _mybetslipArr = _draw.gamegroupID != 13 ? _draw.BetSelection : [_draw.BetSelection[i]];
                let _mybetslipArrSort = _draw.gamegroupID != 13 ? _draw.BetSelectionSort : "" + _draw.BetSelection[i];
                let mybet = {
                    GamePadId: _maxid, BetSelection: _mybetslipArr, stakeValue: _draw.stakeValue, GameId: _draw.GameId,
                    RateInPs: _draw.RateInPs, gamegroupID: _draw.gamegroupID, StakeMulti: _draw.StakeMulti,
                    total: (_draw.minStake * _draw.RateInPs), no_of_balls: _draw.no_of_balls, DrawDate: _draw.DrawDate,
                    LotName: _draw.LotName, DrawDateTime: _draw.DrawDateTime, autoApply: _draw.autoApply,
                    DrawTime: _draw.DrawTime, minStake: _draw.minStake, maxStake: _draw.maxStake, DrawShowTime: _draw.DrawDate + " " + _draw.DrawShowTime,
                    SaleCloseTimeMSec: _draw.SaleCloseTimeMSec, BetSelectionSort: _mybetslipArrSort, OddsVal: _draw.OddsVal
                };
                this.state.cartItems.push(mybet);
                if (this.state.cartItems[i].gamegroupID == 13) {
                    this.state.cartItems[i].stakeValue = this.state.cartItems[i].minStake * this.state.cartItems[i].OddsVal;
                    //multi draw implementation
                    if (this.state.cartItems[i].gamegroupID == 13 && this.state.myUCDraw && this.state.myUCDraw.length == 1) {
                        let fucd = this.state.myUCDraw.filter(a => a != this.state.cartItems[i].GameId);
                        if (fucd && fucd.length > 0) {
                            let _draw = this.state.currentAvailableDraws.filter(a => a.GameId == fucd[0]);
                            if (_draw && _draw.length > 0) {
                                _draw = _draw[0];
                                this.state.cartItems[i].GameId = _draw.GameId;
                                this.state.cartItems[i].DrawDateTime = _draw.DrawDateTime;
                                this.state.cartItems[i].DrawTime = _draw.DrawTime;
                                this.state.cartItems[i].DrawShowTime = _draw.DrawDate + " " + _draw.DrawShowTime;
                                this.state.cartItems[i].SaleCloseTimeMSec = _draw.SaleCloseTimeMSec;
                            }
                        }

                    }
                }
                this.props.AddToCart(mybet);
                //multi draw implementaion
                if (this.state.cartItems[i].gamegroupID == 13 && this.state.myUCDraw && this.state.myUCDraw.length > 1) {
                    let fucd = this.state.myUCDraw.filter(a => a != this.state.cartItems[i].GameId);
                    if (fucd && fucd.length > 0) {
                        let _draw = this.state.currentAvailableDraws.filter(a => a.GameId == fucd[0]);
                        if (_draw && _draw.length > 0) {
                            let cartItemsM = { ...this.state.cartItems[i] }
                            _draw = _draw[0];
                            let _maxid = 0 + (Math.random() * (99999 - 0));
                            cartItemsM.GamePadId = _maxid;
                            cartItemsM.GameId = _draw.GameId;
                            cartItemsM.DrawDateTime = _draw.DrawDateTime;
                            cartItemsM.DrawTime = _draw.DrawTime;
                            cartItemsM.DrawShowTime = _draw.DrawDate + " " + _draw.DrawShowTime;
                            cartItemsM.SaleCloseTimeMSec = _draw.SaleCloseTimeMSec;
                            this.props.AddToCart(cartItemsM);
                        }
                    }
                }

            }
            let cobj = {};
            commonClass.SendEventTracking(9, cobj, false, this.state.cartItems);
        }
        //for multi buy
        this.gotoMyCart();
    }
    gotoMyCart = () => {
        let bervalidate = true;
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            let mobno = 0;
            localStorage.setItem('lastloginuser', mobno);
            setTimeout(() => {
                this.showMsg('lblPlsLoginOrSignUp', 1);
            }, 500);
            bervalidate = false;
            return;
        }
        //if (this.state.cartItems.length == 0 && this.state.otherGameCount == 0) {
        if (this.state.cartItems == undefined && this.state.cartItems == null) {
            bervalidate = false;
            this.showMsg('lblplcurbet');
            return;
        }
        for (let i = 0; i < this.state.cartItems.length; i++) {
            let x = this.state.cartItems[i];
            if (x.BetSelection.filter(a => a != '?').length != x.no_of_balls.split('~')[2]) {
                this.showMsg('lblplcurbet');
                bervalidate = false;
                break;
            }
        }
        if (bervalidate) {
            this.showMsg('lblAddCartsucss');
            setTimeout(() => {
                history.push('/mycart');
            }, 1000);
        }
    }

    quickPick = (x) => {
        let darr = x.no_of_balls.split('~');
        let sballno = darr[0];
        let eballno = darr[1];
        let maxballno = darr[2];
        this.state.random = [...x.BetSelection.filter(a => a != '?')];
        x.BetSelection.forEach((_bx, inx) => {
            x.BetSelection[inx] = '?';
        });
        this.state.random = [];
        let QPBallCount = maxballno - this.state.random.length;
        if (QPBallCount <= 0) {
            QPBallCount = maxballno;
        }
        for (var k = sballno; k <= QPBallCount; k++) {
            let _bal = this.generateRandomInteger(x);
            this.selectBall(_bal, x);
        }
    }

    generateRandomInteger = (x) => {
        let darr = x.no_of_balls.split('~');
        let sballno = darr[0];
        let eballno = darr[1];
        let maxballno = darr[2];
        var min = sballno;
        var max = parseInt(eballno) + 1;
        if (this.state.random.length >= maxballno) {
            this.state.random = [];
        }
        let valFound = false;
        while (valFound == false) {
            for (var i = min; i <= max; i++) {
                var temp = Math.floor(max - Math.random() * (max - min));
                temp = "" + temp;
                //temp = ("0" + temp).slice(-2);
                if (temp && this.state.random.indexOf(temp) == -1) {
                    this.state.random.push(temp);
                    valFound = true;
                    return temp;
                }
            }
        }
    };

    applyAutoBuy = (flog, x) => {
        if (flog == "+") {
            if (x.autoApply + 1 > this.state.autoApplyCountmax) {
                return;
            }
            else {
                x.autoApply++;
            }
        }
        else if (flog == "-") {
            if (x.autoApply - 1 < 1) {
                return
            }
            else {
                x.autoApply--;
            }
        }
    };

    setIsShown = (x, flog) => {
        if (x.BetSelection && x.BetSelection.filter(a => a != '?').length == x.no_of_balls.split('~')[2]) {
            x.betComplete = flog;
        }
        else {
            x.betComplete = false;
        }
    }
    saveMyFavNo = (x) => {
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            this.showMsg('lblplsloginTry', 1);
            return;
        }
        if (x.BetSelection.filter(a => a != '?').length != x.no_of_balls.split('~')[2]) {
            this.showMsg('lblplcurbet');
            return;
        }
        // Showing 0 Issue For Favourite Tab.
        // let no = (x.BetSelection.map(i => ("0" + i).slice(-2))).join(';')
        let no = (x.BetSelection.map(i => ("" + i))).join(';')
        this.props.SaveMyFavNumbers(this.state.myfavNoID, x.gamegroupID, no, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey)
        let uObj = {};
        let SavemyNo = [x]
        commonClass.SendEventTracking(12, uObj, false, SavemyNo);

    }
    AddTicket = (obj) => {
        let no = (obj.BetSelection.join(';'))
        //localStorage.setItem('setfavNo', no);
        localStorage.setItem('setAddPad', no);
        if (obj.gamegroupID == 9) {
            history.push('/fortune6');
        }
        else if (obj.gamegroupID == 11) {
            history.push('/magic5');
        }
        else if (obj.gamegroupID == 13) {
            history.push('/lucky1');
        }
        else if (obj.gamegroupID == 14) {
            history.push('/dream5');
        }
        else if (obj.gamegroupID == 15) {
            history.push('/rush3');
        }
        else {
            history.push('/super6');
        }
    }
    Formbannerimage = (data) => {
        try {
            const mythis = this;
            let HomebannerJsonObj = [];
            data.data.forEach(obj => {
                if (obj) {
                    let startdate = obj.startDate;
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    let strmilliseconds = (new Date(startdate.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
                    if (mythis.props.serverTime >= strmilliseconds) {
                        if (obj.expiryDate != "" && obj.expiryDate != "null") {
                            let enddate = obj.expiryDate;
                            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                            let endmilliseconds = (new Date(enddate.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
                            if (mythis.props.serverTime < endmilliseconds) {
                                obj.imagPath = data.baseurl ? data.baseurl + obj.imagPath : obj.imagPath;
                                HomebannerJsonObj.push(obj);
                            }
                        }
                        else {
                            obj.imagPath = data.baseurl ? data.baseurl + obj.imagPath : obj.imagPath;
                            HomebannerJsonObj.push(obj);
                        };
                    }
                }
            });
            return HomebannerJsonObj;
        }
        catch (ex) {
            console.log("Formbannerimage => ", ex)
        }
    }
    bnrClck = (e) => {
        try { 
            if (e.target.tagName === 'IMG') {
                const _ink = e.target.getAttribute('data-lnk');
                if (_ink && _ink.length > 1) {
                    let home_ban_link = e.target.getAttribute('data-lnk')
                    if (home_ban_link.indexOf("http") >= 0) {
                        window.open(home_ban_link,'_blank'); 
                    } else {
                        history.push("/" + e.target.getAttribute('data-lnk'))
                    }
                }
            }
        } catch (ex) {
            console.log("bnrClck ==>", ex)
        }
    }
    SetgetJsonbanner = (HomebannerJson) => {
        try {
            let numAscending = HomebannerJson.sort(function (a, b) {
                return a.position - b.position || b.modifyOn - a.modifyOn;
            });
            //     this.setState({ bannerList: numAscending });
            //   setTimeout(() => {
            //     window.documentReadyjQueryFlex();
            //     }, 600); 

            let dd = "";
            numAscending.forEach(x => {
                dd += `<li class="bannerdisplay"><img src='${x.imagPath + '?' + x.version}' data-lnk='${x.linkUrl}' alt='Slider Image'/></li>`;
            });
            document.getElementById('Homeslide').innerHTML = "<div class='banner'> <ul class='slides' id='HomeslideChild' >" + dd + "</ul></div>";
            document.getElementById('HomeslideChild').addEventListener("click", this.bnrClck);
            window.documentReadyjQueryFlex();

            // let gg = setInterval(() => {
            //     let c_cnt = document.getElementById('HomeslideChild').childElementCount;
            //     if (c_cnt > 0 && this.state.bcn != c_cnt) {
            //         this.state.bcn = c_cnt;
            //         clearInterval(gg);
            //         window.documentReadyjQueryFlex();
            //     }
            // }, 500);

        }
        catch (ex) {
            console.log("SetgetJsonbanner => ", ex)
        }
    }

    setjsondata = (isdelay = 0) => {
        try {
            let hbanner = 0;
            let hbanner_old = localStorage.getItem('whbver');
            if (this.props.appvariables.bVersion) {
                let hbannerVer = this.props.appvariables.bVersion.split("~")
                if (hbannerVer.length > 0) {
                    hbanner = hbannerVer[0];
                    // localStorage.setItem('whbver', hbanner); Somes Time Could Not Loaded Implement In Inside The After Fetch FTP
                }
            }
            let temp_hbanner = false;
            let _test = JSON.parse(localStorage.getItem('whblst'));
            if (_test && !_test.data) {
                localStorage.removeItem('whblst');
                temp_hbanner = true;
            }
            let HomebannerJson = [];
            if (hbanner_old != hbanner || temp_hbanner) { // hbanner_old != hbannertrue
                var s = process.env.REACT_APP_CDN_URL + 'Web/WEBHome.json' + "?V" + hbanner;
                // , { catch implement for banner Not loading
                //     cache: "reload",	
                //     headers: {
                //         'cache-control':'no-cache'
                //     }
                // }
                fetch(s).then(response => response.json()).then(data => {
                    //  HomebannerJson = this.Formbannerimage(data.data, data.baseurl);          //form banner image
                    // localStorage.setItem('whblst', JSON.stringify(data.data)); // set ftb all Array
                    localStorage.setItem('whblst', JSON.stringify(data)); // set ftb all Array 
                    let dg = setInterval(() => {
                        if (this.props.serverTime > 0) {
                            clearInterval(dg);
                            HomebannerJson = this.Formbannerimage(data);          //form banner image
                            this.SetgetJsonbanner(HomebannerJson);                     //set banner image for state
                            localStorage.setItem('whbver', hbanner);
                        }
                    }, 100);
                })
            } else {
                if (isdelay == 0) {
                    let BannerimgLcl = JSON.parse(localStorage.getItem('whblst')); // get ftb all Array
                    let dg1 = setInterval(() => {
                        if (this.props.serverTime > 0) {
                            clearInterval(dg1);
                            HomebannerJson = this.Formbannerimage(BannerimgLcl);           //form banner image
                            this.SetgetJsonbanner(HomebannerJson);                         //set banner image for state
                        }
                    })
                }
            }
        }
        catch (ex) {
            console.log("setjsondata => ", ex)
        }
    };


    SelectUpcomeDraw = (x) => {
        console.log(x)
        if (this.state.myUCDraw) {
            const fobj = this.state.myUCDraw.filter(a => a == x.GameId);
            if (fobj.length > 0) {
                if (this.state.myUCDraw.length > 1)
                    this.state.myUCDraw = this.state.myUCDraw.filter(a => a != x.GameId);
            }
            else {
                let tlst = this.state.myUCDraw
                tlst.push(x.GameId);
                // this.setState({ myUCDraw: tlst })
                this.state.myUCDraw = tlst;
            }
        }
        else {
            this.state.myUCDraw = [x.GameId]
        }
    };

    setCurrentDraw = (x, idclk) => {
        //to prevent the fire click event while scroll move.this value set in jquery file.
        if (window.clckeveadd && window.clckeveadd == 1) {
            window.clckeveadd = 0;
            return;
        }
        // if (idclk) {
        //     switch (idclk) {
        //         case 9:
        //             history.push('/fortune4');
        //             break;
        //         case 10:
        //             history.push('/super6');
        //             break;
        //         case 11:
        //             history.push('/magic5');
        //             break;
        //         case 13:
        //             history.push('/lucky1');
        //             break;
        //         case 14:
        //             history.push('/dream5');
        //             break;
        //         default:
        //             break;
        //     }
        //     return;
        // }
        if (x.gamegroupID == 13 || x.gamegroupID == 15) {
            this.state.myUCDraw = [];
        }
        this.state.currentDraw = x;
        this.setState({
            currentDraw: x
        });
        this.state.cartItems = [];
        this.createGamepad(this.state.padCount);
        this.LoadReBetNumber();
        this.loadPrevCartItem();
        this.loadSelectFavNo();
        if (this.props.user.mobileNo != 0) {
            this.props.GetMyFavNumbers(this.state.currentDraw.gamegroupID, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        }
        if (this.state.currentDraw) {
            commonClass.SendEventTracking(11, {}, false, [this.state.currentDraw]);
        }
    }
    createGamepad = (addpadcount) => {
        if (this.state.currentDraw && Object.keys(this.state.currentDraw).length > 1) {
            if (addpadcount > 0) {
                let _draw = this.state.currentDraw;
                //let _maxid = this.state.cartItems.length > 0 ? Math.max(...this.state.cartItems.map(o => o.GamePadId)) : 0;

                for (let i = 1; i <= addpadcount; i++) {
                    let tarr = []
                    for (let i = 1; i <= _draw.no_of_balls.split('~')[2]; i++) {
                        tarr.push('?');
                    }
                    //_maxid++;
                    const min = 0;
                    const max = 99999;
                    let _maxid = min + (Math.random() * (max - min));
                    let mybet = {
                        GamePadId: _maxid, BetSelection: tarr, stakeValue: 0, GameId: _draw.GameId,
                        RateInPs: _draw.RateInPs, gamegroupID: _draw.gamegroupID, StakeMulti: _draw.StakeMulti,
                        total: (_draw.minStake * _draw.RateInPs), no_of_balls: _draw.no_of_balls,
                        LotName: _draw.LotName, DrawDateTime: _draw.DrawDateTime, DrawTime: _draw.DrawTime,
                        minStake: _draw.minStake, maxStake: _draw.maxStake, betComplete: false, DrawShowTime: _draw.DrawDate + " " + _draw.DrawShowTime,
                        SaleCloseTimeMSec: _draw.SaleCloseTimeMSec, OddsVal: (_draw.gamegroupID == 13 || _draw.gamegroupID == 15 ? 1 : 0),
                        BetType: (_draw.gamegroupID == 15 ? _draw.BetType : "")
                    };
                    this.state.cartItems.push(mybet)
                }
            }
            else {
                let dd = this.state.cartItems.pop();
                for (let i = 1; i < Math.abs(addpadcount); i++) {
                    this.state.cartItems.pop();
                }
                this.state.cartItems[this.state.cartItems.length - 1] = dd;
            }
        }
    }
    LoadReBetNumber = () => {
        if (this.state.currentDraw && Object.keys(this.state.currentDraw).length > 1) {
            let _no = localStorage.getItem('rebetno');
            if (_no) {
                if (_no.split("~")[0] != this.state.currentDraw.gamegroupID) {
                    localStorage.removeItem('rebetno')
                    return;
                }
                let _noArr = _no.split("~")[1]
                _noArr = _noArr.split("|")
                // let _noArr = _no.split("|")
                let len = _noArr.length - (this.state.cartItems.length - 1);
                for (let i = 0; i < len; i++) {
                    this.createGamepad(1);
                }
                len = this.state.cartItems.length - 1;
                for (let i = 0; i < len; i++) {
                    this.state.cartItems[i].BetSelection = _noArr[i].split(';').map(Obj => parseInt(Obj) + "");
                    this.state.cartItems[i].stakeValue = this.state.currentDraw.minStake;
                    this.state.cartItems[i].betComplete = true;
                    // Multiple Rebet issue 
                    var arr2 = JSON.stringify(this.state.cartItems[i].BetSelection);
                    arr2 = JSON.parse(arr2);
                    let sbet = [...arr2.sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1)];
                    let jnew = sbet.map((e, c) => e).join(';')
                    this.state.cartItems[i].BetSelectionSort = jnew;
                }
                localStorage.removeItem('rebetno')
            }
        }
    }
    loadSelectFavNo = () => {
        let gameNo = localStorage.getItem('setfavNo');
        gameNo = gameNo && gameNo.split('~')
        if (gameNo && gameNo[1] && this.state.cartItems && this.state.cartItems.length > 0 && gameNo[0] == this.state.currentDraw.gamegroupID) {
            this.state.cartItems[0].BetSelection = gameNo[1].split(';').map(Obj => parseInt(Obj) + "");
            this.state.cartItems[0].stakeValue = this.state.currentDraw.minStake;
            this.state.cartItems[0].betComplete = true;
            localStorage.removeItem('setfavNo')
        }
        if (gameNo && gameNo[0] && gameNo[0] != this.state.currentDraw.gamegroupID) {
            localStorage.removeItem('setfavNo')
        }
        //add more pad
        let gameNo1 = localStorage.getItem('setAddPad');
        if (gameNo1 && this.state.cartItems && this.state.cartItems.length > 0) {
            localStorage.removeItem('setAddPad')
            this.state.cartItems[0].BetSelection = gameNo1.split(';');
            this.state.cartItems[0].stakeValue = this.state.currentDraw.minStake;
            if (this.state.cartItems[0].BetSelection && this.state.cartItems[0].BetSelection.filter(a => a != '?').length == this.state.cartItems[0].no_of_balls.split('~')[2]) {
                this.state.cartItems[0].betComplete = true;
            }
            else {
                this.state.cartItems[0].betComplete = false;
            }
            let _mycat = this.state.cartItems;
            let d = { ...this.state.cartItems[this.state.cartItems.length - 1] };
            let d1 = _mycat.pop();
            let betball = [];
            for (let i = 1; i <= d.BetSelection.length; i++) {
                betball.push('?');
            }
            d.BetSelection = betball;
            _mycat.push(d);
            let _maxid = this.state.cartItems.length > 0 ? Math.max(...this.state.cartItems.map(o => o.GamePadId)) : 0;
            d1.GamePadId = _maxid + 1;
            _mycat.push(d1);
            this.setState({ cartItems: _mycat });
        }

    }
    setIsShown = (x, flog) => {
        if (x.BetSelection && x.BetSelection.filter(a => a != '?').length == x.no_of_balls.split('~')[2]) {
            x.betComplete = flog;
        }
        else {
            x.betComplete = false;
        }
    }
    setMyFavNo = (e, x) => {
        if (e.target.value == "-1") {
            this.clearCart(x)
            return;
        }
        let ss = this.state.MyFavNos[e.target.value];
        let no = ss.numbers.split(';');
        x.BetSelection = no.filter(a => a != '?').map((_no, inx) => "" + parseInt(_no));
        let darr = x.no_of_balls.split('~')[2];
        if (x.BetSelection.filter(a => a != '?').length == darr) {
            x.stakeValue = x.minStake;
        }
        else {
            x.stakeValue = 0;
        }
        this.setState({ myfavNoID: ss.favorite_id })
    }
    ballPadGenerate = (flog, cnt) => {
        if (this.state.currentDraw && this.state.currentDraw.SaleStatus != 0) {
            return;
        }
        if (flog == "+") {
            cnt = (cnt ? cnt : 1);
            let MyCartTicket = JSON.parse(localStorage.getItem('mycart'));
            let MyCartCount = MyCartTicket == null ? 0 : MyCartTicket.filter(Obj => Obj.gamegroupID == this.state.currentDraw.gamegroupID).length
            let MaxTicketCount = this.state.padCountmax - MyCartCount
            if (this.state.cartItems.length + 1 > MaxTicketCount) {
                this.props.alertError(this.props.t('lblMaximumstake'));
            }
            else { this.createGamepad(cnt) }
        }
        else if (flog == "-") {
            cnt = (cnt ? cnt : -1)
            if (this.state.cartItems.length - 1 < this.state.padCount) {
                //this.props.alertError(this.props.t('lblMinimumstake') + " : " + padCountmax);
                return
            }
            else { this.createGamepad(cnt) }
        }
    };
    removeGamePad = (removeinx) => {
        if (this.state.cartItems.length == 2) {
            return;
        }
        else {
            let updateCart = this.state.cartItems.filter((data, inx) => {
                return inx != removeinx;
            });
            this.setState({ cartItems: updateCart });
        }
    }
    addCustomizePad_b = (_txtthis) => {
        this.setState({ txtShow: false });
    }
    addCustomizePad_k = (_txtthis) => {
        if (/\D/g.test(_txtthis.currentTarget.value)) {
            _txtthis.currentTarget.value = _txtthis.currentTarget.value.replace(/\D/g, '');
        }
        if (this.state.padCountmax <= _txtthis.currentTarget.value) {
            _txtthis.currentTarget.value = this.state.cartItems.length - 1;
            this.state.txtPadCount = _txtthis.currentTarget.value;
        }
        else {
            this.state.txtPadCount = _txtthis.currentTarget.value;
        }
        if (0 < this.state.txtPadCount) {
            //this.state.txtPadCount = this.state.padCount;
            this.ballPadGenerate_temp();
        }
    }
    ballPadGenerate_temp = () => {
        if (this.state.txtPadCount > 0) {
            let dd = this.state.txtPadCount - (this.state.cartItems.length - 1);
            this.setState({ txtPadCount: 0 });
            if (dd > 0) {
                this.ballPadGenerate("+", dd);
            }
            else if (dd < 0) {
                this.ballPadGenerate("-", dd);
            }
        }
    }
    addMyCart = () => {
        if (this.state.currentDraw && this.state.currentDraw.SaleStatus == 1) {
            this.showMsg('lblclosed');
            return;
        }
        let bervalidate = true;
        // if (this.props.user == undefined || this.props.user.mobileNo == "0") {
        //     this.showMsg('lblPlsLoginOrSignUp', 1);
        //     bervalidate = false;
        //     return;
        // }
        //due to user can view empty cart.
        // if (this.state.cartItems.length == 0 && this.state.otherGameCount == 0) {
        //     bervalidate = false;
        //     this.showMsg(this.props.t('lblplcurbet'));
        //     return;
        // }
        for (let i = 0; i < this.state.cartItems.length; i++) {
            let x = this.state.cartItems[i];
            if (i == this.state.cartItems.length - 1) { // to skip last one
                continue;
            }
            let ecnt = x.BetSelection.filter(a => a != '?').length;
            if (ecnt > 0 && ecnt != x.no_of_balls.split('~')[2] && x.gamegroupID != 13) {
                this.showMsg('lblplcurbet');
                bervalidate = false;
            }
            // Pick3 Game Check
            else if ((x.gamegroupID == 15 && x.Pick3BetTypeid != undefined && ecnt == 0) || (x.gamegroupID == 15 && x.Pick3BetTypeid == undefined && ecnt > 0)) {
                this.showMsg('lblplcurbet');
                bervalidate = false;
            }
            // else if (x.gamegroupID == 15 && x.Pick3BetTypeid != undefined && ecnt == 0) {
            //     this.showMsg('lblplcurbet');
            //     bervalidate = false;
            // }
        }
        if (bervalidate) {
            if (this.state.otherGameCount < this.state.CartmaxLength) {
                let ccn = this.state.cartItems.filter(x => x.BetSelection.filter(a => a != '?').length > 0)
                if (ccn == 0) {
                    {
                        this.showMsg('lblplcurbet');
                        return;
                    }
                }
                let mycart = null;
                for (let i = 0; i < this.state.cartItems.length; i++) {
                    if (i == this.state.cartItems.length - 1) { // to skip last one
                        continue;
                    }
                    else if (this.state.cartItems[i].BetSelection.filter(a => a == '?').length > 0) {  // to skip uncompleted bet
                        continue;
                    }
                    if (this.state.cartItems[i].OddsVal <= 0 && this.state.cartItems[i].gamegroupID == 13) {
                        continue;
                    }
                    let oitem = this.state.OldcartItems.filter(a => a.GamePadId == this.state.cartItems[i].GamePadId); if (oitem && oitem.length > 0) {
                        this.props.UpdateCartAll(this.state.cartItems[i]);
                        //this.props.AddToCart(this.state.cartItems[i]);
                    }
                    else {
                        if (mycart == null) {
                            mycart = JSON.parse(localStorage.getItem('mycart'));
                        }
                        let item = mycart && mycart.filter(Fobj => Fobj.BetSelectionSort == this.state.cartItems[i].BetSelectionSort && Fobj.gamegroupID == this.state.cartItems[i].gamegroupID).length;
                        if (item == null || item == undefined || item == 0 || this.state.cartItems[i].gamegroupID == 13 || this.state.cartItems[i].gamegroupID == 15) {
                            if (this.state.cartItems[i].gamegroupID == 13 || this.state.cartItems[i].gamegroupID == 15) {
                                this.state.cartItems[i].stakeValue = this.state.cartItems[i].minStake * this.state.cartItems[i].OddsVal;
                                //multi draw implementation 
                                //More than 2 Lucky1 Draws Issue Solved
                                // if (this.state.currentDraw.gamegroupID == 13 && this.state.myUCDraw && this.state.myUCDraw.length == 1) {
                                if ((this.state.currentDraw.gamegroupID == 13 || this.state.cartItems[i].gamegroupID == 15) && this.state.myUCDraw && this.state.myUCDraw.filter(a => a == this.state.cartItems[i].GameId) == 0) {
                                    let fucd = this.state.myUCDraw.filter(a => a != this.state.cartItems[i].GameId);
                                    if (fucd && fucd.length > 0) {
                                        let _draw = this.state.currentAvailableDraws.filter(a => a.GameId == fucd[0]);
                                        if (_draw && _draw.length > 0) {
                                            _draw = _draw[0];
                                            this.state.cartItems[i].GameId = _draw.GameId;
                                            this.state.cartItems[i].DrawDateTime = _draw.DrawDateTime;
                                            this.state.cartItems[i].DrawTime = _draw.DrawTime;
                                            this.state.cartItems[i].DrawShowTime = _draw.DrawDate + " " + _draw.DrawShowTime;
                                            this.state.cartItems[i].SaleCloseTimeMSec = _draw.SaleCloseTimeMSec;
                                        }
                                    }

                                }
                            }
                            if (this.state.cartItems[i].gamegroupID != 13) {
                                this.props.AddToCart(this.state.cartItems[i]);
                            }
                            else {
                                this.AddCartLucky1(this.state.cartItems[i])
                            }


                            //multi draw implementaion
                            if ((this.state.currentDraw.gamegroupID == 13 || this.state.currentDraw.gamegroupID == 15) && this.state.myUCDraw && this.state.myUCDraw.length > 1) {
                                let fucd = this.state.myUCDraw.filter(a => a != this.state.cartItems[i].GameId);
                                if (fucd && fucd.length > 0) {
                                    //More than 2 Lucky1 Draws Issue Solved
                                    fucd.forEach(_fucd => {
                                        // let _draw = this.state.currentAvailableDraws.filter(a => a.GameId == fucd[0]);
                                        let _draw = this.state.currentAvailableDraws.filter(a => a.GameId == _fucd);
                                        if (_draw && _draw.length > 0) {
                                            let cartItemsM = { ...this.state.cartItems[i] }
                                            _draw = _draw[0];
                                            // let _maxid = 0 + (Math.random() * (99999 - 0));
                                            // cartItemsM.GamePadId = _maxid;
                                            cartItemsM.GameId = _draw.GameId;
                                            cartItemsM.DrawDateTime = _draw.DrawDateTime;
                                            cartItemsM.DrawTime = _draw.DrawTime;
                                            cartItemsM.DrawShowTime = _draw.DrawDate + " " + _draw.DrawShowTime;
                                            cartItemsM.SaleCloseTimeMSec = _draw.SaleCloseTimeMSec;
                                            // if(this.state.currentDraw.gamegroupID == 13){
                                            this.AddCartLucky1(cartItemsM)
                                            // }
                                            // else{
                                            //     cartItemsM.stakeValue = _draw.minStake * _draw.OddsVal;
                                            //     this.props.AddToCart(cartItemsM);
                                            // }
                                        }
                                    })
                                }

                            }
                        }
                    }
                }
                let cobj = {};
                let tcartitem = this.state.cartItems.filter(Fobj => Fobj.BetSelectionSort != undefined)
                commonClass.SendEventTracking(9, cobj, false, tcartitem);
                let prev_cartItem = [];
                localStorage.setItem('prevCartItem', prev_cartItem);
                this.showMsg('lblAddCartsucss');
                if (this.props.user == undefined || this.props.user.mobileNo == "0") {
                    let mobno = 0;
                    localStorage.setItem('lastloginuser', mobno);
                    setTimeout(() => {
                        this.showMsg('lblPlsLoginOrSignUp', 1);
                    }, 500);
                    bervalidate = false;
                    return;
                }
                else {
                    setTimeout(() => {
                        history.push('/mycart');
                    }, 1000);
                }
            }
            else {
                this.showMsg('lblMaxLen')
            }
        }
    }
    AddCartLucky1 = (Obj) => {
        if (Obj.gamegroupID == 13) {
            let lcartl = Obj.BetSelection.length;
            for (var j = 0; j <= lcartl - 1; j++) {
                let _maxid = 0 + (Math.random() * (99999 - 0));
                let storecart = { ...Obj };
                storecart.BetSelection = [storecart.BetSelection[j]];
                storecart.GamePadId = _maxid;
                storecart.BetSelectionSort = [storecart.BetSelection[j]];
                this.props.AddToCart(storecart);
            }
        }
        else {
            let _maxid = 0 + (Math.random() * (99999 - 0));
            let storecart = { ...Obj };
            storecart.GamePadId = _maxid;
            this.props.AddToCart(storecart);
        }

    }
    quickPick_lucy1 = (x) => {
        let darr = x.no_of_balls.split('~');
        let sballno = darr[0];
        let eballno = darr[1];
        let maxballno = darr[2];
        this.state.random = [...x.BetSelection.filter(a => a != '?')];
        // x.BetSelection = ['?']
        // this.state.random = [];
        let QPBallCount = maxballno - this.state.random.length;
        if (QPBallCount <= 0) {
            QPBallCount = maxballno;
        }
        let samel = [];
        // Per Total sign  selected Not working.
        if (x.BetSelection.length < eballno) {
            for (var k = sballno; k <= QPBallCount; k++) {
                let _bal = this.generateRandomInteger(x);
                samel = x.BetSelection.filter(a => a == _bal)
                if (samel.length > 0) {
                    QPBallCount = parseInt(QPBallCount) + 1;
                    samel = [];
                }
                else {
                    this.selectBall_lucy1(_bal, x);
                }
            }
        }
    }

    quickPick_pick3 = (x) => {
        let darr = x.no_of_balls.split('~');
        let sballno = darr[0];
        let eballno = darr[1];
        let maxballno = darr[2];
        this.state.random = [...x.BetSelection.filter(a => a != '?')];
        x.BetSelection.forEach((_bx, inx) => {
            x.BetSelection[inx] = '?';
        });
        this.state.random = [];
        let QPBallCount = maxballno - this.state.random.length;
        if (QPBallCount <= 0) {
            QPBallCount = maxballno;
        }
        for (var k = sballno; k < QPBallCount; k++) {
            let _bal = this.generateRandomInteger(x);
            this.selectBall_Pick3(_bal, x, k);
        }
    }



    MyCart = () => {
        //due to user can view empty cart.
        //if (this.props.cartItems && this.props.cartItems.length > 0) {
        if (this.props.cartItems && this.props.cartItems.length > 0) {
            history.push('/mycart');
        }
        else {
            this.showMsg(this.props.t('lblCartempty'));
        }
    }

    PickBetType = (x, bet_type) => {
        x.Pick3BetTypeid = bet_type.id;
        x.Pick3BetTypename = bet_type.betname;
    }

    CloseImage = () => {
        if(this.props.appvariables.bVersion){
            let hbannerVer = this.props.appvariables.bVersion.split("~");
            if (hbannerVer.length > 0) {
                localStorage.setItem('offerimgver', hbannerVer[6]);
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {process.env.REACT_APP_MODE == "production" && <>
                    {this.state.currentDraw.gamegroupID == 9 ? <Helmet>
                        <title>Gulf Ticket's Fortune 6: Your Gateway to AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FstPrize} Million for AED {appVariables.PRIZE_STRUCT_FORTUNE5.BetAmount}</title>
                        <meta name="description" content="Embark on a journey of excitement with Gulf Ticket's Fortune 6 lottery, offering a life-changing AED 200,000 prize for just AED 10." />
                        <meta name="keywords" content="Gulf Ticket, lottery, UAE, Fortune 6, AED 5 Million, AED 10, raffle, lottery, winners, draw, winning numbers, mega prize, Gulf Ticket ticket prices" />

                        <meta property="og:title" content={`Gulf Ticket's Fortune 6: Your Gateway to AED ${appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FstPrize} Million for AED ${appVariables.PRIZE_STRUCT_FORTUNE5.BetAmount}`} />
                        <meta property="og:site_name" content="Gulf Ticket" />
                        <meta property="og:url" content="https://gulfticket.com" />
                        <meta property="og:description" content="Stay ahead of the game with Gulf Ticket's draw results, featuring the latest winning numbers for both Fortune 6 ,Super 6 and Magic 5 lotteries." />
                        <meta property="og:type" content="website" />
                        <meta property="og:image" content="https://gulfticket.com/assets/img/gamesBg/fortune-6-logo.png" />
                    </Helmet > : this.state.currentDraw.gamegroupID == 10 ? <Helmet>
                        <title>Gulf Ticket's Super 6: Your Chance to Win AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} Million for AED {appVariables.PRIZE_STRUCT_SUPER6.BetAmount}</title>
                        <meta name="description" content="Experience the thrill of becoming a Gulf Ticket Super 6 millionaire with a staggering AED 100 million prize for just AED 30." />
                        <meta name="keywords" content="Gulf Ticket, lottery, UAE, Super 6, AED 100 million, AED 30, raffle, lottery, winners, draw, winning numbers, mega prize, Gulf Ticket ticket prices" />

                        <meta property="og:title" content={`Gulf Ticket's Fortune 6: Your Gateway to AED ${appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} Million for AED ${appVariables.PRIZE_STRUCT_SUPER6.BetAmount}`} />
                        <meta property="og:site_name" content="Gulf Ticket" />
                        <meta property="og:url" content="https://gulfticket.com" />
                        <meta property="og:description" content="Stay ahead of the game with Gulf Ticket's draw results, featuring the latest winning numbers for both Fortune 6 ,Super 6 and Magic 5 lotteries." />
                        <meta property="og:type" content="website" />
                        <meta property="og:image" content="https://gulfticket.com/assets/img/gamesBg/super-6-logo.png?v0.2" />
                    </Helmet > : this.state.currentDraw.gamegroupID == 11 ? <Helmet>
                        <title>Gulf Ticket's Magic 5: Your Chance to Win AED {appVariables.PRIZE_STRUCT_MAGIC5.MainDrawWin.FstPrize} for AED {appVariables.PRIZE_STRUCT_MAGIC5.BetAmount}</title>
                        <meta name="description" content="Experience the thrill of becoming a Gulf Ticket magic 5 millionaire with a staggering AED 500,000 prize for just AED 5." />
                        <meta name="keywords" content="Gulf Ticket, lottery, UAE, Magic 5, AED 500,000, AED 5, raffle, lottery, winners, draw, winning numbers, mega prize, Gulf Ticket ticket prices" />

                        <meta property="og:title" content="Experience the thrill of becoming a Gulf Ticket magic 5 millionaire with a staggering AED 500,000 prize for just AED 5." />
                        <meta property="og:site_name" content="Gulf Ticket" />
                        <meta property="og:url" content="https://gulfticket.com" />
                        <meta property="og:description" content="Stay ahead of the game with Gulf Ticket's draw results, featuring the latest winning numbers for both Fortune 6 ,Super 6 and Magic 5 lotteries." />
                        <meta property="og:type" content="website" />
                        <meta property="og:image" content="https://gulfticket.com/assets/img/gamesBg/magic-5-logo.png?v0.2" />
                    </Helmet > : <Helmet>
                        <title >Gulf Ticket: Your Gateway to Millionaire Dreams</title>
                        <meta name="description" content="Unleash your chance to win millions with Gulf Ticket, the UAE's premier online raffle draw. Experience the thrill of life-changing possibilities and become a part of our winning community." />
                        <meta name="keywords" content="Gulf Ticket, online raffle draw, UAE raffle draw, Gulf Ticket millionaire draw, best online raffle draw in the UAE, how to play Gulf Ticket, Gulf Ticket lottery prizes, Gulf Ticket results, Gulf Ticket winner stories, Gulf Ticket reviews, Gulf Ticket promo codes, Gulf Ticket contact information, online raffle Dubai, how to play Gulf Ticket online, Gulf Ticket schedule and results, best strategies for winning Gulf Ticket Lottery" />

                        <meta property="og:title" content="Gulf Ticket: Your Gateway to Millionaire Dreams" />
                        <meta property="og:site_name" content="Gulf Ticket" />
                        <meta property="og:url" content="https://gulfticket.com" />
                        <meta property="og:description" content="Unleash your chance to win millions with Gulf Ticket, the UAE's premier online raffle draw. Experience the thrill of life-changing possibilities and become a part of our winning community." />
                        <meta property="og:type" content="website" />
                        <meta property="og:image" content="https://gulfticket.com/assets/img/logo.png" />
                    </Helmet>}</>}
                {/* {process.env.REACT_APP_MODE == "production" &&
                    <Helmet>
                        <title >Gulf Ticket: Your Gateway to Millionaire Dreams</title>
                        <meta name="description" content="Unleash your chance to win millions with Gulf Ticket, the UAE's premier online raffle draw. Experience the thrill of life-changing possibilities and become a part of our winning community." />
                        <meta name="keywords" content="Gulf Ticket, online raffle draw, UAE raffle draw, Gulf Ticket millionaire draw, best online raffle draw in the UAE, how to play Gulf Ticket, Gulf Ticket lottery prizes, Gulf Ticket results, Gulf Ticket winner stories, Gulf Ticket reviews, Gulf Ticket promo codes, Gulf Ticket contact information, online raffle Dubai, how to play Gulf Ticket online, Gulf Ticket schedule and results, best strategies for winning Gulf Ticket Lottery" />

                        <meta property="og:title" content="Gulf Ticket: Your Gateway to Millionaire Dreams" />
                        <meta property="og:site_name" content="Gulf Ticket" />
                        <meta property="og:url" content="https://gulfticket.com" />
                        <meta property="og:description" content="Unleash your chance to win millions with Gulf Ticket, the UAE's premier online raffle draw. Experience the thrill of life-changing possibilities and become a part of our winning community." />
                        <meta property="og:type" content="website" />
                        <meta property="og:image" content="https://gulfticket.com/assets/img/logo.png" />
                    </Helmet>} */}
                <Header />
                <div className="wrap1">
                    <div id='Homeslide'>
                        {/* <div className="banner">
                        <ul className="slides" id='HomeslideChild'>
                            {this.state.bannerList && this.state.bannerList.length > 0 && this.state.bannerList.map((x, inx) =>
                                <li style={{ display: inx == 0 ? 'none' : 'none' }}>
                                    <img src={x.imagPath + '?' + x.version} onClick={() => { if (x.linkUrl) { history.push("/" + x.linkUrl) } }} />
                                </li>
                            )}
                        </ul>
                    </div > */}
                    </div >
                    <div className="hmGameSec">
                        <div className="hmGameTab" id='game_all'>
                            <ul className="hmGameTabLi" id='game_allover'>
                                {[13, 15, 14, 11, 9, 10].map((x1, inx1) => <>
                                    {(this.state.currentAvailableDraws && this.state.currentAvailableDraws.filter(a => a.gamegroupID == x1).length > 0) ?
                                        // borActives6 Super6 Border Hide issue Solved.
                                        this.state.currentAvailableDraws.filter(a => a.gamegroupID == x1).sort((a, b) => parseInt(b.SaleStatus) <= parseInt(a.SaleStatus) ? 1 : -1).slice(0, 1).map((x, inx) =>
                                            <li id={`game_${x.gamegroupID}`} key={`c_${inx}`} className={`${x.gamegroupID == this.state.currentDraw.gamegroupID ? `borActive  ${this.state.currentDraw.gamegroupID == 10 ? '' : ''}` : 'collapsed'}`}>
                                                <div className={`gameLi bg-game${x.gamegroupID} ${x.gamegroupID == this.state.currentDraw.gamegroupID && this.state.currentDraw.gamegroupID == 10 ? '' : ''}`} onClick={() => { this.setCurrentDraw(x, x1) }}>
                                                    <div className="gameLogo">
                                                        {x.gamegroupID == 9 ?
                                                            <img src="./assets/img/gamesBg/fortune-6-logo-white.png?v0.2" alt={`${x.LotName} logo`} className="object-fill" />
                                                            : x.gamegroupID == 10 ?
                                                                <img src="./assets/img/gamesBg/super-6-logo-white.png?v0.2" alt={`${x.LotName} logo`} className="object-fill" />
                                                                : x.gamegroupID == 11 ?
                                                                    <img src="./assets/img/gamesBg/magic-5-logo-white.png?v0.2" alt={`${x.LotName} logo`} className="object-fill" />
                                                                    : x.gamegroupID == 13 ?
                                                                        <img src="./assets/img/gamesBg/lucky-1-logo-white.png?v0.1" alt={`${x.LotName} logo`} className="object-fill" />
                                                                        : x.gamegroupID == 14 ?
                                                                            <img src="./assets/img/gamesBg/dream-5-logo-white.png?v0.1" alt={`${x.LotName} logo`} className="object-fill" />
                                                                            : x.gamegroupID == 15 ?
                                                                                <img src="./assets/img/gamesBg/pick-3-logo-white.png?v0.4" alt={`${x.LotName} logo`} className="object-fill" /> :
                                                                                <></>
                                                        }
                                                    </div>
                                                    {(x.gamegroupID != 13 && x.gamegroupID != 15) && <h3 className="text-white">
                                                        {/* {this.props.appvariables.currency} {x.RateInPs.replace('#', t('lblMillion'))}* */}
                                                        {this.props.appvariables.currency} {commonClass.aedCrnyConvert(x.RateInPs)}*
                                                    </h3>}
                                                    {(x.gamegroupID != 13 && x.gamegroupID != 15) ?
                                                        <h4 className="text-white">{x.DisplayDateTime} {x.DrawShowTime}(UAE)</h4>
                                                        :
                                                        <>
                                                            <h3 className="text-white">{t('lblevryday')}</h3>
                                                            <h4 className="text-white">&nbsp;</h4>
                                                        </>
                                                    }
                                                    {/* {x.SaleStatus != 1 ? <> */}
                                                    {(x.SaleCloseTimeMSec > 0 &&
                                                        ((this.state.showSaleCloseInfo && x.gamegroupID == 9) ||
                                                            (this.state.showSaleCloseSuper && x.gamegroupID == 10) ||
                                                            (this.state.SaleCloseInfoMagic && x.gamegroupID == 11) ||
                                                            (this.state.SaleCloseInfoLucky && x.gamegroupID == 13) ||
                                                            (this.state.SaleCloseInfoDream && x.gamegroupID == 14) ||
                                                            (this.state.showSaleClosePick3 && x.gamegroupID == 15))) ?
                                                        <div className={`adjustDate`} >{t('lblsalebfr')} {x.SaleCloseTimeMSec / (60 * 1000)} {t('lblminutes')} </div>
                                                        : <div className={`adjustDate`}>&nbsp;</div>
                                                    }
                                                    {/* </>
                                                        : <div className={`adjustDate`}>&nbsp;</div>
                                                    } */}
                                                    <div className="hmDateCoundown">
                                                        {this.reCount(x)}
                                                    </div>
                                                    <span className={`aedLable text-game${x.gamegroupID}`}>{this.props.appvariables.currency} {commonClass.aedCrnyConvert(x.minStake,1)}</span>
                                                </div>
                                            </li>
                                        ) :
                                        <>{
                                            // borActives6 Super6 Border Hide issue Solved.
                                            <li id={`game_${x1}`} className={`${x1 == this.state.currentDraw?.gamegroupID ? `borActive  ${this.state.currentDraw?.gamegroupID == 10 ? '' : ''}` : 'collapsed'}`}>
                                                <div className={`gameLi bg-game${x1} ${x1 == this.state.currentDraw?.gamegroupID && x1 == 10 ? '' : ''}`} onClick={() => { this.setCurrentDraw({ gamegroupID: x1 }) }}>
                                                    <div className="gameLogo">
                                                        {x1 == 9 ?
                                                            <img src="./assets/img/gamesBg/fortune-6-logo-white.png?v0.2" alt="fortune-6-logo" className="object-fill" />
                                                            : x1 == 10 ?
                                                                <img src="./assets/img/gamesBg/super-6-logo-white.png?v0.2" alt="super-6-logo" className="object-fill" />
                                                                : x1 == 11 ?
                                                                    <img x1 src="./assets/img/gamesBg/magic-5-logo-white.png?v0.2" alt="magic-5-logo" className="object-fill" />
                                                                    : x1 == 13 ?
                                                                        <img src="./assets/img/gamesBg/lucky-1-logo-white.png?v0.1" alt="lucky-1-logo" className="object-fill" />
                                                                        : x1 == 14 ?
                                                                            <img src="./assets/img/gamesBg/dream-5-logo-white.png?v0.1" alt="dream-5-logo" className="object-fill" />
                                                                            : x1 == 15 ?
                                                                                <img src="./assets/img/gamesBg/pick-3-logo-white.png?v0.4" alt="Pick-3-logo" className="object-fill" />
                                                                                : <></>
                                                        }
                                                    </div>
                                                    {this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0 && this.state.dataFatchCompleted ?
                                                        <h4 className="text-white" >{t('lblDrawClosed')}</h4>
                                                        : <h4 className="text-white" >{t('lblLoading')}..</h4>}
                                                </div>
                                            </li>
                                        }
                                        </>
                                    }</>
                                )}
                            </ul>
                        </div>

                        {this.state.currentDraw &&
                            <div className={`w-100 bg-game${this.state.currentDraw.gamegroupID}`}>
                                <div class="upComingDraw">
                                     <a class="liveDrawBtn" onClick={() => { window.showLivStrmVideo && window.showLivStrmVideo() }}><span class="live-icon"></span>Live Draw</a>
                                </div>
                                {(this.state.currentDraw.gamegroupID != 13 && this.state.currentDraw.gamegroupID != 15) ? <h3 className="headding7 wAuto customPadd1 text-upper">
                                    {this.state.currentDraw.gamegroupID == 9 ? appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinCount
                                        : this.state.currentDraw.gamegroupID == 10 ? appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount
                                            : this.state.currentDraw.gamegroupID == 11 ? appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount
                                                : this.state.currentDraw.gamegroupID == 14 ? appVariables.PRIZE_STRUCT_DREAM5.RaffleWinCount
                                                    : 0
                                    }
                                    {' '}- {t('lblguranteed')} {t('lblGuaranteedWinner')} {t('lblWinner')}</h3>
                                    :
                                    <div className='upComingDraw'>
                                        {this.state.currentAvailableDraws.filter(draw => draw.gamegroupID == this.state.currentDraw.gamegroupID && draw.SaleStatus != 1).map((x, inx) =>
                                            <button className={`${this.state.myUCDraw.filter(xx => xx == x.GameId).length > 0 ? `upcominDrawBtn ${this.state.currentDraw.gamegroupID == 13 ? 'upcominDrawBtnActiveLucky' : 'upcominDrawBtnActivePick3'}` : 'upcominDrawBtn'}`} onClick={() => { this.SelectUpcomeDraw(x) }} ><span>{x.DisplayDateTime} {`${x.DrawShowTime}`} UAE</span></button>
                                        )}
                                    </div>
                                }

                                <div className="hmGameSecCont">
                                    {this.state.currentDraw && this.state.currentDraw.SaleStatus == 0 ?
                                        <div className="hmGameLi">
                                            {this.state.cartItems && this.state.cartItems.length > 0 && this.state.cartItems.map((x, inx) =>
                                                <div className={`gameCont ${x.gamegroupID != 15 ? 'gameCont2 gamesContainerHeight' : ''} bg-img${x.gamegroupID}`} onMouseEnter={() => this.setIsShown(x, false)} onMouseLeave={() => this.setIsShown(x, true)}>
                                                    <div className="gameSelt">
                                                        {(this.state.MyFavNos && this.state.MyFavNos.length > 0) ?
                                                            <select value={(this.state.myfavNoID == 0 ? "-1" : this.state.myfavNoID)} onChange={(e) => this.setMyFavNo(e, x)}>
                                                                <option value="-1">{t('lblChooseFav')}</option>
                                                                {this.state.MyFavNos && this.state.MyFavNos.map((mfav, inxs) =>
                                                                    <option key={`ax_${inxs}`} value={inxs}>{mfav.numbers}</option>
                                                                )}
                                                            </select> :
                                                            <select>
                                                                <option value="-1">{t('lblNoFav')}</option>
                                                            </select>}
                                                        {/* itemsCenter Arabic Language Change Icons Alignment Issue*/}
                                                        <div className="likeAndShare  likeAndShare2 float-right">
                                                            <a onClick={() => { this.saveMyFavNo(x) }} ><img src="./assets/img/icons/like.png" alt='like Icon' /></a>
                                                            <a onClick={() => { (x.gamegroupID == 13 ? this.quickPick_lucy1(x) : x.gamegroupID == 15 ? this.quickPick_pick3(x) : this.quickPick(x)) }}><img src="./assets/img/icons/shuffle.png" alt='shuffle Icon' /></a>
                                                            <button type="button" className="text-center deleteBtn marginLeft1">
                                                                <img src="./assets/img/icons/delete.webp" alt="delete button" className="margin5Top" onClick={() => { this.removeGamePad(inx) }} />
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {x.gamegroupID != 13 && <ul className={`ballSec game-${x.gamegroupID}-balls ${x.gamegroupID == 10 ? "topBallsAdjust2" : x.gamegroupID == 9 ? "topBallsAdjust2" : x.gamegroupID == 15 ? "drawBallSection3" : "topBallsAdjust1"}`} >
                                                        {x.BetSelection.map((x1, inx) => <li key={`ay_${inx}`}><a className={`${x1 != '?' ? `game-${x.gamegroupID}-active` : ''}`}>{x1}</a></li>
                                                        )}
                                                    </ul>}
                                                    {x.gamegroupID == 15 && x.Pick3BetTypename && <div class="gameTypeBtn border-left-0">
                                                        <a class="margin-0-auto game-15-active">{x.Pick3BetTypename}</a>
                                                    </div>}
                                                    <div className="circlePosn">
                                                        <div className={`half-circle circle1 bg-game${x.gamegroupID}`}></div>
                                                        <div className="half-circleLine"></div>
                                                        <div className={`half-circle circle2 bg-game${x.gamegroupID}`}></div>
                                                    </div>

                                                    {x.gamegroupID == 13 ?
                                                        <ul className={`zodiacBallSec zodiacBallSecInr drawBallSection game-13-balls rowGap ${x.betComplete ? `addTick${x.gamegroupID}` : ''}`}>
                                                            {this.createBall_lucy1(x)}
                                                        </ul> : x.gamegroupID == 15 ?
                                                            <div className={`${(x.betComplete == true && x.Pick3BetTypename) ? `w-100 addTick${x.gamegroupID}` : ''}`}>
                                                                <ul className={`ballSec drawBallSection3 rowGap dottedBorRht game-${x.gamegroupID}-balls `}>
                                                                    {this.createBall_Pick3(x)}
                                                                </ul>
                                                                {pick3BetTypeLst && pick3BetTypeLst.length > 0 && <div class="gameTypeBtn">
                                                                    {pick3BetTypeLst.map((x1, inx) =>
                                                                        <>{(x.BetType == "" || x.BetType.split(',')[inx] == '1') &&
                                                                            <a className={`${x1.id == x.Pick3BetTypeid ? 'game-15-active' : ''}`} onClick={() => { this.PickBetType(x, x1) }}>{x1.betname}</a>
                                                                        }</>
                                                                    )}
                                                                </div>}
                                                            </div>
                                                            :
                                                            <ul className={`ballSec drawBallSection rowGap game-${x.gamegroupID}-balls ${x.betComplete ? `addTick${x.gamegroupID}` : ''}`}>
                                                                {this.createBall(x)}
                                                            </ul>}

                                                    {x.gamegroupID != 13 && x.gamegroupID != 15 ?
                                                        <button className="clrAll" onClick={() => { this.clearCart(x) }}>{t('lblClearAll')}</button>
                                                        :
                                                        <>
                                                            <div className={`entryLbl text-game${x.gamegroupID}`} >{x.gamegroupID == 15 ? t('lblmultientry1000') : t('lblmultientry')}</div>
                                                            <div className="contw100" >
                                                                {locky1Arr.map((nx, inxl) =>
                                                                    <button key={`bc_${inxl}`} onClick={() => { this.selMulti_lucy1(x, nx, 1) }} className={`${x.gamegroupID == 13 ? 'zodiacValBtn' : 'pick3ValBtn'} ${x.OddsVal == nx ? `bg-game${x.gamegroupID} text-white` : ''}`}>{nx}<sup>x</sup></button>
                                                                )}
                                                                {/*  */}
                                                                <div className={`${x.gamegroupID == 13 ? 'zodiacValCont' : 'pick3ValCont'}`}>
                                                                    <span onClick={() => { this.selMulti_lucy1_sub(x) }}>x</span>
                                                                    <input onBlur={(obj) => this.multiKyblur(obj, x)} onKeyUp={(obj) => this.multiKup(obj, x)} id='txtmtpl' maxLength={4} className={`${x.gamegroupID == 13 ? 'zodiacValInpt' : 'pick3ValInpt'}`} />
                                                                </div>
                                                                {x.gamegroupID != 15 && <div className="lckWindtls">
                                                                    <p>{t('lblPay')} {commonClass.aedCrnyConvert(x.minStake * x.OddsVal)}</p>
                                                                    <span>{t('lblwin')} {commonClass.aedCrnyConvert((x.minStake * x.OddsVal) * x.StakeMulti)}</span>
                                                                    {this.state.myUCDraw && this.state.myUCDraw.length > 1 &&
                                                                        // <div className="entryLbl">Total Pay Amount is {(x.minStake * x.OddsVal) * this.state.myUCDraw.length}</div>
                                                                        <div className="entryLbl">Total Pay Amount is {commonClass.aedCrnyConvert(((x.minStake * x.OddsVal) * x.BetSelection.length) * this.state.myUCDraw.length)}</div>
                                                                    }
                                                                </div>}
                                                            </div>
                                                        </>
                                                    }

                                                    {this.state.cartItems.length - 1 == inx &&
                                                        <div className="buyDisable" onClick={() => { this.ballPadGenerate("+") }}>
                                                            {x.gamegroupID == 9 ? <img src="./assets/img/plus-circle.png?v0.1" alt={`${x.LotName} plus sign`} className="addImgIcon" />
                                                                : x.gamegroupID == 10 ? <img src="./assets/img/game2_plus.png" alt={`${x.LotName} plus sign`} className="addImgIcon" />
                                                                    : x.gamegroupID == 11 ? <img src="./assets/img/plus-circle-3.png" alt={`${x.LotName} plus sign`} className="addImgIcon" />
                                                                        : x.gamegroupID == 13 ? <img src="./assets/img/plus-circle-13.png" alt={`${x.LotName} plus sign`} className="addImgIcon" />
                                                                            : x.gamegroupID == 14 ? <img src="./assets/img/plus-circle-dream.png" alt={`${x.LotName} plus sign`} className="addImgIcon" />
                                                                                : x.gamegroupID == 15 ? <img src="./assets/img/plus-circle-pick-3.png" alt={`${x.LotName} plus sign`} className="addImgIcon" />
                                                                                    : <></>}
                                                        </div>}

                                                </div>
                                            )}
                                        </div>
                                        :
                                        <div className="hmGameLi">
                                            <div className={`gameCont gameCont2 gamesContainerHeight bg-img${this.state.currentDraw.gamegroupID}`}>
                                                <h4 className="text-center">{t('lblDrawClosed')}</h4>
                                                <div className="circlePosn">
                                                    <div className={`half-circle circle1 bg-game${this.state.currentDraw.gamegroupID}`}></div>
                                                    <div className="half-circleLine"></div>
                                                    <div className={`half-circle circle2 bg-game${this.state.currentDraw.gamegroupID}`}></div>
                                                </div>
                                                <div className="flex flex-col items-center my-16">
                                                    {this.state.currentDraw.gamegroupID == 9 ?
                                                        <img src="./assets/img/gamesBg/fortune-5-paused.svg?v0.1" alt="fortune-4-Paused" className="w-48" />
                                                        : this.state.currentDraw.gamegroupID == 10 ?
                                                            <img src="./assets/img/gamesBg/super-6-paused.svg" alt="super-6-Paused" className="w-48" />
                                                            : this.state.currentDraw.gamegroupID == 11 ?
                                                                <img x1 src="./assets/img/gamesBg/magic-5-paused.svg" alt="magic-5-Paused" className="w-48" />
                                                                : this.state.currentDraw.gamegroupID == 13 ?
                                                                    <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="lucky-1-Paused" className="w-48" />
                                                                    : this.state.currentDraw.gamegroupID == 14 ?
                                                                        <img src="./assets/img/gamesBg/dream-5-paused.svg" alt="dream-5-Paused" className="w-48" />
                                                                        : this.state.currentDraw.gamegroupID == 15 ?
                                                                            <img src="./assets/img/gamesBg/pick-3-paused.svg" alt="pick-3-Paused" className="w-48" />
                                                                            : <></>
                                                    }

                                                    <p className={`text-lg font-semibold text-game${this.state.currentDraw.gamegroupID} mt-5 text-center`}>
                                                        {t('lblclosed')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="buyGameLft">
                                        <div className="buyGameLftImg">
                                            {this.state.currentDraw.gamegroupID == 9 || this.state.currentDraw.gamegroupID == 14 ?
                                                <img src="./assets/img/BottleWithML-1.png?v0.1" alt='Fortune4 Bottle Icon' />
                                                : this.state.currentDraw.gamegroupID == 10 ? <img src="./assets/img/BottleWithML-2.png" alt='Super6 Bottle Icon' />
                                                    : <img src="./assets/img/BottleWithML-3.png" alt='Magic5 Bottle Icon' />
                                            }
                                        </div>
                                        <div className="plusMinus mar0Auto">
                                            <a className={`bg-game${this.state.currentDraw.gamegroupID}-2`} onClick={() => { this.ballPadGenerate("-") }}>-</a>
                                            {this.state.txtShow != true ?
                                                <span onClick={() => { this.setState({ txtShow: true }); }}> {this.state.cartItems.length > 0 ? this.state.cartItems.length - 1 : 0}</span>
                                                :
                                                <input type='text' id='txtpadcnt' autoFocus onBlur={(obj) => this.addCustomizePad_b(obj)} onKeyUp={(obj) => this.addCustomizePad_k(obj)} maxLength={2} />
                                            }
                                            <a className={`bg-game${this.state.currentDraw.gamegroupID}-2`} onClick={() => { this.ballPadGenerate("+") }}>+</a>
                                        </div>
                                        <div className="btnsCont lckbtnsCont">
                                            <button className={`bg-game${this.state.currentDraw.gamegroupID} ${this.state.currentDraw.gamegroupID == 10 ? `text-white` : `text-game${this.state.currentDraw.gamegroupID}`}`} onClick={() => { this.addMyCart() }}>{t('lblAddTocart')}</button>
                                        </div>
                                        {this.state.currentDraw.gamegroupID == 13 && this.state.myUCDraw && this.state.myUCDraw.length > 0 ?
                                            // <a className="subTotal">{t('lblSubTotal')} ({this.props.appvariables.currency}) : {((this.state.cartItems.reduce((a, b) => a = a + (b.stakeValue * b.OddsVal), 0)) * this.state.myUCDraw.length)}</a>
                                            <a className="subTotal">{t('lblSubTotal')} ({this.props.appvariables.currency}) : {commonClass.aedCrnyConvert(((this.state.cartItems.reduce((a, b) => a = a + ((b.stakeValue * b.OddsVal) * b.BetSelection.length), 0)) * this.state.myUCDraw.length))}</a>
                                            :
                                            this.state.currentDraw.gamegroupID == 15 && this.state.myUCDraw && this.state.myUCDraw.length > 0 ?
                                                <a className="subTotal">{t('lblSubTotal')} ({this.props.appvariables.currency}) : {commonClass.aedCrnyConvert(((this.state.cartItems.reduce((a, b) => a = a + ((b.stakeValue * b.OddsVal)), 0)) * this.state.myUCDraw.length))}</a>
                                                :
                                                <a className="subTotal">{t('lblSubTotal')} ({this.props.appvariables.currency}) : {commonClass.aedCrnyConvert(this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0))}</a>
                                        }
                                        <small className="subTotalDesc">
                                            {t('lblbottletext')}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
                {this.state.currentDraw.gamegroupID == 9 ? <AboutFortureFive /> :
                    this.state.currentDraw.gamegroupID == 11 ? <AboutMgicFive /> :
                        this.state.currentDraw.gamegroupID == 10 ? <AboutSuperSix /> : this.state.currentDraw.gamegroupID == 14 ? <AboutDreamFive /> : this.state.currentDraw.gamegroupID == 15 ? <AboutPickThree /> : <AboutLuckyOne />}
                <PriceBreakdown />
                {/* <BrandTrust /> */}
                <HowToPlay ispage={false} />
                <LatestResults />
                <DownloadApp />
                <div className="cart" onClick={() => { this.MyCart() }}><img src="./assets/img/icons/cart.svg" alt="Cart Icon" /><span>{this.props.cartItems ? this.props.cartItems.length : 0}</span></div>
                <a id="bckTop"><img src="./assets/img/icons/back-to-top.png" alt='back-to-top Image' /></a>
                {/* TwitchTv Embeded Popup */}

                <div class="lightboxinfo2">
                    <a class="lightBoxClose"><img src="./assets/img/icons/cloes-icon.png?v0.1" /></a>
                    <div class="lightBox-wrap">
                        <span>
                            {/* <img src="./assets/img/gamesBg/lucky-1-logo.png" /> */}
                            {/* <iframe src="https://player.twitch.tv/?channel=gulfticket7&parent=localhost&muted=true" height={`${window.screen.height / 1.3}`} width={`${window.screen.width / 1}`} allowfullscreen></iframe> */}
                            {/* <iframe src={process.env.REACT_APP_TWICTVORIGIN} height={`${window.screen.height / 2}`} width={`${window.screen.width / 1.6}`} allowfullscreen></iframe>  document.documentElement.clientWidth-480 850*/}
                            {/* <iframe src={(this.state.currentDraw.gamegroupID == 13 || this.state.currentDraw.gamegroupID == 15) ? process.env.REACT_APP_TWICTVORIGIN : process.env.REACT_APP_YOUTUBEURL} height={`${document.documentElement.clientHeight - 180}`} width={`${'100%'}`} allowfullscreen></iframe> */}
                            {(this.state.currentDraw.gamegroupID == 13 || this.state.currentDraw.gamegroupID == 15) ?
                                <iframe src={process.env.REACT_APP_TWICTVORIGIN} height={`${document.documentElement.clientHeight - 180}`} width={`${'100%'}`} allowfullscreen></iframe>
                                : <iframe src={process.env.REACT_APP_YOUTUBEURL} height={`${document.documentElement.clientHeight - 180}`} width={`${'100%'}`} allowfullscreen></iframe>
                            }
                        </span>
                        {/* <a class="liveDrawPopBtn">Close</a> */}
                    </div>
                </div>
                <Offerimage />
                <div onClick={() => {this.CloseImage()}}  class="overlay"></div>
            </>
        );
    }
}


function mapStateToProps(state) {
    const { currentAvailableDraws, MyFavNos } = state.gameReducer;
    const { user, appvariables } = state.loginReducer;
    const { serverTime } = state.commonReducer;
    const { cartItems } = state.gameReducer;
    return { user, serverTime, currentAvailableDraws, appvariables, MyFavNos, cartItems };
}
const mapActionToProps = {
    GameFamily: gameActions.GameFamily,
    GameAvailableGames: gameActions.GameAvailableGames,
    Buybet: gameActions.Buybet,
    AddToCart: gameActions.AddToCart,
    ClearCart: gameActions.ClearCart,
    closeBetSlip: gameActions.closeBetSlip,
    alertError: alertActions.error,
    alertsucc: alertActions.success,
    SaveMyFavNumbers: gameActions.SaveMyFavNumbers,
    GetMyFavNumbers: gameActions.GetMyFavNumbers,
    UpdateCartAll: gameActions.UpdateCartAll,
    clearAlerts: alertActions.clear,
    getBanner: loginActions.getBanner,

}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Home))
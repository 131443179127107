import { appVariables } from "./appVariables";
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';
import { isExpired, decodeToken } from "react-jwt";
import CryptoJS from 'crypto-js';
const _apiUrl = process.env.REACT_APP_API_URL;




//let user = JSON.parse(localStorage.getItem('user'));
//const g_ccode = (user != null && user.Country_code != "") ? user.Country_code : '0'
export const commonClass = {
    requestOptions,
    getRequestOptions,
    handleResponse,
    writeLog,
    SendPageEvent,
    SendOtherEvent,
    SendPageDataLayer,
    encrypt,
    decrypt,
    SendFacebookpixel,
    GetCountry,
    SendUserTracking,
    SendEventTracking,
    GetUserCountry,
    aedCrnyConvert,
    SetCountryCode,
    LoadCountry
};

function SendPageEvent(myloc) {
    try {
        //ReactGA.send({ hitType: "pageview", page: myloc, title: "Website" });

        let tagMArgs = {
            gtmId: process.env.REACT_APP_GA_TRACKING_ID,
            application: 'Website',
            page: myloc,
            action: 'View'
        }
        TagManager.initialize(tagMArgs);

        let tagMArgs1 = {
            gtmId: process.env.REACT_APP_GA_TRACKING_ID1,
            application: 'Website',
            page: myloc,
            action: 'View'
        }
        TagManager.initialize(tagMArgs1);

        // let tagManagerArgs = {
        //     gtmId: process.env.REACT_APP_GA_TRACKING_ID,
        //     dataLayer: {
        //         application: 'Website',
        //         page: myloc,
        //         action: 'View'
        //     }
        // }
        // TagManager.dataLayer(tagManagerArgs);
    } catch (ex) {
        console.error("SendPageEvent", ex)
    }
}
function SendOtherEvent(mycat, myact, obj) {
    // return; //kalai told no need to call this 
    try {
        let url = "";
        let timestp = new Date().toISOString();
        if (mycat == 1 && myact == 1) {
            let flagId = process.env.REACT_APP_MODE == "production" ? 242 : 240;
            url = "https://alpha1.digiaxis.net/trace/v1/conv/con.gif?of=" + flagId + "&ac=7&af=20&cs=&ts=" + timestp;
            fetch(url).then(response => {
                response.text().then(text => {
                    if (!response.ok) {
                        const error = { message: response.statusText };
                        //return Promise.reject(error);
                    }
                    const data = text;
                    //const data = text && JSON.parse(text);
                    //return data;
                });
            })
        }
        else if (mycat == 3 && myact == 1) {
            let gamegroupLst = (obj && obj.betLst) ? [...new Set(obj.betLst.map(item => item.Id))] : [];
            gamegroupLst.forEach(element => {
                let cnt = obj.betLst.filter(a => a.Id == element);
                // let Fortune5_no_of_lines = cnt[0].GameName.toLowerCase().indexOf('super') == -1 ? cnt.length : 0;
                // let Super6_no_of_lines = cnt[0].GameName.toLowerCase().indexOf('super') >= 0 ? cnt.length : 0;

                // let Fortune5_no_of_lines = cnt[0].BallNo.split(';').length == 5 ? cnt.length : 0;
                // let Super6_no_of_lines = cnt[0].BallNo.split(';').length == 6 ? cnt.length : 0;
                let Fortune5_no_of_lines = 0;
                let Super6_no_of_lines = 0;
                let Magic5_no_of_lines = 0;
                let Zodiac_no_of_lines = 0;
                let Dream5_no_of_lines = 0;
                let Pick3_no_of_lines = 0;
                // obj.ggourArr && obj.ggourArr.forEach(ggID => {
                switch (cnt[0].gamegroupID) {
                    case 9:
                        Fortune5_no_of_lines = cnt.length;
                        break;
                    case 10:
                        Super6_no_of_lines = cnt.length;
                        break;
                    case 11:
                        Magic5_no_of_lines = cnt.length;
                        break;
                    case 13:
                        Zodiac_no_of_lines = cnt.length;
                        break;
                    case 14:
                        Dream5_no_of_lines = cnt.length;
                        break;
                    case 15:
                        Pick3_no_of_lines = cnt.length;
                        break;
                }
                // });
                let transaction_amount = obj.betLst.filter(a => a.Id == element).reduce((a, b) => a = a + b.StakeValue, 0);
                let user_id = obj.mobileNo;
                let transaction_id = obj.betLst.filter(a => a.Id == element)[0].Id;
                // let GameId = process.env.REACT_APP_MODE == "production" ? 243 : 241;
                let GameId = 0;
                if (cnt[0].gamegroupID == 9) {
                    GameId = process.env.REACT_APP_MODE == "production" ? 243 : 241;
                }
                else if (cnt[0].gamegroupID == 11) {
                    GameId = process.env.REACT_APP_MODE == "production" ? 323 : 322;
                }
                else if (cnt[0].gamegroupID == 10) {
                    GameId = process.env.REACT_APP_MODE == "production" ? 258 : 257;
                }
                else if (cnt[0].gamegroupID == 14) {
                    GameId = process.env.REACT_APP_MODE == "production" ? 458 : 457;
                }
                else if (cnt[0].gamegroupID == 15) {
                    GameId = process.env.REACT_APP_MODE == "production" ? 459 : 458;
                }
                else {
                    GameId = process.env.REACT_APP_MODE == "production" ? 334 : 332;
                }
                url = "https://alpha1.digiaxis.net/trace/v1/conv/con.gif?of=" + GameId + "&ac=7&af=20&cs=0&cp1=" + Fortune5_no_of_lines + "&cp2=" + Super6_no_of_lines + "&cp3=" + Magic5_no_of_lines + "&cp8=" + Zodiac_no_of_lines + "&cp5=" + transaction_amount + "&cp6=" + user_id + "&cp7=" + transaction_id + "&ts=" + timestp;
                // url = "https://alpha1.digiaxis.net/trace/v1/conv/con.gif?of=" + GameId + "&ac=7&af=20&cs=0&cp1=" + Fortune5_no_of_lines + "&cp2=" + Super6_no_of_lines + "&cp5=" + transaction_amount + "&cp6=" + user_id + "&cp7=" + transaction_id + "&ts=" + timestp;
                fetch(url).then(response => {
                    response.text().then(text => {
                        if (!response.ok) {
                            const error = { message: response.statusText };
                            //return Promise.reject(error);
                        }
                        const data = text;
                        //const data = text && JSON.parse(text);
                        //return data;
                    });
                })
            });
        }

    } catch (ex) {
        console.error("SendOtherEvent", ex)
    }
}

function SendPageDataLayer(eventID, Info) {
    try {
        let eventName = "";
        switch (eventID) {
            case 1:
                eventName = "login_success";
                break;
            case 2:
                eventName = "login_failure";
                break;
            case 3:
                eventName = "registration_success";
                break;
            case 4:
                eventName = "registration_failure";
                break;
            case 5:
                eventName = "fortune4_line_selected";
                break;
            case 6:
                eventName = "super6_line_selected";
                break;
            case 7:
                eventName = "add_to_cart";
                break;
            case 8:
                eventName = "proceed_to_pay";
                break;
            case 9:
                eventName = "proceed_to_payment_gateway";
                break;
            case 10:
                eventName = "payment_success";
                break;
            case 11:
                eventName = "payment_failure";
                break;
            case 12:
                eventName = "purchase_success";
                break;
            case 13:
                eventName = "purchase_failure";
                break;
            case 14:
                eventName = "fortune4_purchase_success";
                break;
            case 15:
                eventName = "super6_purchase_success";
                break;
            case 16:
                eventName = "magic5_purchase_success";
                break;
            case 17:
                eventName = "magic5_line_selected";
                break;
            case 18:
                eventName = "lucky1_purchase_success";
                break;
            case 19:
                eventName = "lucky1_line_selected";
                break;
            case 21:
                eventName = "dream5_purchase_success";
                break;
            case 22:
                eventName = "dream5_line_selected";
                break;
            case 23:
                eventName = "Pick3_purchase_success";
                break;
            case 24:
                eventName = "Pick3_line_selected";
                break;
            default:
                eventName = "Unknow"
                break;
        }
        let _dataLayer = {
            event: eventName
        };
        if (eventID == 4 && Info) {
            let infoArr = Info.split('~');
            _dataLayer.eventProps = {
                mobileno: infoArr[0],
                countrycode: infoArr[1],
                message: infoArr[2],
            }
        }
        let tagManagerArgs = {
            gtmId: process.env.REACT_APP_GA_TRACKING_ID,
            dataLayer: _dataLayer
        }
        TagManager.dataLayer(tagManagerArgs);

        let tagManagerArgs1 = {
            gtmId: process.env.REACT_APP_GA_TRACKING_ID1,
            dataLayer: _dataLayer
        }
        TagManager.dataLayer(tagManagerArgs1);
    } catch (ex) {
        console.error("SendPageDataLayer", ex)
    }
}

function langidsel(lang_val) {
    switch (lang_val) {
        case "en":
            lang_val = appVariables.LANGUAGE_ID.English;
            break;
        case "ar":
            lang_val = appVariables.LANGUAGE_ID.Arabic;
            break;
        case "ml":
            lang_val = appVariables.LANGUAGE_ID.Malayalam;
            break;
        case "ur":
            lang_val = appVariables.LANGUAGE_ID.Urdu;
            break;
        case "th":
            lang_val = appVariables.LANGUAGE_ID.Thai;
            break;
        case "tl":
            lang_val = appVariables.LANGUAGE_ID.Filipino;
            break;
        case "es":
            lang_val = appVariables.LANGUAGE_ID.Spanish;
            break;
        case "ta":
            lang_val = appVariables.LANGUAGE_ID.Tamil;
            break;
        case "hi":
            lang_val = appVariables.LANGUAGE_ID.Hindi;
            break;
        case "id":
            lang_val = appVariables.LANGUAGE_ID.Indonisia;
            break;
        case "vi":
            lang_val = appVariables.LANGUAGE_ID.vietnam;
            break;
        case "km":
            lang_val = appVariables.LANGUAGE_ID.khmer;
            break;
        default:
            lang_val = appVariables.LANGUAGE_ID.English;
    }
    return lang_val;
}

// function requestOptions(data, mobileNo, langID = null, sessionKey = null, Ccode) {
//     try {
//         if (Ccode == undefined || Ccode == null) {
//             let user = JSON.parse(localStorage.getItem('user'));
//             if (user && user.Country_code && user.Country_code != "")
//                 Ccode = user.Country_code;
//             else
//                 Ccode = process.env.REACT_APP_Countrycode;//91;
//         }

//         // if (Ccode == undefined || Ccode == null) {
//         //     Ccode = '+971';
//         // }
//         langID = langidsel(langID);
//         return {
//             method: 'POST',
//             //mode: 'cors',
//             crossorigin: true,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Access-Control-Allow-Origin': '*',
//                 'User_Id': mobileNo,
//                 'Channel_Id': process.env.REACT_APP_ChannelId, //1- web, 2- app, 3 - mobile_web,app_web- 4
//                 'Version': process.env.REACT_APP_Version,
//                 'TVN': process.env.REACT_APP_TVN,
//                 'SNO': sessionKey,
//                 'Language_Id': langID,
//                 'Country_Code': Ccode,
//                 'IP_Address': ''
//             },
//             body: JSON.stringify(data)
//         };
//     } catch (ex) {
//         console.error("requestOptions", ex)
//         return null;
//     }
// };
// function getRequestOptions(mobileNo, langID = null, sessionKey = null, Ccode) {
//     try {
//         if (Ccode == undefined || Ccode == null) {
//             let user = JSON.parse(localStorage.getItem('user'));
//             if (user && user.Country_code && user.Country_code != "")
//                 Ccode = user.Country_code;
//             else
//                 Ccode = process.env.REACT_APP_Countrycode;//91;
//         }
//         langID = langidsel(langID);
//         return {
//             method: 'GET',
//             // mode: 'cors',
//             crossorigin: true,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Access-Control-Allow-Origin': '*',
//                 'User_Id': mobileNo,
//                 'Channel_Id': process.env.REACT_APP_ChannelId, //1- web, 2- app, 3 - mobile_web,app_web- 4
//                 'Version': process.env.REACT_APP_Version,
//                 'TVN': process.env.REACT_APP_TVN,
//                 'SNO': sessionKey,
//                 'Language_Id': langID,
//                 'Country_Code': Ccode,
//                 'IP_Address': ''
//             }
//         };
//     } catch (ex) {
//         console.error("getRequestOptions", ex)
//         return null;
//     }
// };
// function handleResponse(response) {
//     try {
//         return response.text().then(text => {
//             if (!response.ok) {
//                 //if (response.status === 401) {
//                 //auto logout if 401 response returned from api
//                 //logout();
//                 //location.reload(true);
//                 //}

//                 //const error = (data && data.message) || response.statusText;
//                 //const error ={message:( (data && data.message) || response.statusText)};
//                 const error = { message: response.statusText };
//                 return Promise.reject(error);
//             }
//             const data = text && JSON.parse(text);
//             // let en_data = Decryption(data);
//             return data;
//         });
//     } catch (ex) {
//         // writeLog(ex);
//     }
// };

function writeLog(ob) {

}

async function requestOptions(data, mobileNo, langID = null, sessionKey = null, Ccode) {
    // if (Ccode == null) {
    //     Ccode = 971;
    // }
    try {
        if (Ccode == undefined || Ccode == null) {
            let user = decrypt(localStorage.getItem('user'));
            try {
                user = JSON.parse(user);
            }
            catch (ex) {
                localStorage.removeItem('user')
            }
            if (user && user.Country_code && user.Country_code != "")
                Ccode = user.Country_code;
            else {
                Ccode = process.env.REACT_APP_Countrycode;//971;
                // alert(',')
            }
        }
        // if (!mobileNo) {
        //     alert(',,')
        // }
        langID = langidsel(langID);
        let _headerToken = "";
        let en_mob = encrypt(mobileNo);
        if (mobileNo) {
            let _token = localStorage.getItem('jtkn');
            let _tokenMob = decrypt(localStorage.getItem('jtknmob'));
            let newTokenNeed = false;
            if ((_tokenMob == "0" || mobileNo == "0") && _tokenMob != mobileNo) {
                newTokenNeed = true;
            }
            //const myDecodedToken = decodeToken(_token);
            const isMyTokenExpired = _token ? isExpired(_token) : true;
            if (isMyTokenExpired || newTokenNeed) {
                _token = await gettoken(mobileNo, langID, sessionKey, Ccode);
                localStorage.setItem('jtkn', _token)
                localStorage.setItem('jtknmob', encrypt(en_mob));
            }
            _headerToken = 'Bearer ' + _token
        }
        let str_data = JSON.stringify(data);
        let en_data = encrypt(str_data);

        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'User_Id': en_mob,
                'Channel_Id': process.env.REACT_APP_ChannelId,
                'Version': process.env.REACT_APP_Version,
                'TVN': process.env.REACT_APP_TVN,
                'SNO': sessionKey,
                'Language_Id': langID,
                'Country_Code': Ccode,
                'IP_Address': '',
                'Authorization': _headerToken
            },
            body: JSON.stringify({ data: en_data })
        };
    }
    catch (ex) {
        console.log("requestOptions", ex)
        return null;
    }
};
async function getRequestOptions(mobileNo, langID = null, sessionKey = null, Ccode) {
    // if (Ccode == null) {
    //     Ccode = 971;
    // }
    try {
        if (Ccode == undefined || Ccode == null) {
            let user = decrypt(localStorage.getItem('user'));
            try {
                user = JSON.parse(user);
            }
            catch (ex) {
                localStorage.removeItem('user')
            }
            if (user && user.Country_code && user.Country_code != "")
                Ccode = user.Country_code;
            else {
                Ccode = process.env.REACT_APP_Countrycode;//971;
                // alert(',')
            }
        }
        // if (!mobileNo) {
        //     alert(',,')
        // }
        langID = langidsel(langID);
        let _headerToken = "";
        let en_mob = encrypt(mobileNo);
        if (mobileNo) {
            let _token = localStorage.getItem('jtkn')
            let _tokenMob = decrypt(localStorage.getItem('jtknmob'))
            let newTokenNeed = false;
            if ((_tokenMob == "0" || mobileNo == "0") && _tokenMob != mobileNo) {
                newTokenNeed = true;
            }
            //const myDecodedToken = decodeToken(_token);
            const isMyTokenExpired = _token ? isExpired(_token) : true;
            if (isMyTokenExpired || newTokenNeed) {
                _token = await gettoken(mobileNo, langID, sessionKey, Ccode);
                localStorage.setItem('jtkn', _token);
                localStorage.setItem('jtknmob', encrypt(mobileNo));
            }
            _headerToken = 'Bearer ' + _token
        }

        return {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'User_Id': en_mob,
                'Channel_Id': process.env.REACT_APP_ChannelId,
                'Version': process.env.REACT_APP_Version,
                'TVN': process.env.REACT_APP_TVN,
                'SNO': sessionKey,
                'Language_Id': langID,
                'Country_Code': Ccode,
                'IP_Address': '',
                'Authorization': _headerToken
            }
        };
    }
    catch (ex) {
        console.log('getRequestOptions', ex)
        return null;
    }
};
function handleResponse(response) {
    try {
        return response.text().then(text => {
            if (!response.ok) {
                //if (response.status === 401) {
                //auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
                //}

                const error = { message: response.statusText };
                return Promise.reject(error);

                //const errorRes = JSON.parse({ result: { Status_id: response.status, Message: response.statusText } });
                //return errorRes;
            }
            let en_data1 = text && JSON.parse(text);
            let en_data = en_data1 ? en_data1.data ? decrypt(en_data1.data) : decrypt(en_data1) : "";
            const data = en_data && JSON.parse(en_data);
            return data;
        });
    } catch (ex) {
        writeLog(ex);
    }
};


// function encrypt(message) {
//     try {
//         return message;
//     }
//     catch (ex) {
//         return message;
//     }

// };

// function decrypt(ciphertext) {
//     try {
//         return ciphertext;
//     } catch (error) {
//         return ciphertext;
//     }
// };

const key = '000502030405060208090A0B050D0E0A';
const iv = 'f0f1f2f3f4f5f6f7f8f9fafbfcfdfcfc';

function encrypt(message) {
    try {
        const keyBytes = CryptoJS.enc.Hex.parse(key);
        const ivBytes = CryptoJS.enc.Hex.parse(iv);
        const encrypted = CryptoJS.AES.encrypt(message, keyBytes, {
            iv: ivBytes,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        let d = encrypted.toString();
        return d;
    }
    catch (ex) {
        // return "";
        return message;
    }

};


function decrypt(ciphertext) {
    try {
        const keyBytes = CryptoJS.enc.Hex.parse(key);
        const ivBytes = CryptoJS.enc.Hex.parse(iv);
        const decrypted = CryptoJS.AES.decrypt(ciphertext, keyBytes, {
            iv: ivBytes,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        return str;
    } catch (error) {
        // return "";
        return ciphertext;
    }


};

function SendFacebookpixel() {
    try {
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
            autoConfig: false, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: true, // enable logs
        };
        ReactPixel.init(process.env.REACT_APP_FB_TRACKING_ID, advancedMatching, options);
        ReactPixel.pageView();

        ReactPixel.init(process.env.REACT_APP_FB_TRACKING_ID2, advancedMatching, options);
        ReactPixel.pageView();

        ReactPixel.init(process.env.REACT_APP_FB_TRACKING_ID3, advancedMatching, options);
        ReactPixel.pageView();
    }
    catch (ex) {
        console.error("SendFacebookpixel", ex)
    }
}


function GetCountry(hbanner) {
    try {
        return fetch(process.env.REACT_APP_CDN_URL + 'assets/countrycode.json' + "?V" + hbanner);
    }
    catch (ex) {
        console.log("setjsondata => " + ex)
    }
};

function SendUserTracking(eventID, uObj) {
    try {
        let eventName = "";
        switch (eventID) {
            //     case 1:
            //         eventName = "purchase_success";
            //         break;
            //     case 2:
            //         eventName = "purchase_failure";
            //         break;
            case 3:
                eventName = "sign_in";
                break;
            case 4:
                eventName = "sign_up";
                break;
            default:
                eventName = "Unknow"
                break;
        }
        // let user = JSON.parse(localStorage.getItem('user'));
        // if (user && user.emailID && user.emailID != "") {
        //     uObj['pk^email'] = user.emailID;
        //     uObj['email'] = user.emailID;
        // }
        // if (user && user.mobileNo && user.mobileNo != "" && user.mobileNo != "0") {
        //     uObj['mobile'] = user.Country_code + user.mobileNo;
        // }

        uObj['EVENT_DATE'] = new Date().toLocaleString();
        window.smartech('contact', '1', uObj);
        //window.smartech('contact', 'LIST IDENTIFIER', uObj);
        window.smartech('identify', uObj.email);
        delete uObj['pk^email'];
        if (uObj.NAME) {
            let _name = uObj['NAME'];
            let _lname = uObj['LAST_NAME'];
            let _nation = uObj["NATIONALITY"];
            delete uObj['NAME'];
            delete uObj['LAST_NAME'];
            delete uObj["NATIONALITY"];
            uObj['name'] = _name;
            uObj['last_name'] = _lname;
            uObj['nationality'] = _nation;
        }
        window.smartech('dispatch', eventName, uObj);
    } catch (ex) {
        console.error("SendUserTracking", ex)
    }
}

function SendEventTracking(eventID, obj, isRegister = false, cartitem) {
    try {
        if (cartitem) {
            let parr = []
            let Gamegid = [...new Set(cartitem.map(item => item.gamegroupID))];
            Gamegid.forEach(ggID => {
                let url = '';
                let imgurl = '';
                let name = '';
                let fcartItems = cartitem.filter(a => a.gamegroupID == ggID);
                switch (parseInt(ggID)) {
                    case 9:
                        url = "/fortune6";
                        imgurl = 'fortune-6-logo.png'
                        name = fcartItems[0].LotName ? fcartItems[0].LotName : "fortune6"
                        break;
                    case 10:
                        url = "/super6";
                        imgurl = 'super-6-logo.png?v0.2'
                        name = fcartItems[0].LotName ? fcartItems[0].LotName : "super6"
                        break;
                    case 11:
                        url = "/magic5";
                        imgurl = 'magic-5-logo.png?v0.2'
                        name = fcartItems[0].LotName ? fcartItems[0].LotName : "magic5"
                        break;
                    case 13:
                        url = "/lucky1";
                        imgurl = 'lucky-1-logo.png'
                        name = fcartItems[0].LotName ? fcartItems[0].LotName : "lucky1"
                        break;
                    case 14:
                        url = "/dream5";
                        imgurl = 'dream-5-logo.png'
                        name = fcartItems[0].LotName ? fcartItems[0].LotName : "dream5"
                        break;
                    case 15:
                        url = "/rush3";
                        imgurl = 'pick-3-logo.png'
                        name = fcartItems[0].LotName ? fcartItems[0].LotName : "Rush3"
                        break;
                    default:
                        url = "Unknow";
                        imgurl = 'Unknow';
                        name = "Unknow"
                        break;
                }
                parr.push({
                    'product_name': name,
                    'prod_url': process.env.REACT_APP_DESKTOP_URL + url,
                    'prod_img_url': process.env.REACT_APP_DESKTOP_URL + '/assets/img/gamesBg/' + imgurl,
                    'prqt': fcartItems ? fcartItems.length : 1,
                    'product_price': fcartItems[0].stakeValue ? fcartItems[0].stakeValue : fcartItems[0].minStake ? fcartItems[0].minStake : fcartItems[0].StakeValue ? fcartItems[0].StakeValue : 0,
                    'prid': ggID + '',
                })
                if ((eventID == 12 || eventID == 13) && fcartItems[0].BetSelection) {
                    parr[0]['VALUE'] = fcartItems[0].BetSelection.join(';');
                }
            })
            obj = {
                'items': parr,
            }
            if (eventID == 15 || eventID == 14 || eventID == 17) { // Checkout , buy_now , Product_Purchase
                obj['Amount'] = cartitem.reduce((a, b) => a = a + b.stakeValue, 0) ? cartitem.reduce((a, b) => a = a + b.stakeValue, 0) : cartitem.reduce((a, b) => a = a + b.StakeValue, 0);
                obj['total_prqt'] = cartitem.length;
            }

        }
        let eventName = "";
        switch (eventID) {
            case 1:
                eventName = "sign_in";
                break;
            case 2:
                eventName = "login_failure";
                break;
            case 3:
                eventName = "registration_step1_success";
                break;
            case 4:
                eventName = "registration_step1_failed";
                break;
            case 5:
                eventName = "registration_otp_success";
                break;
            case 6:
                eventName = "registration_otp_failed";
                break;
            case 7:
                eventName = "sign_up";
                break;
            case 8:
                eventName = "registration_activate_failed";
                break;
            case 9:
                eventName = "Add_To_Cart";
                break;
            case 10:
                eventName = "Remove_From_Cart";
                break;
            case 11:
                eventName = "Product_View";
                break;
            case 12:
                eventName = "Add_To_favorite";
                break;
            case 13:
                eventName = "Remove_From_favorite";
                break;
            case 14:
                eventName = "Checkout";
                break;
            case 15:
                eventName = "product_purchase_new";
                break;
            case 16:
                eventName = "home_page_load";
                break;
            case 17:
                eventName = "buy_now";
                break;
            case 18:
                eventName = "profile_update";
                break;
            case 19:
                eventName = "Product_Purchase_failed";
                break;
            case 20:
                eventName = "Page_Browse";
                break;
            default:
                eventName = "Unknow"
                break;
        }
        let user = decrypt(localStorage.getItem('user'));
        user = JSON.parse(user)
        // let user = localStorage.getItem('user');
        // user = JSON.parse(user);
        if (obj && (!obj.mobile || obj.mobile == '0')) {
            if (user && user.mobileNo) {
                obj['mobile'] = user.Country_code + user.mobileNo;
            }
        }
        else {
            if (isRegister == false) {
                obj['mobile'] = user.Country_code + obj.mobile;
            }
        }

        let _email = obj.emailID;
        if (obj && (!obj.emailID || obj.emailID == "")) {
            if (user && user.emailID) {
                _email = user.emailID;
            }
        }
        if (_email != "") {
            window.smartech('identify', _email);
            obj['email'] = _email;
        }
        obj['EVENT_DATE'] = new Date().toLocaleString();
        window.smartech('dispatch', eventName, obj);
    } catch (ex) {
        console.error("SendEventTracking", ex)
    }
}


const gettoken = async function (mobileNo, langID, sessionKey, Ccode) {
    try {
        // if (Ccode == undefined || Ccode == null) {
        //     let user = JSON.parse(decrypt(localStorage.getItem('user')));
        //     // let user = JSON.parse(localStorage.getItem('user'));
        //     if (user && user.Country_code && user.Country_code != "")
        //         Ccode = user.Country_code;
        //     else
        //         Ccode = '971';//971;
        // }
        let en_mob = encrypt(mobileNo);
        let _option = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'User_Id': en_mob,
                'Channel_Id': process.env.REACT_APP_ChannelId,
                'Version': process.env.REACT_APP_Version,
                'TVN': process.env.REACT_APP_TVN,
                'SNO': sessionKey,
                'Language_Id': langID,
                'Country_Code': Ccode,
                'IP_Address': ''
            }
        };
        const response = await fetch(`${_apiUrl}get_token/`, _option);
        const result = await response.json();
        return result.token;
    }
    catch (e) {
        console.log(e);
    }
}

function GetUserCountry() {
    try {
        // let s = localStorage.getItem('cloc');
        // if (s && s.indexOf('country') >= 0) {
        //     let _mures = new Response(s, {
        //         status: 200,
        //         statusText: "OK",
        //     });
        //     let _mypro = new Promise(function (resolve, reject) {
        //         resolve(_mures);
        //     });
        //     return _mypro;
        // }
        // else {
        return fetch('https://ipv4-check-perf.radar.cloudflare.com/api/info');
        // }
    }
    catch (ex) {
        console.log("GetUserCountry => " + ex)
    }
};


// let clst = [
//     { baseCurrency: "AED", targetCurrency: "AED", currency_value: 1 },
//     { baseCurrency: "AED", targetCurrency: "INR", currency_value: 23.109235 },
//     { baseCurrency: "AED", targetCurrency: "PHP", currency_value: 15.977352 },
//     { baseCurrency: "AED", targetCurrency: "EUR", currency_value: 0.257204 }
// ];

let clst = localStorage.getItem('aedEqualCrnyValue');
if (clst && clst != 'undefined' && clst != null) {
    clst = JSON.parse(clst);
}
else {
    clst = [];
    clst.push({ currency_desc: "AED", currency_value: "1" });
    // let userC = { 'code': 'AED', "symbol": "AED" };
    // localStorage.setItem('setuserCuncy', JSON.stringify(userC))

}
let userCurrency = localStorage.getItem('setuserCuncy');
if (userCurrency && userCurrency != 'undefined' && userCurrency != null) {
    userCurrency = JSON.parse(userCurrency);
}
else {
    userCurrency = { code: "AED", symbol: "AED" };
}
function aedCrnyConvert(cval, hidetwodigit) {
    try {
        if (typeof cval !== 'number') {
            cval = cval.replace(',', '').replace(/ /g, '');
            if (cval.indexOf('#') >= 0) {
                cval = cval.replace('#', '');
                cval = cval * 1000000;
            }
            else if (cval.toLowerCase().indexOf('million') >= 0) {
                cval = cval.toLowerCase().replace('million', '');
                cval = cval * 1000000;
            }
        }
        if (cval == 0) {
            return cval
        }
        let fclst = clst.filter(a => a.currency_desc == userCurrency.code);
        if (fclst && fclst.length > 0) {
            fclst = fclst[0];
        }
        else {
            let userC = { 'code': 'AED', "symbol": "AED"};
            localStorage.setItem('setuserCuncy', JSON.stringify(userC))
            return cval;
        } 
        let ddc = cval;
        if (hidetwodigit == 1 && userCurrency.code == "AED") {
            return ddc;
            // ddc = (parseFloat(cval) * fclst.currency_value).toFixed(2).split('.')[1] > 0 ? (parseFloat(cval) * fclst.currency_value).toFixed(2) : (parseFloat(cval) * fclst.currency_value).toFixed(2).split('.')[0];
        }
        else {
            ddc = (parseFloat(cval) * fclst.currency_value).toFixed(2);
        }
        console.log('ddc ', ddc)
        return ddc;
    } catch (error) {
    }
    return cval;
}

function SetCountryCode() {
    try {
        let Cvalue = localStorage.getItem('setuserCuncy');
        if (Cvalue) {
            Cvalue = JSON.parse(Cvalue)
        }
        Cvalue = (Cvalue && Cvalue.symbol) ? Cvalue.symbol : process.env.REACT_APP_CURRENCY;
        return Cvalue;
    }
    catch (ex) {
        return process.env.REACT_APP_CURRENCY;
    }
}



function LoadCountry() {
    try {
        const CurrencyValue = [
            { 'code': 'AED', "symbol": "AED", "country_code": "971" },
            { 'code': 'THB', "symbol": "฿", "country_code": "66" },
            { 'code': 'IDR', "symbol": "Rp", "country_code": "62" },
            { 'code': 'TET', "symbol": "TET", "country_code": "00" },
            { 'code': 'TEX', "symbol": "TEX", "country_code": '00' },

            // { 'code': 'PHP', "symbol": "₱", "country_code": "63" },
            // { 'code': 'VND', "symbol": "₫", "country_code": "84" },
            // { 'code': 'INR', "symbol": "₹", "country_code": "91" },
            // { 'code': 'MYR', "symbol": "RM", "country_code": "60" },
            // { 'code': 'BRL', "symbol": "R$", "country_code": "55" },
            // { 'code': 'BHD', "symbol": ".د.ب", "country_code": "973" }
            // { 'code': 'OMR', "symbol": "﷼" ,"country_code":"968"},
            // { 'code': 'USD', "symbol": "$" ,"country_code":"00"},
            // { 'code': 'SAR', "symbol": "﷼" ,"country_code":"966"},
        ];
        return CurrencyValue;
    }
    catch (ex) {
        console.log('LoadCountry')
    }
}

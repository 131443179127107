import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class TermsCondition extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		//  window.setAccordian();
	}
	render() {
		const { t } = this.props;
		const { i18n } = this.props;

		return (
			<>
				<div>
				<h2 className="headding3 float-none">Terms and Conditions</h2>
					{/* {i18n.language === 'en' && <h2 className="headding3 float-none">Terms and Conditions</h2>} */}
					{/* {i18n.language === 'ml' && <h2 className="headding3 float-none">ഉപാധികളും നിബന്ധനകളും</h2>} */}
					{/* {i18n.language === 'ar' && <h2 className="headding3 float-none">الأحكام والشروط</h2>} */}
					{/* {i18n.language === 'ur' && <h2 className="headding3 float-none">شرائط و ضوابط</h2>} */}
					{/* {i18n.language === 'en' ? */}
						<div className="mt-5">
							<h3 className="text-base lg:text-xl font-semibold">Terms & Conditions</h3>
							<div className="text-sm">Date of Issue: 31st January 2024</div>

							<p className="mt-5">
								These General Terms and Conditions ("Terms") set out the various rules and
								procedures that apply when You access and use the Platform, create and maintain an
								Account with Us, purchase Our Products on the Platform, and/or Enter Games.
							</p>
							<p className="mt-3">
								By accessing and using the Platform, creating and maintaining an Account with Us,
								purchasing Products on Our Platform, and/or Entering Games, You expressly agree to be
								bound by these Terms. We also have separate Rules and a Privacy Policy and We recommend
								that You read and understand these before accessing the Platform, creating an Account,
								purchasing Products on the Platform and/or Entering Games. Copies of the Rules and
								Privacy Policy can be found on the Gulf Ticket Website and Mobile App, when available.
							</p>
							<p className="mt-3">
								These Terms, Rules and Privacy Policy constitute the entire agreement between You and
								Gulf Ticket in relation to You accessing the Platform, creating an Account, purchasing
								Products and/or Entering Games. The Rules and Privacy Policy are hereby incorporated
								into, and form part of these Terms. By accepting these Terms, You agree to be legally
								bound by the Gulf Ticket Terms, Rules and Privacy Policy.
							</p>
							<p className="mt-3">
								{/* The Platform is owned and operated by Miraki Future Investments and Holdings, a Limited
								Liability Company incorporated in the Emirate of Dubai, UAE.  */}
								Gulf Ticket&apos;s Customer
								Service Centre can be contacted on +971 542473561 or by sending an email to
								support@gulfticket.com.
							</p>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Definitions</h3>
								For the purposes of this Terms & Conditions:
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										Account means a unique account created for You to access our Platform or parts of
										our Platform
									</li>

									<li>
										Application refers to Gulf Ticket App, the software program provided by the Company.
									</li>
									{/* <li>
										Company (referred to as either "the Company", "We", "Us" or "Our" in this Policy)
										refers to Miraki Future Investments and Holding LLC, Dubai, UAE.
									</li> */}
									<li>
										Cookies are small files that are placed on Your computer, mobile device or any other
										device by a website, containing the details of Your browsing history on that website
										among its many uses.
									</li>
									<li>Country refers to: United Arab Emirates</li>
									<li>
										Device means any device that can access the Platform such as a computer, a cell
										phone or a digital tablet.
									</li>
									<li>
										Personal Data is any information that relates to an identified or identifiable
										individual.
									</li>
									<li>Platform refers to the Application or the Website or both.</li>

									<li>
										Service Provider means any natural or legal person who processes the data on behalf
										of the Company. It refers to third-party companies or individuals employed by the
										Company to facilitate the Service, to provide the Service on behalf of the Company,
										to perform services related to the Service or to assist the Company in analyzing how
										the Service is used.
									</li>
									<li>
										Usage Data refers to data collected automatically, either generated by the use of
										the Platform or from the Platform infrastructure itself (for example, the duration
										of a page visit).
									</li>
									<li>Website refers to Gulf Ticket, accessible from www.gulfticket.com</li>
									<li>
										You,means the individual accessing or using the Platform, or the company, or other
										legal entity on behalf of which such individual is accessing or using the Platform,
										as applicable.
									</li>
								</ul>
							</div>
							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Account Registration</h3>
								<h4 className="text-base lg:text-lg font-semibold">Eligibility</h4>
								To play Gulf Ticket, You must be at least 18 years or older & abide by these Terms and
								the Rules; <br />
								Not be a resident of a sanctioned or blocked countries mentioned below :
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>Afghanistan</li>
									<li>Bosnia / Herzegovina</li>
									<li>Belarus</li>
									<li>Burundi</li>
									<li>Central African Republic</li>
									<li>Cuba</li>
									<li>Democratic Republic of Congo</li>
									<li>Democratic People's Republic of Korea</li>
									<li>Ethiopia</li>
									<li>Guinea</li>
									<li>Guinea-Bissau</li>
									<li>Haiti</li>
									<li>Iran</li>
									<li>Libya</li>
									<li>Mali</li>
									<li>Myanmar</li>
									<li>Nicaragua</li>
									<li>Russia</li>
									<li>Somalia</li>
									<li>South Sudan</li>
									<li>Sudan</li>
									<li>Syria</li>
									<li>United States of America</li>
									<li>Ukraine</li>
									<li>Venezuela</li>
									<li>Yemen</li>
									<li>Zimbabwe</li>
								</ul>
							</div>
							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">How to Register</h3>
								For the purposes of this Terms & Conditions:
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										To open your Gulf Ticket account, we'll need some details about you. Please ensure
										every bit is honest, accurate, and always kept up-to-date. Updating your info is a
										quick and easy process within your account unless exceptional circumstances prompt
										us to handle it differently.
									</li>
									<li>
										We may occasionally request additional information to verify what you've provided.
										Rest assured, any verification steps we take are reasonable and meant to keep your
										account secure. All information you share with us falls under the protection of our
										Privacy Policy, which you implicitly agree to by sharing your data. In essence, you
										trust us to hold and process your information responsibly, guided by the principles
										outlined in that policy.
									</li>
								</ul>
							</div>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Account Access & Responsibility</h3>
								For the purposes of this Terms & Conditions:
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										<span className="font-semibold">Accessing Games & Prizes:</span>
										<span
										>Upon opening your account, avail yourself of a complimentary entry with each
											purchase and engage responsibly within legal jurisdictions.</span
										>
									</li>
									<li>
										<span className="font-semibold">Personal Responsibility:</span>
										<span
										>Maintain the confidentiality and security of your password. Take all necessary
											precautions to prevent unauthorized access. Gulf Ticket bears no liability for
											damages or losses resulting from negligence or other lapses in security.</span
										>
									</li>
									<li>
										<span className="font-semibold">Unauthorized Access:</span>
										<span
										>While we commit to investigating suspicious activities, any losses incurred due
											to the unintended disclosure of your password remain the responsibility of the
											account holder.</span
										>
									</li>
									<li>
										<span className="font-semibold">Password Recovery:</span>
										<span
										>Forgot your password? No cause for concern. Simply follow the platform's
											prescribed reset instructions for a seamless recovery process.</span
										>
									</li>
									<li>
										<span className="font-semibold">Participant Designation:</span>
										<span
										>The sole individual officially registered as the Account holder shall be
											recognized as the "Participant." Prizes, in accordance with the Rules, shall be
											disbursed exclusively to the registered Account holder.</span
										>
									</li>
									<li>
										<span className="font-semibold">Syndicate Disputes Disclaimer:</span>
										<span
										>Gulf Ticket assumes no responsibility or liability for any disputes arising from
											Syndicates (Group Purchases).</span
										>
									</li>
								</ul>
							</div>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Purchasing Product/Services</h3>
								<h4 className="text-base lg:text-lg font-semibold">
									Products and/or Services Representation:
								</h4>
								Diligent Efforts: We undertake reasonable measures to ensure that all descriptions and
								graphical representations of Products and/or Services align with the actual delivered
								items.

								<h4 className="text-base font-semibold mt-2">Pricing Integrity:</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										<span className="font-semibold">Price Accuracy:</span>
										<span
										>We diligently strive to ensure the correctness of all prices displayed on the
											Platform at the time of purchase.</span
										>
									</li>
									<li>
										<span className="font-semibold">Pricing Adjustments:</span>
										<span
										>We reserve the right to modify prices as deemed necessary. Any alterations in
											price will not impact previously placed Orders.</span
										>
									</li>
									<li>
										<span className="font-semibold">Pricing Inclusions:</span>
										<span
										>VAT Inclusion: All prices featured on the Platform include VAT, where
											applicable.</span
										>
									</li>
								</ul>

								<h4 className="text-base font-semibold mt-2">Orders Protocol:</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										Order Placement:
										<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
											<li>
												Sequential Guidance: The Platform will systematically lead you through the
												online ordering process.
											</li>
											<li>
												Order Review: Before submission, you will have the opportunity to review and
												amend your Order. Once processed, no changes are permissible.
											</li>
										</ul>
									</li>
									<li>
										Contractual Offer:
										<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
											<li>
												No General Right: No general right exists for an individual to purchase a
												Product. Your Order serves as a contractual offer subject to Our discretionary
												acceptance.
											</li>
											<li>
												Acceptance Confirmation: Our acceptance is affirmed by sending an Order
												Confirmation via email, constituting a binding Contract between Us and You.
											</li>
										</ul>
									</li>
								</ul>
								<h4 className="text-base font-semibold mt-2">Payment Terms:</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										Advance Payment: Payment for Products and/or Services and applicable delivery
										charges must be made in advance.
									</li>

									<li>
										Currency and Pricing: Prices will be presented in UAE Dirhams (AED), with the final
										amount calculated based on the exchange rate on the day of your card issuer's
										transaction processing.
										 {/* Note that "Miraki Future Investments and Holding LLC-FZ"
										will be reflected on your bank or credit card statement. */}
									</li>
								</ul>
							</div>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">
									Termination, Variation, or Suspension of Your Account:
								</h3>
								We reserve the right, at our absolute discretion, to terminate, vary, or suspend access
								to Your Account, the Platform, purchase of Credit, purchase of Products, Entry into a
								Draw, or Your ability to make a withdrawal or transfer from Your Winnings Balance. This
								may occur immediately and without prior notification if:
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										You register or operate more than one (1) Account simultaneously (in such cases, We
										reserve the right to terminate, vary, or suspend some or all of those Accounts).
									</li>
									<li>
										Any information provided to Us is found to be fraudulent, false, inaccurate,
										incomplete, or outdated.
									</li>
									<li>
										You participate in a Draw while located in a jurisdiction where such Entry is
										unlawful.
									</li>
									<li>Essential repair, maintenance, or upgrades on the Platform are required.</li>
									<li>
										We cease to provide Gulf Ticket or other functionalities such as Accounts or access
										to the Platform for any reason.
									</li>
									<li>Identity and/or financial verification and security checks are in progress.</li>
									<li>We, in our sole discretion, deem it necessary.</li>
								</ul>
							</div>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Time-Out and Self-Exclusion</h3>
								<h4 className="text-base font-semibold">Limiting Account Activity</h4>
								If you wish to restrict your ability to add Credit, purchase Products, or Enter a Draw
								for a specified period, Gulf Ticket provides options for a short-term time-out or a more
								extended self-exclusion.

								<h4 className="text-base font-semibold mt-2">Short-term Time-out:</h4>
								Choose a time-out exclusion for a period between one (1) week and six (6) weeks. During
								this period, you cannot add Credit, purchase Products, or Enter a Draw. However, you can
								still withdraw Prizes from Your Winnings Balance.
								<h4 className="text-base font-semibold mt-2">Self-Exclusion:</h4>
								Opt for a longer self-exclusion period starting from six (6) months, including a
								permanent exclusion. <br />Throughout the self-exclusion period, you cannot add Credit,
								purchase Products, or Enter a Draw. Withdrawals of Prizes from Your Winnings Balance
								remain accessible. Please note that once a time-out or self-exclusion request is
								submitted, changes or withdrawals to the request are not allowed.

								<h4 className="text-base font-semibold mt-3">Dormant Accounts</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										If you do not log into or use your Account for a continuous period of twelve (12)
										months, it will be classified as a "Dormant Account.
									</li>
									<li>
										In the event of dormancy, any balance in your Credit Balance will be forfeited, and
										the balance in Your Winnings Balance will be remitted to the Department of Economic
										Development.
									</li>
									<li>
										To reactivate a dormant Account, you may contact Customer Support at any time.
										Reactivation requests are subject to additional security and verification
										procedures. No refund of forfeited funds will be provided.
									</li>
								</ul>

								<h4 className="text-base font-semibold mt-3">Closing Your Account</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										If you decide to close your Account, you can do so at any time by accessing the
										&quot;My Account&quot; section of the Online Services. Closing your Account will
										result in the removal of your information, including the deletion of any Favorites
										you have created. It will also revoke access to any areas of the Platform requiring
										an Account for entry. Please note that your personal data will be stored in
										accordance with the terms of the Privacy Policy.
									</li>
									<li>
										Closing your Account will render any Entries for future Draws void. You will forfeit
										any remaining Credit in your Credit Balance and/or Prizes credited to your Winnings
										Balance upon the termination of your Account. Any funds in your Winnings Balance
										will be remitted to the Department of Economic Development.
									</li>
								</ul>
							</div>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Prize Transfer and Withdrawals</h3>
								All winning prizes will be disbursed to your wallet in United Arab Emirates DIrhams
								(AED) based on our procedure of validation. The Prize money will be credited in full and
								not in installments.

								<h4 className="text-base lg:text-lg font-semibold mt-2">Withdrawal Procedure:</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>You can withdraw your available Balance at any time.</li>
									<li>Minimum transfer amount is AED 200.</li>
									<li>Each withdrawal request must encompass the total available funds.</li>
									<li>
										Withdrawals will be executed via bank wire transfer or authorized Financial
										Institution.
									</li>
								</ul>

								<h4 className="text-base lg:text-lg font-semibold mt-3">Additional Charges:</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										Administration charges may apply to withdrawals at Financial Institutions outside
										the UAE.
									</li>
									<li>
										We are not liable for any additional charges, including foreign exchange charges.
									</li>
								</ul>

								<h4 className="text-base lg:text-lg font-semibold mt-3">Taxation on Winning Prizes:</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										It&apos;s important to note that in the United Arab Emirates (UAE), winnings from
										Gulf Ticket are tax-free. However, taxation on winning prizes will be as per the
										applicable laws in the winner's respective countries. Winners are responsible for
										understanding and fulfilling any tax obligations in their own jurisdictions.
									</li>
								</ul>
								<h4 className="text-base lg:text-lg font-semibold mt-3">Account Restrictions:</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>Prizes cannot be transferred between Accounts.</li>
								</ul>

								<h4 className="text-base lg:text-lg font-semibold mt-3">Payment Modes:</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>Payments are not processed via cheque, postal order, or similar.</li>
								</ul>

								<h4 className="text-base lg:text-lg font-semibold mt-3">Fund Management:</h4>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										All Winnings Balance funds are held in separate dedicated accounts, independently
										audited.
									</li>
									<li>Dedicated account is distinct from the Manager’s other operating funds.</li>
									<li>In cases of insolvency, these funds remain protected.</li>
								</ul>
							</div>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Platform Access and Use</h3>
								<p>
									Access to the Platform is provided free of charge, and you are responsible for
									ensuring your device compatibility. The Platform is offered "as is" and "as
									available," and we disclaim all warranties. We reserve the right to alter, suspend, or
									discontinue the Platform at any time without notice.
								</p>
								<p className="mt-3">
									You must comply with local laws, regulations, and ensure lawful use of your Account
									and the Platform. We are not liable for Entries made from unlawful jurisdictions, and
									you agree to indemnify us for any wrongful use. The Content on the Platform is
									protected by intellectual property laws, and you may not reproduce or reuse it without
									explicit permission. Unauthorized reverse engineering or decompiling of associated
									software is prohibited.
								</p>
							</div>
							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">
									Responsible Online Raffle draw Practices
								</h3>
								<p>
									Gulf Ticket is committed to responsible gaming. We encourage our members to play
									responsibly and set limits on their spending. If you have any problems with
									overspending, please contact the Gulf Ticket customer support team for help.
								</p>
								<p className="mt-3">
									Our commitment to responsible online raffle draw practices is evident in the stringent
									measures we've implemented to ensure the protection of players. We adhere to age
									restrictions diligently, displaying clear signage on our website, incorporating age
									controls during registration, and strictly prohibiting participation for individuals
									below the legal age for online raffle draw. Our advertising practices avoid targeting
									minors, and we provide resources such as links to filtering programs to prevent
									underage access.
								</p>
								<p className="mt-3">
									Moreover, we prioritize the well-being of our players by offering tools for
									self-control. Players can set spending limits voluntarily, take mandatory breaks
									during play, and utilize features for self-exclusion. Our platform promotes
									transparency in players' online behaviors, with visible betting histories and
									accessible information on probabilities, payout ratios, and game structures. Our
									dedicated staff undergoes responsible online raffle draw training, ensuring they are
									equipped to assist players responsibly. We continuously evaluate and audit our
									strategies to maintain a secure and socially responsible online raffle draw
									environment.
								</p>
							</div>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Links to Other Websites</h3>
								<p>
									Links to external third-party sites may be provided on the Online Services and other
									communications sent by us. Unless expressly stated, these sites are beyond our
									control. We neither assume nor accept responsibility or liability for the content of
									third-party sites. The inclusion of a link to another site on the Online Services or
									other communication is for informational purposes only and does not imply any
									endorsement of the sites themselves or of those in control of them.
								</p>
							</div>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Disclaimer of Warranty and Liability</h3>
								<p>
									The information on the Platform is provided for general information purposes only, and
									we make no warranty, representation, or guarantee regarding its completeness,
									accuracy, or security. Your use of the Platform is at your sole risk, and we do not
									guarantee uninterrupted or error-free access.
								</p>
								<p className="mt-3">
									We direct our content only to eligible participants and disclaim responsibility for
									any interruptions, access delays, data issues, losses, or events beyond our control.
									By using the Platform, you agree to these terms and acknowledge that neither we nor
									our affiliates are liable for any such issues or inaccuracies in the content.
								</p>
							</div>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Limitation of Liability</h3>
								<p>
									We shall not be liable for any loss or damage, foreseeable or otherwise, resulting
									from your use of the Platform. This includes issues related to account registration,
									product purchase attempts, game entries, or any platform failures, even if you've
									previously alerted us to potential losses.
								</p>
								<p className="mt-3">
									We also disclaim liability for losses arising from events beyond our control, system
									failures, mistakes, delays, refusals, or losses caused by your actions or technical
									issues. To the fullest extent permitted by law, we exclude all representations,
									warranties, and guarantees. However, this exclusion does not apply to instances of
									fraud, personal injury due to negligence, or other liabilities that cannot be legally
									restricted.
								</p>
							</div>

							<div className="mt-3 lg:mt-7">
								<h3 className="text-base lg:text-xl font-semibold">Additional Terms and Conditions</h3>
								<ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7 mt-2">
									<li>
										Gulf Ticket reserves the right to cancel or suspend any Gulf Ticket Draw at any
										time.
									</li>
									<li>
										Gulf Ticket reserves the right to disqualify any player from playing the Draw or
										claiming their winnings if they violate these terms and conditions or if they engage
										in any fraudulent or illegal activity.
									</li>
									<li>
										Gulf Ticket is committed to protecting the privacy of its players. Please review our
										Privacy Policy for more information about how we collect, use, and share your
										personal information.
									</li>
								</ul>
								<p className="mt-3">
									Gulf Ticket reserves the right to change these terms and conditions at any time. If we
									make any changes, we will post the updated terms and conditions on our website.
								</p>
							</div>
						</div>
						{/* : i18n.language === 'ml' ?
							<div className="addlPage scrollbar">
								<div className="addlpageCont">
									<p>
										ഓൺലൈൻ ഡ്രോ ഗെയിം വെബ്സൈറ്റായ ഗൾഫ് ടിക്കറ്റിലേക്ക് സ്വാഗതം, അവിടെ നിങ്ങൾക്ക് യഥാക്രമം 10 ദിർഹത്തിനും 30 ദിർഹത്തിനും വാട്ടർ ബോട്ടിലുകൾ വാങ്ങാനും ഫോർച്യൂൺ 5, സൂപ്പർ 6 എന്നിവയുടെ നറുക്കെടുപ്പ് ടിക്കറ്റുകൾ നേടാനും കഴിയും.
									</p>
									<p>
										ഗൾഫ് ടിക്കറ്റ് ഉപയോഗിക്കുന്നതിലൂടെ, ഇനിപ്പറയുന്ന നിബന്ധനകളും വ്യവസ്ഥകളും നിങ്ങൾ സമ്മതിക്കുന്നു:
									</p>
									<ul className="faqAccordian">
										<li>
											<a >യോഗ്യത</a>
											<p>ഗൾഫ് ടിക്കറ്റ് കളിക്കാൻ, നിങ്ങളുടെ രാജ്യത്തെ നിയമപരമായ പ്രായം അനുസരിച്ച് നിങ്ങൾക്ക് കുറഞ്ഞത് 18 വയസ്സോ പ്രായപൂർത്തിയായവരോ ആയിരിക്കണം.</p>
										</li>
										<li>
											<a >അക്കൗണ്ട് സൃഷ്ടിക്കുകയും ടിക്കറ്റ് വാങ്ങൽ എടുക്കുകയും ചെയ്യുക</a>
											<p>ഗൾഫ് ടിക്കറ്റ് കളിക്കാൻ നിങ്ങൾ ഒരു അക്കൗണ്ട് സൃഷ്ടിക്കുകയും ഒരു നറുക്കെടുപ്പ് ടിക്കറ്റ് വാങ്ങുകയും വേണം.</p>
											<p>നിങ്ങളുടെ അക്കൗണ്ട് വിവരങ്ങൾ കൃത്യവും കാലികവുമാണെന്ന് ഉറപ്പാക്കുന്നതിനുള്ള ഉത്തരവാദിത്തം നിങ്ങൾക്കാണ്.</p>
											<p>കൃത്യമല്ലാത്തതോ കാലഹരണപ്പെട്ടതോ ആയ അക്കൗണ്ട് വിവരങ്ങളുടെ ഫലമായി ഉണ്ടാകുന്ന ഏതെങ്കിലും നഷ്ടത്തിനോ നാശനഷ്ടങ്ങൾക്കോ ഗൾഫ് ടിക്കറ്റ് ഉത്തരവാദിയല്ല.</p>
											<p>നിങ്ങൾ കൃത്യമല്ലാത്തതോ തെറ്റിദ്ധരിപ്പിക്കുന്നതോ ആയ വിവരങ്ങൾ നൽകുകയാണെങ്കിൽ നിങ്ങളുടെ അക്കൗണ്ട് താൽക്കാലികമായി പ്രവർത്തനരഹിതമാക്കാനോ, അവസാനിപ്പിക്കാനോ ഉള്ള അവകാശം ഗൾഫ് ടിക്കറ്റിൽ നിക്ഷിപ്തമാണ്.</p>
										</li>
										<li>
											<a >പേയ്മെന്റ് രീതികൾ</a>
											<p>നറുക്കെടുപ്പ് ടിക്കറ്റ് വാങ്ങലുകൾക്കായി ഗൾഫ് ടിക്കറ്റ് ഇനിപ്പറയുന്ന പേയ്മെന്റ് രീതികൾ സ്വീകരിക്കുന്നു:</p>
											<ul>
												<li>
													<p>ക്രെഡിറ്റ് കാർഡ്</p>
												</li>
												<li>
													<p>ഡെബിറ്റ് കാർഡ്</p>
												</li>
												<li>
													<p>നെറ്റ് ബാങ്കിംഗ്</p>
												</li>
												<li>
													<p>മൊബൈൽ വാലറ്റ്</p>
												</li>
											</ul>
										</li>
										<li>
											<a >ഗൾഫ് ടിക്കറ്റ് നറുക്കെടുപ്പും വിജയ ടിക്കറ്റുകളും</a>
											<p>ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റിൽ പ്രഖ്യാപിച്ചതുപോലെ ഗൾഫ് ടിക്കറ്റ് നറുക്കെടുപ്പ് പതിവായി നടക്കുന്നു.</p>
											<p>റാഫിൾ വിജയ ടിക്കറ്റുകൾ ഒരു യാദൃശ്ചികമായ നമ്പർ ജനറേറ്റർ വഴി നിർണ്ണയിക്കപ്പെടുന്നു.</p>
											<p>നിങ്ങൾ കളിക്കുന്ന നറുക്കെടുപ്പ് ഗെയിമിനെ ആശ്രയിച്ച് വിജയിക്കാനുള്ള സാധ്യതകൾ വ്യത്യാസപ്പെടുന്നു.</p>
										</li>
										<li>
											<a >ഫലങ്ങൾ</a>
											<p>ഓരോ നറുക്കെടുപ്പിനും വിജയികളായ നമ്പറുകൾ വെബ് സൈറ്റിൽ ലഭ്യമാക്കുകയും ഓരോ നറുക്കെടുപ്പിന് ശേഷവും ഉപഭോക്തൃ പിന്തുണയിലേക്ക് വിളിക്കുകയും ചെയ്യും.. വിജയിക്കുന്ന സംഖ്യകൾ പരസ്യപ്പെടുത്തുന്നതിൽ ഞങ്ങളുടെ ഭാഗത്തുനിന്നുണ്ടായ ഏതെങ്കിലും പരാജയത്തിനോ അല്ലെങ്കിൽ വിജയിച്ച എൻട്രികളുടെ ഉടമകളെ അറിയിക്കുന്നതിൽ പരാജയപ്പെട്ടതിനോ നിങ്ങൾക്ക് നഷ്ടപരിഹാരത്തിന് ക്ലെയിം ഉണ്ടായിരിക്കില്ല; വിജയിക്കുന്ന നമ്പറുകൾക്കെതിരെ നിങ്ങളുടെ എൻട്രികൾ പരിശോധിക്കേണ്ടത് നിങ്ങളുടെ മാത്രം ഉത്തരവാദിത്തമാണ്.</p>
											<p>നഷ്ടപരിഹാരത്തിന് ക്ലെയിം ചെയ്യാൻ നിങ്ങൾക്ക് അർഹതയില്ല, കൂടാതെ ടൈപ്പോഗ്രാഫിക്കൽ, പ്രിന്റിംഗ് അല്ലെങ്കിൽ മറ്റേതെങ്കിലും തരത്തിലുള്ള ഏതെങ്കിലും പ്രഖ്യാപനത്തിലോ പ്രസിദ്ധീകരണത്തിലോ അല്ലെങ്കിൽ നറുക്കെടുപ്പിലെ സമ്മാനങ്ങളുടെ തുകയുടെ ഏതെങ്കിലും പ്രസിദ്ധീകരണത്തിലോ പ്രഖ്യാപനത്തിലോ അടങ്ങിയിരിക്കുന്ന പിശകുകൾക്ക് ഞങ്ങൾ ബാധ്യസ്ഥരല്ല (സമ്മാനങ്ങളിൽ അടയ്ക്കേണ്ട തുക അന്തിമമാക്കുന്നതിന് മുമ്പ് പ്രഖ്യാപനമോ പ്രസിദ്ധീകരണമോ നടത്തുന്ന സ്ഥലം ഉൾപ്പെടെ, പരിധിയില്ലാതെ)</p>
											<p>വെബ്സൈറ്റിൽ പ്രസിദ്ധീകരിച്ച വിജയ നമ്പറുകളും / അല്ലെങ്കിൽ മറ്റ് പ്രഖ്യാപനമോ പ്രസിദ്ധീകരണമോ സെൻട്രൽ കമ്പ്യൂട്ടർ സിസ്റ്റത്തിൽ അടങ്ങിയിരിക്കുന്ന ഔദ്യോഗിക വിജയ നമ്പറുകളും തമ്മിൽ പൊരുത്തക്കേട് ഉണ്ടെങ്കിൽ, രണ്ടാമത്തേത് നിലനിൽക്കും.</p>
											<p>വിജയികളായ എൻട്രികളുടെ അന്തിമ പരിശോധിച്ച എണ്ണം താൽക്കാലിക വിജയികളായ എൻട്രികളുടെ പ്രാരംഭ പരസ്യപ്പെടുത്തിയ എണ്ണത്തേക്കാൾ വ്യത്യസ്തമാണെങ്കിൽ, അത്തരം വിജയികൾക്ക് നൽകേണ്ട സമ്മാനത്തുക വ്യത്യാസപ്പെടാം.</p>
										</li>
										<li>
											<a >സമ്മാനം നേടുക</a>
											<a >റാഫിൾ നറുക്കെടുപ്പ്</a>
											<p>ഓരോ റാഫിൾ നറുക്കെടുപ്പിനും കുറഞ്ഞത് മൂന്ന് (3) വിജയികളായ റാഫിൾ ഐഡികൾ ഉണ്ടായിരിക്കും.</p>
											<p>വിജയികളായ റാഫിൾ ഐഡികളുമായി പൊരുത്തപ്പെടുന്ന റാഫിൾ ഐഡികൾ ഉള്ള പങ്കാളികൾക്ക് ഓരോരുത്തർക്കും 100,000 ദിർഹം (ഒരു ലക്ഷം ദിർഹം) അല്ലെങ്കിൽ ഞങ്ങളുടെ സ്വന്തം വിവേചനാധികാരത്തിൽ ഞങ്ങൾ നിർണ്ണയിക്കുകയും പ്രഖ്യാപിക്കുകയും ചെയ്യുന്ന മറ്റ് തുക ലഭിക്കും.
												ഏതൊരു റാഫിൾ സമ്മാനവും റാഫിൾ സമ്മാനം നേടിയ തീയതിയുടെ 60 കലണ്ടർ ദിവസത്തിനുള്ളിൽ ക്ലെയിം ചെയ്യണം. നിങ്ങൾ യുഎഇക്ക് പുറത്താണ് താമസിക്കുന്നതെങ്കിൽ, റാഫിൾ സമ്മാനം നേടുന്നതിന് യുഎഇയിൽ പങ്കെടുക്കാൻ നിങ്ങളോട് ആവശ്യപ്പെട്ടേക്കാം.
												ഞങ്ങൾ വിജ്ഞാപനം ചെയ്യുന്ന പബ്ലിക് റിലേഷൻസ്, മീഡിയ പ്രവർത്തനങ്ങൾ എന്നിവയിൽ നിങ്ങൾ പങ്കെടുക്കേണ്ടതുണ്ട്.
											</p>
										</li>
										<li>
											<a >വൻ നറുക്കെടുപ്പ്</a>
											<ul>
												<li>
													<p>എല്ലാ സമ്മാനങ്ങളും യുഎഇ ദിർഹത്തിൽ (എഇഡി) ആണ്</p>
													<p>മുൻകൂർ അറിയിപ്പ് കൂടാതെ ഈ നിബന്ധനകളും വ്യവസ്ഥകളും ഭേദഗതി ചെയ്യാനുള്ള അവകാശം ഡ്രോ മാനേജർക്ക് നിക്ഷിപ്തമാണ്.</p>
													<p>നറുക്കെടുപ്പിന്റെ മാനേജർക്ക് മാച്ച് പ്രൈസ് മാറ്റാനുള്ള വിവേചനാധികാരമുണ്ട്. മാച്ച് നാല് വിജയിക്കുന്ന എല്ലാ മത്സരാർത്ഥികൾക്കും മാച്ച് പ്രൈസ് തുല്യമായി വിഭജിക്കും.</p>
													<p>ബാധകമായ നിയമങ്ങൾ അനുസരിച്ച് സമ്മാനങ്ങൾ നികുതികൾക്ക് വിധേയമാണ്.</p>
												</li>
											</ul>
										</li>
										<li>
											<a >ഫോർച്യൂൺ 5</a>
											<p></p>
											<ul>
												<li>
													<p>മത്സരം രണ്ട് - നറുക്കെടുപ്പ് വിജയ സംഖ്യകളിൽ നിന്ന് ഏതെങ്കിലും ഒരു (1) ലൈനിലെ രണ്ട് (2) സംഖ്യകൾ പങ്കാളി ശരിയായി പൊരുത്തപ്പെടുത്തിയിട്ടുണ്ട്.</p>
												</li>
												<li>
													<p>മത്സരം മൂന്ന് - പങ്കെടുക്കുന്നയാൾ ഏതെങ്കിലും ഒരു (1) ലൈനിൽ നിന്ന് മൂന്ന് (3) സംഖ്യകൾ ശരിയായി പൊരുത്തപ്പെടുത്തിയിട്ടുണ്ട്;</p>
												</li>
												<li>
													<p>മത്സരം നാല് - നറുക്കെടുപ്പ് വിജയ സംഖ്യകളിൽ നിന്ന് ഏതെങ്കിലും ഒരു (1) ലൈനിലെ നാല് (4) സംഖ്യകൾ പങ്കാളി ശരിയായി പൊരുത്തപ്പെടുത്തിയിട്ടുണ്ട്; കൂടാതെ</p>
												</li>
												<li>
													<p>മത്സരം അഞ്ച് - ഗ്രാൻഡ് ഡ്രോ വിജയ നമ്പറുകളിൽ നിന്ന് ഏതെങ്കിലും ഒരു (1) ലൈനിലെ അഞ്ച് (5) നമ്പറുകളും പങ്കാളി ശരിയായി പൊരുത്തപ്പെടുത്തിയിട്ടുണ്ട്.</p>
												</li>
											</ul>
										</li>
										<li>
											<a >സൂപ്പർ 6</a>
											<ul>
												<li>
													<p>മത്സരം രണ്ട് - നറുക്കെടുപ്പ് വിജയ സംഖ്യകളിൽ നിന്ന് ഏതെങ്കിലും ഒരു (1) ലൈനിലെ രണ്ട് (2) സംഖ്യകൾ പങ്കാളി ശരിയായി മാച്ച് ചെയ്തിട്ടുണ്ട്.</p>
												</li>
												<li>
													<p>മത്സരം മൂന്ന് - പങ്കെടുക്കുന്നയാൾ ഏതെങ്കിലും ഒരു (1) ലൈനിൽ നിന്ന് മൂന്ന് (3) സംഖ്യകൾ ശരിയായി മാച്ച് ചെയ്തിട്ടുണ്ട്;</p>
												</li>
												<li>
													<p>മത്സരം നാല് - നറുക്കെടുപ്പ് വിജയ സംഖ്യകളിൽ നിന്ന് ഏതെങ്കിലും ഒരു (1) ലൈനിലെ നാല് (4) സംഖ്യകൾ പങ്കാളി ശരിയായി മാച്ച് ചെയ്തിട്ടുണ്ട്; കൂടാതെ</p>
												</li>
												<li>
													<p>അഞ്ചാം മത്സരം - രണ്ടാം നറുക്കെടുപ്പ് വിജയ സംഖ്യകളിൽ നിന്ന് ഏതെങ്കിലും ഒരു (1) ലൈനിലെ അഞ്ച് (5) നമ്പറുകളും പങ്കാളി ശരിയായി മാച്ച് ചെയ്തിട്ടുണ്ട്.</p>
												</li>
												<li>
													<p>മാച്ച് ആറ് - ഗ്രാൻഡ് ഡ്രോ വിജയ നമ്പറുകളിൽ നിന്ന് ഏതെങ്കിലും ഒരു (1) ലൈനിലെ അഞ്ച് (6) നമ്പറുകളും പങ്കാളി ശരിയായി മാച്ച് ചെയ്തിട്ടുണ്ട്.</p>
												</li>
											</ul>
											<p>ഫോർച്യൂൺ 5 ന് അഞ്ച് മത്സരങ്ങൾക്ക് ഗ്രാൻഡ് നറുക്കെടുപ്പിന് 1,000,000 ദിർഹം (ഒരു ദശലക്ഷം ദിർഹം) സമ്മാനം ലഭിക്കും.</p>
											<p>സൂപ്പർ 6 മത്സരങ്ങൾക്ക് ഒരു ഗ്രാൻഡ് നറുക്കെടുപ്പിന് 100,000,000 ദിർഹം (100 മില്യൺ ദിർഹം) സമ്മാനമായി ലഭിക്കും.</p>
										</li>
										<li>
											<a >വിജയങ്ങൾ ക്ലെയിം ചെയ്യുന്നത്</a>
											<p>നറുക്കെടുപ്പ് ജയിച്ച് 30 ദിവസത്തിനുള്ളിൽ നിങ്ങൾ നിങ്ങളുടെ വിജയങ്ങൾ ക്ലെയിം ചെയ്യണം. ക്ലെയിം ചെയ്യാത്ത വിജയികൾ ഗൾഫ് ടിക്കറ്റിന് നഷ്ടമാകും. നിങ്ങളുടെ വിജയങ്ങൾ ക്ലെയിം ചെയ്യുന്നതിന്, നിങ്ങളുടെ ഗൾഫ് ടിക്കറ്റ് അക്കൗണ്ടിലേക്ക് ലോഗിൻ ചെയ്ത് ഒരു ക്ലെയിം ഫോം സമർപ്പിക്കണം. ഗൾഫ് ടിക്കറ്റ് നിങ്ങളുടെ ക്ലെയിം പരിശോധിക്കുകയും 7 പ്രവൃത്തി ദിവസത്തിനുള്ളിൽ നിങ്ങളുടെ അക്കൗണ്ടിലേക്ക് നിങ്ങളുടെ വിജയങ്ങൾ നൽകുകയും ചെയ്യും. 100,000 ദിർഹത്തിന് മുകളിലുള്ള വിജയികൾക്ക് ബാങ്ക് ട്രാൻസ്ഫർ വഴി നൽകും. 100,000 ദിർഹത്തിൽ താഴെയുള്ള വിജയികൾക്ക് ബാങ്ക് ട്രാൻസ്ഫർ, ചെക്ക് വഴി പണം ലഭിക്കും.</p>
										</li>
										<li>
											<a >നികുതികൾ</a>
											<p>ഗൾഫ് ടിക്കറ്റിൽ നിന്നുള്ള എല്ലാ വിജയങ്ങളും യുഎഇ നികുതി നിയമങ്ങൾക്ക് വിധേയമാണ്. സർക്കാർ ചട്ടങ്ങൾ അനുസരിച്ച് നിങ്ങളുടെ രാജ്യത്ത് ഒരു അധിക നികുതിയും ബാധകമായേക്കാം.</p>
											<p>ഗൾഫ് ടിക്കറ്റ് നിങ്ങൾക്ക് നൽകുന്നതിനുമുമ്പ് നിങ്ങളുടെ വിജയങ്ങളിൽ നിന്ന് ബാധകമായ നികുതി പിടിച്ചുവയ്ക്കും.</p>
										</li>
										<li>
											<a >ഉത്തരവാദിത്തമുള്ള ഗെയിമിംഗ്</a>
											<p>ഉത്തരവാദിത്തമുള്ള ഗെയിമിംഗിന് ഗൾഫ് ടിക്കറ്റ് പ്രതിജ്ഞാബദ്ധമാണ്. ഉത്തരവാദിത്തത്തോടെ കളിക്കാനും അവരുടെ ചെലവിന് പരിധി നിശ്ചയിക്കാനും ഞങ്ങൾ അംഗങ്ങളെ പ്രോത്സാഹിപ്പിക്കുന്നു.</p>
											<p>ഉത്തരവാദിത്തമുള്ള ഗെയിമിംഗിന് ഗൾഫ് ടിക്കറ്റ് പ്രതിജ്ഞാബദ്ധമാണ്. ഉത്തരവാദിത്തത്തോടെ കളിക്കാനും അവരുടെ ചെലവിന് പരിധി നിശ്ചയിക്കാനും ഞങ്ങൾ അംഗങ്ങളെ പ്രോത്സാഹിപ്പിക്കുന്നു.</p>
										</li>
										<li>
											<a >ബൗദ്ധിക സ്വത്തവകാശം</a>
											<p>ഗൾഫ് ടിക്കറ്റ് ലോഗോ, വ്യാപാരമുദ്രകൾ, പകർപ്പവകാശം എന്നിവയുൾപ്പെടെ ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റിലെ എല്ലാ ഉള്ളടക്കവും ഗൾഫ് ടിക്കറ്റിന്റെ സ്വത്താണ്.</p>
											<p>ഗൾഫ് ടിക്കറ്റിന്റെ രേഖാമൂലമുള്ള അനുമതിയില്ലാതെ ഏതെങ്കിലും ഗൾഫ് ടിക്കറ്റ് ബൗദ്ധിക സ്വത്ത് ഉപയോഗിക്കാൻ നിങ്ങൾക്ക് അനുവാദമില്ല.</p>
										</li>
										<li>
											<a >ഭരണ നിയമവും തർക്ക പരിഹാരവും</a>
											<p>ഈ നിബന്ധനകളും വ്യവസ്ഥകളും യുഎഇയിലെ നിയമങ്ങൾക്കനുസൃതമായി നിയന്ത്രിക്കുകയും വ്യാഖ്യാനിക്കുകയും ചെയ്യുന്നു. ഈ നിബന്ധനകളും വ്യവസ്ഥകളും മൂലം ഉണ്ടാകുന്ന ഏതൊരു തർക്കവും യുഎഇയിലെ കോടതികൾ പരിഹരിക്കും.</p>
										</li>
										<li>
											<a >വ്യവസ്ഥകളിലും നിബന്ധനകളിലും വരുത്തിയ മാറ്റങ്ങൾ</a>
											<p>ഏത് സമയത്തും ഈ നിബന്ധനകളും വ്യവസ്ഥകളും മാറ്റാനുള്ള അവകാശം ഗൾഫ് ടിക്കറ്റിൽ നിക്ഷിപ്തമാണ്. ഞങ്ങൾ എന്തെങ്കിലും മാറ്റങ്ങൾ വരുത്തുകയാണെങ്കിൽ, അപ്ഡേറ്റുചെയ്ത നിബന്ധനകളും വ്യവസ്ഥകളും ഞങ്ങളുടെ വെബ് സൈറ്റിൽ പോസ്റ്റുചെയ്യും.</p>
										</li>
										<li>
											<a >ഞങ്ങളെ ബന്ധപ്പെടുക</a>
											<p>ഈ നിബന്ധനകളെയും വ്യവസ്ഥകളെയും കുറിച്ച് നിങ്ങൾക്ക് എന്തെങ്കിലും ചോദ്യങ്ങൾ ഉണ്ടെങ്കിൽ, ദയവായി ഞങ്ങളെ ബന്ധപ്പെടുക [support@gulfticket.com]</p>
											<p>ഗൾഫ് ടിക്കറ്റ് കളിച്ചതിന് നന്ദി!</p>
										</li>
										<li>
											<a >അധിക നിബന്ധനകളും വ്യവസ്ഥകളും</a>
											<p>നറുക്കെടുപ്പ് കളിക്കുന്നതിൽ നിന്നോ നിങ്ങളുടെ വിജയങ്ങൾ അവകാശപ്പെടുന്നതിൽ നിന്നോ നിങ്ങളെ തടഞ്ഞേക്കാവുന്ന ഏതെങ്കിലും സാങ്കേതിക ബുദ്ധിമുട്ടുകൾക്ക് ഗൾഫ് ടിക്കറ്റ് ഉത്തരവാദിയല്ല.</p>
											<p>ഏത് സമയത്തും ഏതെങ്കിലും ഗൾഫ് ടിക്കറ്റ് നറുക്കെടുപ്പ് റദ്ദാക്കാനോ താൽക്കാലികമായി നിർത്താനോ ഉള്ള അവകാശം ഗൾഫ് ടിക്കറ്റിൽ നിക്ഷിപ്തമാണ്.</p>
											<p>ഈ നിബന്ധനകളും വ്യവസ്ഥകളും ലംഘിക്കുകയോ ഏതെങ്കിലും വഞ്ചനാപരമോ നിയമവിരുദ്ധമോ ആയ പ്രവർത്തനങ്ങളിൽ ഏർപ്പെടുകയോ ചെയ്താൽ ഏതെങ്കിലും കളിക്കാരനെ നറുക്കെടുപ്പ് കളിക്കുന്നതിൽ നിന്നോ അവരുടെ വിജയങ്ങൾ അവകാശപ്പെടുന്നതിൽ നിന്നോ അയോഗ്യരാക്കാനുള്ള അവകാശം ഗൾഫ് ടിക്കറ്റിൽ നിക്ഷിപ്തമാണ്.</p>
											<p>കളിക്കാരുടെ സ്വകാര്യത പരിരക്ഷിക്കാൻ ഗൾഫ് ടിക്കറ്റ് പ്രതിജ്ഞാബദ്ധമാണ്. നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങൾ ഞങ്ങൾ എങ്ങനെ ശേഖരിക്കുകയും ഉപയോഗിക്കുകയും പങ്കിടുകയും ചെയ്യുന്നു എന്നതിനെ കുറിച്ചുള്ള കൂടുതൽ വിവരങ്ങൾക്ക് ഞങ്ങളുടെ സ്വകാര്യതാ നയം അവലോകനം ചെയ്യുക.</p>
										</li>
									</ul>
								</div>
							</div> : i18n.language === 'ar' ?
								<div className="addlPage scrollbar">
									<div className="addlpageCont">
										<p>
											مرحباً بك في تذكرة الخليج, موقع لعبة السحب عبر الإنترنت حيث يمكنك شراء زجاجات مياه بقيمة 10 دراهم و30 درهماً والحصول على تذاكر السحب لـFortune 5 وSuper 6 على التوالي.
										</p>
										<p>
											استخدامك لتذكرة الخليج فأنت توافق على الشروط والأحكام التالية
										</p>
										<ul className="faqAccordian">
											<li>
												<a >الأهلية</a>
												<p>للعب في تذكرة الخليج يجب أن يكون عمرك 18 عامًا على الأقل أو بالغًا حسب السن القانوني في بلدك.</p>
											</li>
											<li>
												<a >إنشاء الحساب وسحب شراء التذاكر</a>
												<p>يجب عليك إنشاء حساب وشراء تذكرة السحب للعب تذكرة الخليج.</p>
												<p>أنت مسؤول عن التأكد من أن معلومات حسابك دقيقة وحديثة.</p>
												<p>تذكرة الخليج ليست مسؤولة عن أي خسائر أو أضرار تتكبدها نتيجة لمعلومات الحساب غير الدقيقة أو القديمة.</p>
												<p>تحتفظ شركة Gulf Ticket بالحق في تعليق أو إنهاء حسابك إذا قدمت معلومات غير دقيقة أو مضللة.</p>
											</li>
											<li>
												<a >طرق الدفع</a>
												<p>تقبل تذكرة الخليج طرق الدفع التالية لشراء تذاكر السحب:</p>
												<ul>
													<li>
														<p>البطاقة الائتمانية</p>
													</li>
													<li>
														<p>بطاقة الصراف الآلي</p>
													</li>
													<li>
														<p>التحويل البنكي</p>
													</li>
													<li>
														<p>محفظة الجوال</p>
													</li>
												</ul>
											</li>
											<li>
												<a >السحب والفوز بتذكرة الخليج</a>
												<p>يتم إجراء السحوبات بشكل منتظم، كما هو معلن على موقع تذكرة الخليج.</p>
												<p>يتم تحديد التذاكر الفائزة بالسحب من خلال مولد أرقام عشوائي.</p>
												<p>تختلف احتمالات الفوز اعتمادًا على لعبة السحب التي تلعبها.</p>
											</li>
											<li>
												<a >النتائج</a>
												<p>سيتم توفير الأرقام الفائزة لكل سحب على الموقع الإلكتروني ومن خلال الاتصال بدعم العملاء بعد كل سحب. لن يكون لديك أي مطالبة بالتعويض عن أي فشل من جانبنا في نشر الأرقام الفائزة أو عدم إخطار حاملي الأرقام الفائزة إدخالات؛ تقع على عاتقك وحدك مسؤولية التحقق من إدخالاتك مقابل الأرقام الفائزة.</p>
												<p>لن يحق لك المطالبة بالتعويض، ولن نلتزم بأي أخطاء واردة في أي إعلان أو منشور، سواء كان مطبعيًا أو مطبوعًا أو غير ذلك أو أي منشور أو إعلان عن مبلغ الجوائز في السحب (بما في ذلك، بدون القيد، حيث يتم الإعلان أو النشر قبل الانتهاء من المبلغ الذي سيتم دفعه في الجوائز).</p>
												<p>في حالة وجود تناقض بين الأرقام الفائزة المنشورة على الموقع الإلكتروني و/أو أي إعلان أو منشور آخر والأرقام الفائزة الرسمية الموجودة على نظام الكمبيوتر المركزي، فإن الأخيرة هي التي تسود.</p>
												<p>في حالة اختلاف العدد النهائي الذي تم التحقق منه من المشاركات الفائزة عن العدد الأولي المُعلن عنه من المشاركات الفائزة المؤقتة، فقد تختلف أموال الجائزة المستحقة لهؤلاء الفائزين.</p>
											</li>
											<li>
												<a >الفوز بجائزة</a>
												<a >السحب</a>
												<p>سيكون هناك ما لا يقل عن ثلاثة (3) معرفات سحب فائزة لكل سحب.</p>
												<p>سيفوز كل من المشاركين الذين تتطابق معرفاتهم في السحب مع معرفات السحب الفائزة بمبلغ 100,000 درهم إماراتي (مائة ألف درهم) أو أي مبلغ آخر قد نحدده ونعلن عنه وفقًا لتقديرنا الخاص.</p>
												<p>يجب المطالبة بأي جائزة يانصيب في موعد لا يتجاوز 60 يومًا تقويميًا من تاريخ الفوز بجائزة السحب. إذا كنت تقيم خارج دولة الإمارات العربية المتحدة، فقد يُطلب منك الحضور إلى دولة الإمارات العربية المتحدة للمطالبة بجائزة السحب.</p>
												<p>قد يُطلب منك حضور أنشطة العلاقات العامة والإعلام التي أبلغناك بها.</p>
											</li>
											<li>
												<a >السحب الكبير</a>
												<ul>
													<li>
														<p>جميع الجوائز بالدرهم الإماراتي (AED).</p>
														<p>يحتفظ مدير السحب بالحق في تعديل هذه الشروط والأحكام دون إشعار مسبق.</p>
														<p>يمتلك مدير القرعة السلطة التقديرية لتغيير جائزة المباراة. سيتم تقسيم جائزة المباراة بالتساوي بين جميع المشاركين الفائزين في المباراة الرابعة</p>
														<p>تخضع الجوائز للضرائب وفقًا للقوانين المعمول بها.</p>
													</li>
												</ul>
											</li>
											<li>
												<a >الثروة 5</a>
												<p></p>
												<ul>
													<li>
														<p>المباراة الثانية - قام المشارك بمطابقة رقمين (2) بشكل صحيح في أي سطر (1) واحد من الأرقام الفائزة في السحب.</p>
													</li>
													<li>
														<p>المباراة الثالثة - قام المشارك بمطابقة ثلاثة (3) أرقام بشكل صحيح في أي سطر (1) واحد من الأرقام الفائزة في السحب؛</p>
													</li>
													<li>
														<p>المباراة الرابعة - قام المشارك بمطابقة أربعة (4) أرقام بشكل صحيح في أي سطر واحد (1) من الأرقام الفائزة في السحب؛ و</p>
													</li>
													<li>
														<p>المباراة الخامسة - قام المشارك بمطابقة جميع الأرقام الخمسة (5) بشكل صحيح في أي سطر واحد (1) من الأرقام الفائزة في السحب الكبير.</p>
													</li>
												</ul>
											</li>
											<li>
												<a >السوبر 6</a>
												<ul>
													<li>
														<p>المباراة الثانية - قام المشارك بمطابقة رقمين (2) بشكل صحيح في أي سطر (1) واحد من الأرقام الفائزة في السحب.</p>
													</li>
													<li>
														<p>المباراة الثالثة - قام المشارك بمطابقة ثلاثة (3) أرقام بشكل صحيح في أي سطر (1) واحد من الأرقام الفائزة في السحب؛</p>
													</li>
													<li>
														<p>المباراة الرابعة - قام المشارك بمطابقة أربعة (4) أرقام بشكل صحيح في أي سطر واحد (1) من الأرقام الفائزة في السحب؛ و</p>
													</li>
													<li>
														<p>المباراة الخامسة - قام المشارك بمطابقة جميع الأرقام الخمسة (5) بشكل صحيح في أي سطر واحد (1) من الأرقام الفائزة في السحب الثاني.</p>
													</li>
													<li>
														<p>المباراة السادسة - قام المشارك بمطابقة جميع الأرقام الخمسة (6) بشكل صحيح في أي سطر واحد (1) من الأرقام الفائزة في السحب الكبير.</p>
													</li>
												</ul>
												<p>بما يخص لعبة الثروة 5 ستحصل مباريات الأرقام الخمسة على الجائزة الكبرى بقيمة 1,000,000 درهم إماراتي (مليون درهم) في كل سحب كبير.</p>
												<p>بما يخص لعبة السوبر 6 ستحصل مباريات الأرقام الستة على الجائزة الكبرى بقيمة 100,000,000 درهم إماراتي (مائة مليون درهم) لكل سحب كبير.</p>
											</li>
											<li>
												<a >المطالبة بالجوائز</a>
												<p>يجب عليك المطالبة بجوائزك خلال 30 يومًا من الفوز بالسحب. سيتم مصادرة المكاسب غير المطالب بها إلى تذكرة الخليج. للمطالبة بأرباحك، يجب عليك تسجيل الدخول إلى حساب تذكرة الخليج الخاص بك وتقديم نموذج المطالبة. ستقوم تذكرة الخليج بالتحقق من مطالبتك ودفع أرباحك إلى حسابك خلال 7 أيام عمل. سيتم دفع المكاسب التي تزيد عن 100,000 درهم عن طريق التحويل المصرفي. يمكن دفع المكاسب التي تقل عن 100,000 درهم عن طريق التحويل المصرفي أو الشيك.</p>
											</li>
											<li>
												<a >الضرائب</a>
												<p>تخضع جميع المكاسب من تذكرة الخليج لقوانين الضرائب في دولة الإمارات العربية المتحدة. قد تكون هناك أيضًا ضريبة إضافية مطبقة في بلدك وفقًا للوائح الحكومية.</p>
												<p>سوف تقوم شركة تذكرة الخليج بخصم الضريبة المطبقة من أرباحك قبل دفعها لك.</p>
											</li>
											<li>
												<a >ألعاب مسؤولة</a>
												<p>تلتزم تذكرة الخليج بتقديم ألعاب بمسؤولية. نحن نشجع عملاؤنا على اللعب بمسؤولية ووضع حد لمصروفاتهم.</p>
												<p>إذا كانت لديك أي مشكلة مع الإسراف في الصرف، برجاء الاتصال بفريق دعم عملاء تذكرة الخليج للحصول على المساعدة.</p>
											</li>
											<li>
												<a >الملكية الفكرية</a>
												<p>جميع محتويات موقع تذكرة الخليج، بما في ذلك شعار تذكرة الخليج والعلامات التجارية وحقوق الطبع والنشر، هي ملك لشركة تذكرة الخليج.</p>
												<p>لا يجوز لك استخدام أي ملكية فكرية لتذكرة الخليج دون الحصول على إذن كتابي صريح من تذكرة الخليج.</p>
											</li>
											<li>
												<a >القانون الحاكم وحل النزاعات</a>
												<p>تخضع هذه الشروط والأحكام وتفسر وفقًا لقوانين دولة الإمارات العربية المتحدة.سيتم حل أي نزاعات تنشأ عن هذه الشروط والأحكام من قبل محاكم دولة الإمارات العربية المتحدة.</p>
											</li>
											<li>
												<a >التغييرات في الشروط والأحكام</a>
												<p>تحتفظ شركة تذكرة الخليج بالحق في تغيير هذه الشروط والأحكام في أي وقت.إذا أجرينا أي تغييرات، فسنقوم بنشر الشروط والأحكام المحدثة على موقعنا</p>
											</li>
											<li>
												<a >اتصل بنا</a>
												<p>إذا كان لديك أي أسئلة حول هذه الشروط والأحكام، يرجى الاتصال بنا على [support@gulfticket.com]</p>
												<p>شكرا للعب تذكرة الخليج!</p>
											</li>
											<li>
												<a >شروط وأحكام إضافية</a>
												<p>تذكرة الخليج ليست مسؤولة عن أي صعوبات فنية قد تمنعك من لعب القرعة أو المطالبة بأرباحك.</p>
												<p>تحتفظ شركة تذكرة الخليج بالحق في إلغاء أو تعليق أي سحب على تذكرة الخليج في أي وقت.</p>
												<p>تحتفظ شركة تذكرة الخليج بالحق في استبعاد أي لاعب من لعب السحب أو المطالبة بأرباحه إذا انتهك هذه الشروط والأحكام أو إذا شارك في أي نشاط احتيالي أو غير قانوني.</p>
												<p>تلتزم شركة تذكرة الخليج بحماية خصوصية لاعبيها. يرجى مراجعة سياسة الخصوصية الخاصة بنا للحصول على مزيد من المعلومات حول كيفية جمع معلوماتك الشخصية واستخدامها ومشاركتها.</p>
											</li>
										</ul>
									</div>
								</div> :
								<div className="addlPage scrollbar">
									<div className="addlpageCont">
										<p>آن لائن ڈرا گیم کی ویب سائٹ گلف ٹکٹ میں خوش آمدید جہاں آپ 10 اماراتی درہم اور 30 اماراتی درہم میں پانی کی بوتلیں خرید سکتے ہیں اور فورچیون  5 اور سوپر  6 کے لیے بالترتیب ڈرا ٹکٹ حاصل کر سکتے ہیں۔
										</p>
										<p>
											گلف ٹکٹ استعمال کرکے، آپ درج ذیل شرائط و ضوابط سے اتفاق کرتے ہیں:
										</p>
										<ul className="faqAccordian">
											<li>
												<a >اہلیت</a>
												<p>گلف ٹکٹ کھیلنے کے لیے، آپ کی عمر کم از کم 18 سال ہونی چاہیے یا آپ کو آپ کے ملک میں قانونی عمر کے مطابق بالغ ہونا چاہیے۔</p>
											</li>
											<li>
												<a >اکاؤنٹ بنانا اور ڈرا ٹکٹ کی خریداری</a>
												<p>گلف ٹکٹ کھیلنے کے لیے آپ کو اکاؤنٹ بنانا ہوگا اور ڈرا ٹکٹ خریدنا ہوگا۔</p>
												<p>آپ اس بات کو یقینی بنانے کے ذمہ دار ہیں کہ آپ کے اکاؤنٹ کی معلومات درست اور تازہ ترین ہیں۔</p>
												<p>گلف ٹکٹ اکاؤنٹ کی غلط یا پرانی معلومات کے نتیجے میں ہونے والے کسی نقصان یا خسارے کے لیے ذمہ دار نہیں ہے۔
												</p>
												<p>اگر آپ غلط یا گمراہ کن معلومات فراہم کرتے ہیں تو گلف ٹکٹ آپ کے اکاؤنٹ کو معطل یا ختم کرنے کا حق محفوظ رکھتا ہے۔</p>
											</li>
											<li>
												<a >ادائیگی کے طریقے</a>
												<p>گلف ٹکٹ ڈرا ٹکٹ کی خریداریوں کے لیے ادائیگی کے درج ذیل طریقوں کو قبول کرتا ہے:</p>
												<ul>
													<li>
														<p>کریڈٹ کارڈ</p>
													</li>
													<li>
														<p>ڈیبٹ کارڈ</p>
													</li>
													<li>
														<p>نیٹ بینکنگ</p>
													</li>
													<li>
														<p>موبائل والیٹ</p>
													</li>
												</ul>
											</li>
											<li>
												<a >گلف ٹکٹ ڈراز اور جیتنے والے ٹکٹ</a>
												<p>گلف ٹکٹ کی ویب سائٹ پر کیے گیے اعلان کے مطابق گلف ٹکٹ ڈراز مستقل بنیادوں پر ہوتے ہیں۔</p>
												<p>ریفل جیتنے والے ٹکٹوں کا تعین بے ترتیب نمبر جنریٹر کے ذریعے کیا جاتا ہے۔</p>
												<p>آپ جو ڈرا گیم کھیلتے ہیں اس کے لحاظ سے جیتنے کے امکانات مختلف ہوتے ہیں۔</p>
											</li>
											<li>
												<a >نتائج</a>
												<p>ہر ڈرا کے لیے جیتنے والے نمبر ہر ڈرا کے بعد ویب سائٹ پر اور کسٹمر سپورٹ کو کال کرکے دستیاب کرائے جائیں گے۔ جیتنے والے نمبروں کو شائع کرنے میں ہماری طرف سے کسی ناکامی یا جیتنے والے اندراجات کے حاملین کو مطلع کرنے میں ناکامی کے لیے آپ کو معاوضے کا دعویٰ کرنے کا حق نہیں ہوگا؛ جیتنے والے نمبروں کے مقابل اپنے اندراجات کی جانچ کرنا مکمل طور پر آپ کی ذمہ داری ہے۔</p>
												<p>آپ کسی اعلان یا طباعت، پرنٹنگ وغیرہ کی شکل میں موجود اشاعت میں شامل کسی غلطی یا قرعہ اندازی میں انعامات کی رقم کی کسی اشاعت یا اعلان کی وجہ سے معاوضے کا دعوی کرنے کے حقدار نہيں ہوں گے اور نہ ہم ادا کرنے کے پابند ہوں گے (بغیر کسی تحدید کے ان جگہوں کے بشمول جہاں انعامات میں ادا کی جانے والی رقم کو حتمی شکل دینے سے پہلے اعلان یا اشاعت کی جاتی ہے)۔</p>
												<p>ویب سائٹ اور/یا دیگر اعلان یا اشاعت پر شائع کردہ جیتنے والے نمبروں اور سنٹرل کمپیوٹر سسٹم پر موجود باضابطہ جیتنے والے نمبروں کے درمیان فرق کی صورت میں، مؤخر الذکر کو فوقیت دی جائے گی۔</p>
												<p>اگر جیتنے والے اندراجات کی حتمی تصدیق شدہ تعداد عارضی جیتنے والے اندراجات کی ابتدائی شائع کردہ تعداد سے مختلف ہے، تو ایسے جیتنے والوں کو ادا کی جانے والی انعامی رقم مختلف ہو سکتی ہے۔</p>
											</li>
											<li>
												<a >انعام جیتنا</a>
												<a >ریفل ڈرا</a>
												<p>ہر ریفل ڈرا کے لیے کم از کم تین (3) جیتنے والی ریفل آئی ڈیز ہوں گی۔</p>
												<p>جن شرکاء کی ریفل آئی ڈیز جیتنے والی ریفل آئی ڈیزسے مماثلت رکھتی ہیں، ان میں سے ہر ایک 100,000 اماراتی درہم (ایک لاکھ درہم) یا اس طرح کی دوسری رقم جیتے گی جس کا تعین اور اعلان ہماری اپنی صوابدید پر ہو سکتا ہے۔</p>
												<p>کسی بھی ریفل پرائز کا دعویٰ ریفل پرائز جیتنے کی تاریخ کے 60 کیلنڈر دنوں کے اندر کیا جانا چاہیے۔ اگر آپ متحدہ عرب امارات سے باہر رہتے ہیں، تو آپ سے ریفل پرائز کا دعوی کرنے کے لیے متحدہ عرب امارات میں حاضر ہونے کے لیے کہا جا سکتا ہے۔</p>
												<p>ہماری طرف سے ملنے والی اطلاع کے مطابق آپ سے پبلک ریلیشنز، میڈیا کی سرگرمیوں میں شرکت کرنے کا مطالبہ کیا جا سکتا ہے۔</p>
											</li>
											<li>
												<a >گرینڈ ڈرا</a>
												<ul>
													<li>
														<p>تمام انعامات یو اے ای درہم (اے ای ڈی) میں ہیں۔</p>
														<p>ڈرا کا منیجر پیشگی اطلاع کے بغیر ان شرائط و ضوابط میں ترمیم کرنے کا حق محفوظ رکھتا ہے۔</p>
														<p>ڈرا کا مینیجر اپنی صوابدید کے مطابق میچ پرائز کو تبدیل کر سکتا ہے۔ میچ کا انعام میچ کے تمام چار جیتنے والے شرکاء میں برابر تقسیم کیا جائے گا۔</p>
														<p>قابل اطلاق قوانین کے مطابق انعامات پر ٹیکس لگتا ہے۔</p>
													</li>
												</ul>
											</li>
											<li>
												<a >●	فورچیون 5</a>
												<p></p>
												<ul>
													<li>
														<p>میچ ٹو – شرکت کنندہ نے ڈرا کے جیتنے والے نمبروں سے کسی ایک (1) لائن میں دو (2) نمبروں کو صحیح طریقے سے ملایا ہے۔</p>
													</li>
													<li>
														<p>میچ تھری – شرکت کنندہ نے ڈرا کے جیتنے والے نمبروں سے کسی ایک (1) لائن میں تین (3) نمبروں کو صحیح طریقے سے ملایا ہے۔</p>
													</li>
													<li>
														<p>میچ فور – شرکت کنندہ نے ڈرا کے جیتنے والے نمبروں سے کسی ایک (1) لائن میں چار (4) نمبروں کو صحیح طریقے سے ملایا ہے؛ اور</p>
													</li>
													<li>
														<p>میچ پانچ – شرکت کنندہ نے گرینڈ ڈرا جیتنے والے نمبروں سے کسی ایک (1) لائن میں تمام پانچ (5) نمبروں کو صحیح طریقے سے ملایا ہے۔</p>
													</li>
												</ul>
											</li>
											<li>
												<a >سوپر 6</a>
												<ul>
													<li>
														<p>میچ ٹو – شرکت کنندہ نے ڈرا کے جیتنے والے نمبروں سے کسی ایک (1) لائن میں دو (2) نمبروں کو صحیح طریقے سے ملایا ہے۔</p>
													</li>
													<li>
														<p>میچ تھری – شرکت کنندہ نے ڈرا کے جیتنے والے نمبروں سے کسی ایک (1) لائن میں تین (3) نمبروں کو صحیح طریقے سے ملایا ہے۔</p>
													</li>
													<li>
														<p>میچ فور – شرکت کنندہ نے ڈرا کے جیتنے والے نمبروں سے کسی ایک (1) لائن میں چار (4) نمبروں کو صحیح طریقے سے ملایا ہے؛ اور</p>
													</li>
													<li>
														<p>میچ فائیو – شرکت کنندہ نے دوسرے ڈرا کے جیتنے والے نمبروں سے کسی ایک (1) لائن میں تمام پانچ (5) نمبروں کو صحیح طریقے سے ملایا ہے۔</p>
													</li>
													<li>
														<p>میچ سکس – شرکت کنندہ نے گرینڈ ڈرا جیتنے والے نمبروں سے کسی ایک (1) لائن میں تمام پانچ (6) نمبروں کو صحیح طریقے سے ملایا ہے۔</p>
													</li>
												</ul>
												<p>فورچیون  5 کے لیے، پانچ نمبر والے میچز کو فی گرینڈ ڈرا 1,000,000 اماراتی درہم (ایک ملین درہم) کا سب سے بڑا انعام ملے گا۔</p>
												<p>سوپر 6 گیمز کے لیے، چھ نمبر والے میچز کو فی گرانڈ ڈرا 100,000,000 اماراتی درہم  (ایک سو ملین درہم) کا عظیم الشان انعام ملے گا۔</p>
											</li>
											<li>
												<a >جیت کا دعوی کرنا</a>
												<p>آپ کو ڈرا جیتنے کے 30 دنوں کے اندر اپنی جیت کا دعوی کرنا ہوگا۔ جس جیت کا دعوی نہیں کیا جائے گا، اسے گلف ٹکٹ میں ضبط کرلیا جائے گا۔ اپنی جیت کا دعوی کرنے کے لیے، آپ کو اپنے گلف ٹکٹ اکاؤنٹ میں لاگ ان کرنا ہوگا اور دعوی کا فارم جمع کرانا ہوگا۔ گلف ٹکٹ آپ کے دعوے کی تصدیق کرے گا اور 7 کاروباری دنوں کے اندر آپ کی جیت کی رقم آپ کے اکاؤنٹ میں ادا کرے گا۔  100,000اماراتی درہم سے زیادہ جیتی ہوئی رقم بینک ٹرانسفر کے ذریعے ادا کی جائے گی۔  100,000اماراتی درہم سے کم جیتی ہوئی رقم بینک ٹرانسفر، چیک کے ذریعے ادا کی جا سکتی ہے۔</p>
											</li>
											<li>
												<a >ٹیکس</a>
												<p>گلف ٹکٹ سے تمام جیتی ہوئی رقم متحدہ عرب امارات کے ٹیکس قوانین کے تابع آتی ہیں۔ حکومت کے ضوابط کے مطابق آپ کے ملک میں اضافی ٹیکس بھی لاگو ہو سکتا ہے۔</p>
												<p>گلف ٹکٹ آپ کو ادائیگی کرنے سے پہلے آپ کی جیتی ہوئی رقم سے قابل اطلاق ٹیکس کی رقم کاٹ لے گا۔</p>
											</li>
											<li>
												<a >ذمہ دار گیمنگ</a>
												<p>گلف ٹکٹ ذمہ دار گیمنگ کے لیے پرعزم ہے۔ ہم اپنے اراکین کی حوصلہ افزائی کرتے ہیں کہ وہ ذمہ داری سے کھیلیں اور اپنے اخراجات کی حد مقرر کریں۔</p>
												<p>اگر آپ کو حد سے زیادہ خرچ کرنے میں کوئی مسئلہ ہے، تو براہ کرم مدد کے لیے گلف ٹکٹ کسٹمر سپورٹ ٹیم سے رابطہ کریں۔</p>
											</li>
											<li>
												<a >دانشورانہ ملکیت</a>
												<p>گلف ٹکٹ کے لوگو، تجارتی علامات اور کاپی رائٹ سمیت گلف ٹکٹ کی ویب سائٹ پر موجود تمام مواد گلف ٹکٹ کی ملکیت ہیں۔</p>
												<p>آپ کو گلف ٹکٹ کی واضح تحریری اجازت کے بغیر گلف ٹکٹ کی کوئی بھی دانشورانہ ملکیت استعمال کرنے کی اجازت نہیں ہے۔</p>
											</li>
											<li>
												<a >لاگو قانون اور تنازعات کا حل</a>
												<p>ان شرائط و ضوابط پر متحدہ عرب امارات کے قوانین لاگو ہوتے ہیں اور یہ انہیں کے مطابق تشکیل دیئے گیے ہیں۔</p>
												<p>ان شرائط و ضوابط سے پیدا ہونے والے کسی بھی تنازعہ کو متحدہ عرب امارات کی عدالتوں کے ذریعے حل کیا جائے گا۔</p>
											</li>
											<li>
												<a >شرائط و ضوابط میں تبدیلیاں</a>
												<p>گلف ٹکٹ کسی بھی وقت ان شرائط و ضوابط کو تبدیل کرنے کا حق محفوظ رکھتا ہے۔</p>
												<p>اگر ہم کوئی تبدیلی کرتے ہیں، تو ہم اپنی ویب سائٹ پر تازہ ترین شرائط و ضوابط پوسٹ کریں گے۔</p>
											</li>
											<li>
												<a >ہم سے رابطہ کریں</a>
												<p>اگر آپ کے پاس ان شرائط و ضوابط کے بارے میں کوئی سوال ہے تو، براہ کرم ہم سے [support@gulfticket.com] پر رابطہ کریں</p>
												<p>گلف ٹکٹ کھیلنے کے لیے آپ کا شکریہ!</p>
											</li>
											<li>
												<a >اضافی شرائط و ضوابط</a>
												<p>گلف ٹکٹ ایسی کسی بھی تکنیکی دشواری کے لیے ذمہ دار نہیں ہے جو آپ کو ڈرا کھیلنے یا اپنی جیت کا دعوی کرنے سے روک سکتی ہے۔</p>
												<p>گلف ٹکٹ کسی بھی وقت کسی بھی گلف ٹکٹ ڈرا کو منسوخ یا معطل کرنے کا حق محفوظ رکھتا ہے۔</p>
												<p>اگر کوئی کھلاڑی ان شرائط و ضوابط کی خلاف ورزی کرتا ہے یا کسی دھوکہ دہی یا غیر قانونی سرگرمی میں ملوث ہوتا ہے، تو گلف ٹکٹ اسے ڈرا کھیلنے یا اپنی جیت کا دعوی کرنے سے نااہل قرار دینے کا حق محفوظ رکھتا ہے۔</p>
												<p>گلف ٹکٹ اپنے کھلاڑیوں کی رازداری کی حفاظت کرنے کے لیے پرعزم ہے۔ آپ کی ذاتی معلومات کو اکٹھا، استعمال اور اس کا اشتراک کرنے کے بارے میں مزید معلومات کے لیے براہ کرم ہماری رازداری کی پالیسی کا جائزہ لیں۔</p>
											</li>
										</ul>
									</div>
								</div> 
					}*/}
				</div >
			</>

		);
	}
}
function mapStateToProps(state) {
	const { user } = state.loginReducer;
	return { user };
}
const mapActionToProps = {
	logout: loginActions.logout
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(TermsCondition))